<template>
  <div v-if="project">
    <Card>
      <template #title>{{ $t(verificationStateType) }} </template>
      <template #content>
        <div>
          <h6>{{ $t('verificationState') }}</h6>
        </div>
        <div>
          <b-badge class="verificationState" :variant="verificationStateColor(state)">
            {{ $t(state) }}
          </b-badge>
        </div>
      </template>
      <template #footer v-if="!isGuest">
        <hr />
        <b-button
          class="mr-1"
          v-if="showStartVerificationButton"
          @click="onStartVerification()"
          variant="primary"
        >
          {{ $t('start_verification') }}
        </b-button>
        <b-button
          class="mr-1"
          v-if="showRequestVerificationButton"
          @click="onRequestAudit()"
          variant="warning"
        >
          {{ $t('request_verification') }}
        </b-button>
        <b-button
          class="mr-1"
          v-if="showApprovedButton"
          @click="onApproveVerification()"
          variant="success"
        >
          {{ $t('approve_verification') }}
        </b-button>
        <b-button
          class="mr-1"
          v-if="showRejectButton"
          @click="onRejectVerification()"
          variant="danger"
        >
          {{ $t('reject_verification') }}
        </b-button>
        <b-button v-if="showResetButton" @click="onResetVerification()" variant="bg">
          {{ $t('reset') }}
        </b-button>
      </template>
    </Card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Card from 'primevue/card';
import { verificationStateColor } from '@/helpers/colors';

export default {
  components: { Card },
  props: {
    project: { type: Object },
    verificationStateType: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient', 'isGuest']),
    showRequestVerificationButton() {
      if (this.state === 'UNAUDITED' || this.state === 'REJECTED') {
        return true;
      }

      return false;
    },
    showStartVerificationButton() {
      if (this.state === 'REQUESTED' && !this.isOnSiteWorker) {
        return true;
      }

      return false;
    },
    showApprovedButton() {
      if (this.isOnSiteWorker) return false;

      if (this.state === 'IN_PROGRESS') {
        return true;
      }

      return false;
    },
    showRejectButton() {
      if (this.isOnSiteWorker) return false;

      if (this.state === 'IN_PROGRESS') {
        return true;
      }

      return false;
    },
    showResetButton() {
      if (this.isOnSiteWorker) return false;

      if (this.state !== 'UNAUDITED') {
        return true;
      }

      return false;
    },
    isOnSiteWorker() {
      if (this.isAdmin || this.isClient) {
        return false;
      }
      return true;
    },
    state() {
      return this.project[this.verificationStateType];
    },
  },

  methods: {
    ...mapActions(['updateProjectVerificationState']),
    async onRequestAudit() {
      await this.updateProjectVerificationState({
        projectNumber: this.project.number,
        verificationStateInput: {
          verificationStateType: this.verificationStateType,
          verificationState: 'REQUESTED',
        },
      });
    },
    async onStartVerification() {
      await this.updateProjectVerificationState({
        projectNumber: this.project.number,
        verificationStateInput: {
          verificationStateType: this.verificationStateType,
          verificationState: 'IN_PROGRESS',
        },
      });
    },
    async onApproveVerification() {
      await this.updateProjectVerificationState({
        projectNumber: this.project.number,
        verificationStateInput: {
          verificationStateType: this.verificationStateType,
          verificationState: 'APPROVED',
        },
      });
    },
    async onRejectVerification() {
      await this.updateProjectVerificationState({
        projectNumber: this.project.number,
        verificationStateInput: {
          verificationStateType: this.verificationStateType,
          verificationState: 'REJECTED',
        },
      });
    },
    async onResetVerification() {
      await this.updateProjectVerificationState({
        projectNumber: this.project.number,
        verificationStateInput: {
          verificationStateType: this.verificationStateType,
          verificationState: 'UNAUDITED',
        },
      });
    },
    verificationStateColor,
  },
};
</script>

<style scoped lang="scss">
.badge.verificationState {
  padding: 0.6em;
  font-size: 1em;
}
::v-deep .p-card .p-card-footer {
  min-height: 45px;
  padding-top: 0;
}

::v-deep .p-card .p-card-content {
  padding-top: 0.3em;
}
</style>
