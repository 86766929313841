<template>
  <div>
    <AccordionCard :state="cards.inverterSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('inverterSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('inverterSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[51].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('inverterSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.inverterSerialNumberAttachments"
            attachmentType="inverterSerialNumberAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isinverterSerialNumberAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="inverterSerialNumberAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isinverterSerialNumberAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.mateboxSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('mateboxSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('mateboxSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[49].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('mateboxSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.mateboxSerialNumberAttachments"
            attachmentType="mateboxSerialNumberAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ismateboxSerialNumberAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="mateboxSerialNumberAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="ismateboxSerialNumberAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.storageSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('storageSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('storageSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[50].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('storageSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.storageSerialNumberAttachments"
            attachmentType="storageSerialNumberAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isstorageSerialNumberAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="storageSerialNumberAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isstorageSerialNumberAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.dongleSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('dongleSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('dongleSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[48].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('dongleSerialNumberAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.dongleSerialNumberAttachments"
            attachmentType="dongleSerialNumberAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isdongleSerialNumberAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="dongleSerialNumberAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isdongleSerialNumberAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.inverterAttachments">
      <template v-slot:header>
        {{ $t('inverterAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('inverterAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[35].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.inverterAttachments"
            attachmentType="inverterAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isinverterAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="inverterAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isinverterAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.openMateboxAttachments">
      <template v-slot:header>
        {{ $t('openMateboxAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('openMateboxAttachments') }}</div>
        <div class="flex-column select"></div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.openMateboxAttachments"
            attachmentType="openMateboxAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isOpenMateboxAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="openMateboxAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isOpenMateboxAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableBetweenMateboxAndElectricMeterAttachments">
      <template v-slot:header>
        {{ $t('cableBetweenMateboxAndElectricMeterAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableBetweenMateboxAndElectricMeterAttachments') }}
        </div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[45].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.cableBetweenMateboxAndElectricMeterAttachments"
            attachmentType="cableBetweenMateboxAndElectricMeterAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="cableBetweenMateboxAndElectricMeterAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.variousBreakthroughsWithPossibleSealingsAttachments">
      <template v-slot:header>
        {{ $t('variousBreakthroughsWithPossibleSealingsAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('variousBreakthroughsWithPossibleSealingsAttachments') }}
        </div>
        <div class="flex-column select"></div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.variousBreakthroughsWithPossibleSealingsAttachments"
            attachmentType="variousBreakthroughsWithPossibleSealingsAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isvariousBreakthroughsWithPossibleSealingsAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="variousBreakthroughsWithPossibleSealingsAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isvariousBreakthroughsWithPossibleSealingsAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.inverterOptional"
      :project="project"
      notesType="inverterNotes"
      :attachments="project.inverterOptionalAttachments"
      attachmentType="inverterOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isinverterAttachmentsUploadComplete: false,
      isinverterSerialNumberAttachmentsUploadComplete: false,
      ismateboxSerialNumberAttachmentsUploadComplete: false,
      isstorageSerialNumberAttachmentsUploadComplete: false,
      isdongleSerialNumberAttachmentsUploadComplete: false,
      isCableManagementBetweenMateboxAndMeterCabinetAttachmentsUploadComplete: false,
      isvariousBreakthroughsWithPossibleSealingsAttachmentsUploadComplete: false,
      isOpenMateboxAttachmentsUploadComplete: false,
      iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isinverterAttachmentsUploadComplete = true;
          this.isinverterSerialNumberAttachmentsUploadComplete = true;
          this.ismateboxSerialNumberAttachmentsUploadComplete = true;
          this.isstorageSerialNumberAttachmentsUploadComplete = true;
          this.isdongleSerialNumberAttachmentsUploadComplete = true;
          this.isCableManagementBetweenMateboxAndMeterCabinetAttachmentsUploadComplete = true;
          this.isvariousBreakthroughsWithPossibleSealingsAttachmentsUploadComplete = true;
          this.isOpenMateboxAttachmentsUploadComplete = true;
          this.iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
