<template>
  <b-dropdown
    :variant="colorVariant"
    :lazy="true"
    class="confirm-feedback-dropdown"
    no-caret
    right
    size="sm"
    ref="dropdown"
    :disabled="disabled"
    @hide="onHide"
  >
    <template v-slot:button-content>
      <slot name="buttonText">Speichern</slot>
    </template>
    <b-dropdown-text class="fw-semi-bold dropdown-width">
      <slot name="header">Bestätigen!</slot>
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text class="">
      <slot name="content">
        Kann nicht rückgängig gemacht werden! Auf die unten stehende Schaltfläche klicken um zu
        bestätigen.
      </slot>
    </b-dropdown-text>
    <b-dropdown-group>
      <b-button
        class="btn-sm mt-2"
        :disabled="disabled"
        :variant="confirmColorVariant"
        @click="confirm"
      >
        <slot name="confirmButtonText">Speichern</slot>
      </b-button>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ConfirmButton',
  props: {
    id: String,
    callbackId: { default: null },
    disabled: Boolean,
    preventHide: { type: Boolean, default: false },
    colorVariant: { default: 'default' },
    confirmColorVariant: { default: 'danger' },
    confirmCallback: Function,
    hideCallback: { type: Function, default: () => {} },
  },
  methods: {
    confirm() {
      // the old way
      if (this.id) {
        this.$emit('confirm-button:confirmed', { id: this.id });
      }

      // the new way
      if (this.confirmCallback) {
        this.confirmCallback(this.callbackId);
      }
      this.close();
    },

    /**
     * manually close the dropdown
     */
    close() {
      this.hideCallback();
      this.$nextTick(() => {
        this.$refs.dropdown.hide(true);
      });
    },

    /**
     * Fix unexpected closing if a Calendar component is rendered in the content slot
     * @param {*} event
     */
    onHide(event) {
      // console.log('onHide - preventHide', this.preventHide);
      if (this.preventHide) {
        event.preventDefault();
        this.hideCallback();
      }
    },
  },
};
</script>

<style>
.confirm-feedback-dropdown {
  margin-right: 5px;
}
.confirm-feedback-dropdown .dropdown-menu {
  padding: 15px;
  min-width: 300px;
}
.confirm-feedback-dropdown button,
.confirm-feedback-dropdown textarea {
  width: 100%;
}

.btn-group > .btn {
  padding: 0.6rem 1rem;
}

.pi {
  position: relative;
  top: 2px;
}
</style>
