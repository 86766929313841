<template>
  <div class="project-messages">
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <legend>
          <strong>Vorlage</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('messageBoilerplateType')"
          label-for="messageBoilerplateType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('messageBoilerplateType')"
            v-model="selectedMessageBoilerplate"
            :options="messageBoilerplates"
            @select="onSelectMessageBoilerplate"
            :allow-empty="true"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ $t(option.type) }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option.type) }}
            </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col lg="9" md="9" sm="12">
        <div v-if="selectedMessageBoilerplate">
          <legend>
            <strong>E-Mail "{{ $t(selectedMessageBoilerplate.type) }}" versenden</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('subject')"
            label-for="subject"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <b-form-input
              v-model="selectedMessageBoilerplate.subject"
              type="text"
              :placeholder="$t('subject')"
            />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('body')"
            label-for="body"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <ckeditor
              :editor="editor"
              v-model="selectedMessageBoilerplate.body"
              :config="editorConfig"
              @ready="onEditorReady"
            ></ckeditor>
          </b-form-group>

          <!-- <b-form-group
            v-if="selectedMessageBoilerplate.type === 'OPERATION_REGISTRATION'"
            horizontal
            :label="$t('attachments')"
            label-class="text-md-right"
            :label-cols="1"
            breakpoint="md"
          >
            <div class="radio">
              <input
                type="radio"
                id="uploaded"
                value="UPLOADED"
                v-model="selectedMessageBoilerplate.attachmentType"
                @change="onChangeAttachmentRadio"
              />
              <label for="uploaded">Hochgeladene Inbetriebnahmemeldung-Dokumente anhängen</label>
              <input
                type="radio"
                id="auto_generated"
                value="AUTO_GENERATED"
                v-model="selectedMessageBoilerplate.attachmentType"
                :disabled="!hasGeneratedOperationRegistrationDocuments"
                @change="onChangeAttachmentRadio"
              />
              <label for="auto_generated"
                >Automatisch generierte Inbetriebnahmemeldung-Dokumente anhängen</label
              >
            </div>
          </b-form-group> -->
          <b-form-group
            horizontal
            :label="$t('attachmentSize')"
            label-class="text-md-right"
            :label-cols="1"
            breakpoint="md"
          >
            {{ $n(attachmentSize / 1000000) }} MB
          </b-form-group>

          <b-form-group
            horizontal
            label-for="button"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <ConfirmButton
              colorVariant="bg"
              v-bind:disabled="buttonsDisabled"
              v-bind:id="project.id"
              v-on:confirm-button:confirmed="onSendProjectMail"
            >
              <template v-slot:buttonText>
                E-Mail versenden
              </template>
              <template v-slot:header>
                E-Mail "{{ $t(selectedMessageBoilerplate.type) }}" versenden
              </template>
              <template v-slot:content>
                <div>
                  E-mail wird an
                  <b>{{ recipient }}</b> versendet.
                </div>
              </template>
              <template v-slot:confirmButtonText>
                Versand bestätigen
              </template>
            </ConfirmButton>
            <div v-if="disabledReason" class="error-message ml-2">{{ disabledReason }}</div>
          </b-form-group>
        </div>
        <div v-else>
          <legend>
            <strong>E-Mail versenden</strong>
          </legend>
          <div class="danger">Bitte Vorlage wählen!</div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { genderSalutation } from '@/helpers/text';
import ConfirmButton from '@/components/ConfirmButton';
import dayjs from 'dayjs';

export default {
  name: 'ProjectMessages',
  components: { Multiselect, ckeditor: CKEditor.component, ConfirmButton },
  props: {
    project: { type: Object },
    selectedAppointment: { type: Object },
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {},
      selectedMessageBoilerplate: null,
      buttonsDisabled: true,
      recipient: '',
      disabledReason: null,
      attachmentSize: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient']),
    ...mapGetters(['isLoading', 'getMessageBoilerplateTypes', 'getMessageBoilerplates']),
    /** @deprecated */
    hasGeneratedNetRegistrationDocuments() {
      if (this.project.netRegistrationAttachmentsBuildState === 'FINISHED') {
        return true;
      }
      return false;
    },
    /** @deprecated */
    hasGeneratedOperationRegistrationDocuments() {
      if (this.project.operationRegistrationAttachmentsBuildState === 'FINISHED') {
        return true;
      }
      return false;
    },
    /**
     * Filter out some message types for clients and sort alphabetically
     */
    messageBoilerplates() {
      const messageBoilerplates = this.getMessageBoilerplates;

      const filteredMessageBoilerplates = this.isClient
        ? messageBoilerplates.filter(
            (boilerplate) =>
              boilerplate.type === 'NET_REGISTRATION' ||
              boilerplate.type === 'OPERATION_REGISTRATION' ||
              boilerplate.type === 'APPOINTMENT_CONFIRMATION' ||
              boilerplate.type === 'APPOINTMENT_CONFIRMATION_MEASUREMENT' ||
              boilerplate.type === 'APPOINTMENT_CONFIRMATION_DEFICIT'
          )
        : messageBoilerplates;

      return filteredMessageBoilerplates.sort((a, b) => {
        var nameA = this.$t(a.type.toUpperCase()); // Groß-/Kleinschreibung ignorieren
        var nameB = this.$t(b.type.toUpperCase()); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    ...mapActions([
      'fetchMessageBoilerplateTypes',
      'fetchMessageBoilerplates',
      'sendProjectMail',
      'fetchProjectMailAttachmentSize',
    ]),
    onChangeAttachmentRadio() {
      this.calculateAttachmentSize();
    },
    onEditorReady(event) {},
    onSelectMessageBoilerplate() {
      this.$nextTick(() => {
        this.update();
      });
    },
    async onSendProjectMail() {
      await this.sendProjectMail({
        projectId: this.project.id,
        message: this.selectedMessageBoilerplate,
      });
    },
    setRecipient() {
      if (
        this.project.powerCompany &&
        (this.selectedMessageBoilerplate.type === 'NET_REGISTRATION' ||
          this.selectedMessageBoilerplate.type === 'OPERATION_REGISTRATION')
      ) {
        this.recipient = this.project.powerCompany.registrationEmail;
      } else if (
        !this.project.powerCompany &&
        (this.selectedMessageBoilerplate.type === 'NET_REGISTRATION' ||
          this.selectedMessageBoilerplate.type === 'OPERATION_REGISTRATION')
      ) {
        this.recipient = 'Kein EVU ausgewählt!';
      } else {
        this.recipient = this.project.customer.email;
      }
    },
    projectMailPrerequisitesCheck() {
      if (this.selectedMessageBoilerplate.type === 'NET_REGISTRATION') {
        if (this.project.netRegistrationRequirementsFullfilledState === 'NO') {
          this.disabledReason = 'Voraussetzungen Netzanmeldung nicht erfüllt!';
        } else {
          this.disabledReason = null;
        }
        this.buttonsDisabled = false;
      }
      if (this.selectedMessageBoilerplate.type === 'OPERATION_REGISTRATION') {
        if (this.project.operationRegistrationRequirementsFullfilledState === 'NO') {
          this.disabledReason = 'Voraussetzungen Inbetriebnahmemeldung nicht erfüllt!';
        } else {
          this.disabledReason = null;
        }
        this.buttonsDisabled = false;
      }
      if (this.selectedMessageBoilerplate.type === 'CUSTOMER_INITIAL_INFO') {
        this.buttonsDisabled = false;
        this.disabledReason = null;
      }
      if (this.selectedMessageBoilerplate.type === 'CUSTOMER_MISSING_DOCUMENTS') {
        this.buttonsDisabled = false;
        this.disabledReason = null;
      }
      if (this.selectedMessageBoilerplate.type === 'CUSTOM_MAIL') {
        this.buttonsDisabled = false;
        this.disabledReason = null;
      }
      if (
        this.selectedMessageBoilerplate.type === 'APPOINTMENT_CONFIRMATION' ||
        this.selectedMessageBoilerplate.type === 'APPOINTMENT_CONFIRMATION_DEFICIT' ||
        this.selectedMessageBoilerplate.type === 'APPOINTMENT_CONFIRMATION_MEASUREMENT'
      ) {
        this.buttonsDisabled = false;
        this.disabledReason = null;
        // if (this.selectedAppointment) {
        //   this.buttonsDisabled = false;
        //   this.disabledReason = null;
        // } else {
        //   this.buttonsDisabled = true;
        //   this.disabledReason = 'Bitte zunächst Termin auswählen!';
        // }
      }
    },
    parseEmailTemplate() {
      if (this.selectedMessageBoilerplate) {
        this.selectedMessageBoilerplate.body = this.selectedMessageBoilerplate.body.replace(
          /{{salutation}}/,
          genderSalutation(this.project.customer.salutation)
        );
        const lastname =
          this.project.customer.salutation === 'COMPANY' ? '' : this.project.customer.lastname;
        this.selectedMessageBoilerplate.body = this.selectedMessageBoilerplate.body.replace(
          /{{lastname}}/,
          lastname
        );
        this.selectedMessageBoilerplate.subject = this.selectedMessageBoilerplate.subject.replace(
          /{{lastname}}/,
          this.project.customer.lastname
        );
        this.selectedMessageBoilerplate.subject = this.selectedMessageBoilerplate.subject.replace(
          /{{firstname}}/,
          this.project.customer.firstname
        );
        this.selectedMessageBoilerplate.subject = this.selectedMessageBoilerplate.subject.replace(
          /{{zip}}/,
          this.project.customer.zip
        );
        this.selectedMessageBoilerplate.subject = this.selectedMessageBoilerplate.subject.replace(
          /{{city}}/,
          this.project.customer.city
        );
        if (this.selectedAppointment) {
          this.selectedMessageBoilerplate.body = this.selectedMessageBoilerplate.body.replace(
            /{{appointment_date}}/,
            this.$date(this.selectedAppointment.installationAt)
          );
          this.selectedMessageBoilerplate.body = this.selectedMessageBoilerplate.body.replace(
            /{{appointment_start_time}}/,
            this.$time(this.selectedAppointment.installationAt)
          );
          this.selectedMessageBoilerplate.body = this.selectedMessageBoilerplate.body.replace(
            /{{appointment_offset_time}}/,
            this.$time(dayjs(this.selectedAppointment.installationAt).add(2, 'hour'))
          );
        }
      }
    },
    async calculateAttachmentSize() {
      if (this.project.number === '_new') return; // nothing to calculate on new projects

      this.attachmentSize = await this.fetchProjectMailAttachmentSize({
        projectNumber: this.project.number,
        messageBoilerplateType: this.selectedMessageBoilerplate.type,
        messageAttachmentType: this.selectedMessageBoilerplate.attachmentType,
      });
    },
    async update() {
      this.parseEmailTemplate();
      this.setRecipient();
      this.projectMailPrerequisitesCheck();
      await this.calculateAttachmentSize();
    },
  },

  async mounted() {
    await this.fetchMessageBoilerplateTypes();
    await this.fetchMessageBoilerplates();
    // this.selectedMessageBoilerplate = this.getMessageBoilerplates[0];
    this.selectedMessageBoilerplate = this.messageBoilerplates[0];
    this.update();

    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'updateProject') {
          this.fetchMessageBoilerplates();
        }
      },
    });
  },
  watch: {
    /**
     * preselect APPOINTMENT_CONFIRMATION boilerplate when confirm_appointment button is pressed
     */
    selectedAppointment: {
      handler: async function(newSelectedAppointment, oldSelectedAppointment) {
        // console.log(newSelectedAppointment);
        if (
          newSelectedAppointment.installationType === 'UV' ||
          newSelectedAppointment.installationType === 'DIRECT' ||
          newSelectedAppointment.installationType === 'ELECTRIC_METER_CABINET' ||
          newSelectedAppointment.installationType === 'REWORKING'
        ) {
          this.selectedMessageBoilerplate = this.getMessageBoilerplates.find(
            (boilerplate) => boilerplate.type === 'APPOINTMENT_CONFIRMATION'
          );
        }

        if (newSelectedAppointment.installationType === 'MEASUREMENT') {
          this.selectedMessageBoilerplate = this.getMessageBoilerplates.find(
            (boilerplate) => boilerplate.type === 'APPOINTMENT_CONFIRMATION_MEASUREMENT'
          );
        }

        if (newSelectedAppointment.installationType === 'DEFICIT') {
          this.selectedMessageBoilerplate = this.getMessageBoilerplates.find(
            (boilerplate) => boilerplate.type === 'APPOINTMENT_CONFIRMATION_DEFICIT'
          );
        }

        await this.fetchMessageBoilerplates(); // refresh boilerplate templates

        this.$nextTick(() => {
          this.update();
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.error-message {
  color: red;
  display: inline-block;
}
</style>
