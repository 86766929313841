<template>
  <div>
    <AccordionCard :state="cards.pitchedRoofCondition">
      <template v-slot:header>
        {{ $t('pitched_roof_condition') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('pitched_roof_condition') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.conditionState"
            :options="getEnumValues('PitchedRoofConditionState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bricksPlastered">
      <template v-slot:header>
        {{ $t('bricksPlastered') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bricksPlastered') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.bricksPlasteredState"
            :options="getEnumValues('PitchedRoofBricksPlasteredState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.bricksPlasteredState === 'YES'">
        <div class="flex-column name">
          {{ $t('bricksPlasteredAttachments') }}
        </div>
        <div class="flex-column select">
          <p>
            {{ $t('upload_addtional_images') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[11].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row" v-if="project.measurement.pitchedRoof.bricksPlasteredState === 'YES'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.bricksPlasteredAttachments"
            attachmentType="bricksPlasteredAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isBricksPlasteredAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="bricksPlasteredAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isBricksPlasteredAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cardboardPoppetState">
      <template v-slot:header>
        {{ $t('cardboardPoppetState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cardboardPoppetState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.cardboardPoppetState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.cardboardPoppetState === 'YES'">
        <div class="flex-column name">
          {{ $t('cardboardPoppetAttachments') }}
        </div>
        <div class="flex-column select">
          <p>
            {{ $t('upload_addtional_images') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[12].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row" v-if="project.measurement.pitchedRoof.cardboardPoppetState === 'YES'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.cardboardPoppetAttachments"
            attachmentType="cardboardPoppetAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isCardboardPoppetAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="cardboardPoppetAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isCardboardPoppetAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.pedimentAttachments">
      <template v-slot:header>
        {{ $t('pedimentAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('pedimentAttachments') }}
        </div>
        <div class="flex-column select">
          <p>
            {{ $t('pedimentAttachments_description') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[13].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.pedimentAttachments"
            attachmentType="pedimentAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isPedimentAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="pedimentAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isPedimentAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.pitchedRoofOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.pitchedRoofOptionalAttachments"
            attachmentType="pitchedRoofOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isPitchedRoofOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="pitchedRoofOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isPitchedRoofOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.pitchedRoofNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.notes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isBricksPlasteredAttachmentsUploadComplete: false,
      isCardboardPoppetAttachmentsUploadComplete: false,
      isPedimentAttachmentsUploadComplete: false,
      isPitchedRoofOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isBricksPlasteredAttachmentsUploadComplete = true;
          this.isCardboardPoppetAttachmentsUploadComplete = true;
          this.isPedimentAttachmentsUploadComplete = true;
          this.isPitchedRoofOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
