<template>
  <section class="">
    <div v-if="posts.length > limit">
      <a href="#" @click.prevent="isLimit = !isLimit">
        <span v-if="isLimit">Alle anzeigen</span>
        <span v-else>Nur neue anzeigen</span>
      </a>
    </div>
    <ul class="post-comments mt-sm">
      <li v-for="post in limitedPosts" :key="post.id">
        <span class="thumb-xs avatar pull-left mr-sm">
          <img v-if="post.user.avatar" class="rounded-circle" :src="post.user.avatar.path" alt="" />
          <img v-else class="rounded-circle" src="../assets/avatar2.png" alt="" />
        </span>
        <div
          class="comment-body"
          @mouseover="enablePostOptions(post.id)"
          @mouseleave="disablePostOptions(post.id)"
        >
          <div class="comment-options" v-if="activeId == post.id && post.user.id == user.id">
            <span v-if="editable" class="icon" @click.prevent="editPost(post.id)"
              ><i class="fa fa-edit"></i
            ></span>
            <span v-if="deleteable" class="icon" @click.prevent="deletePost(post.id)"
              ><i class="fa fa-times"></i
            ></span>
          </div>
          <h6 class="author fs-sm fw-semi-bold">
            {{ post.user.firstname }} {{ post.user.lastname }}
            <small>
              {{ $fromNow(post.createdAt) }}
              {{ $datetime(post.createdAt) }}
              <b-badge v-if="isNewCustomerPost(post)" class="ml-2" variant="yellow">{{
                $t(post.state)
              }}</b-badge>
            </small>
          </h6>
          <p>{{ post.content }}</p>
        </div>
      </li>
      <li>
        <span class="thumb-xs avatar pull-left mr-sm">
          <img v-if="user.avatar" class="rounded-circle" :src="user.avatar.path" alt="" />
          <img v-else class="rounded-circle" src="../assets/avatar2.png" alt="" />
        </span>
        <form v-on:submit.prevent="savePost" class="comment-form">
          <div class="comment-body">
            <b-form-input
              v-model="post"
              class="form-control form-control-sm"
              type="text"
              :placeholder="$t('write_a_post')"
            />
            <button type="submit" class="btn btn-secondary btn-sm">{{ $t('send') }}</button>
          </div>
        </form>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from 'primevue/button';

/**
 * @todo: Loader Icon
 * @todo: fetch only recent posts, max number
 * @todo: fetch more/all link
 * @todo: delete own posts
 * @todo: edit own posts
 */
export default {
  name: 'Post',
  props: {
    user: null,
    editable: { type: Boolean, default: false },
    deleteable: { type: Boolean, default: false },
    posts: {
      type: Array,
      required: true,
    },
    components: {
      Button,
    },
  },
  data() {
    return {
      post: null,
      activeId: null,
      limit: 10,
      isLimit: true,
    };
  },
  computed: {
    /**
     * apply post limit, return the last [limit] posts from the posts array if limit is set
     */
    limitedPosts() {
      return this.isLimit ? this.posts.slice(-1 * this.limit) : this.posts;
      //   return this.isLimit ? this.posts.slice(0, this.limit) : this.posts;
    },
  },
  methods: {
    ...mapGetters([]),
    savePost() {
      if (!this.post || this.post.trim() === '') {
        return;
      }
      this.$emit('comment:post', this.post);
      this.post = null;
    },
    deletePost(id) {
      this.$emit('comment:delete', id);
      // console.log("delete:" + id)
    },
    editPost(id) {
      // console.log("edit:" + id)
    },
    enablePostOptions(id) {
      this.activeId = id;
    },
    disablePostOptions(id) {
      this.activeId = null;
    },
    isNewCustomerPost(post) {
      return post.state === 'POST_NEW';
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.avatar img {
  width: 28px;
  height: 28px;
  object-fit: cover;
}
.event {
  background: none;
}
.post-comments li.new-post {
  transition: background-color 2s ease;
  background-color: rgba(40, 167, 49, 0.4);
}

.post-comments li {
  background-color: #fff;
  margin-bottom: 10px; /* Fügt einen Abstand zwischen den Posts hinzu */
}

.comment-body {
  position: relative;
}

.comment-options {
  position: absolute;
  top: -2px;
  right: 0;
  background-color: #fff;
  color: #bbb;
  padding: 0 0 0 10px;
  border: 0px solid #bbbbbb;
}

.comment-options .icon {
  margin: 0 2px;
  cursor: pointer;
}

.comment-options .icon i.fa-edit {
  position: relative;
  top: 1px;
}

.comment-options .icon .fa-edit:hover {
  color: $info;
}

.comment-options .icon .fa-times:hover {
  color: $danger;
}

.comment-body .form-control {
  flex-grow: 1;
  margin-right: 10px;
}

.comment-body button {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.comment-form .comment-body {
  display: flex; /* Ändert das Display auf Flex, damit die Eingabe und der Button horizontal angeordnet werden */
  align-items: center; /* Zentriert die Inhalte vertikal */
}

.author {
  margin-bottom: 0; /* Reduziert den Abstand unterhalb des Autors */
}

.comment-body p {
  margin: 0; /* Entfernt den Standardabstand des Absatzes */
  padding-top: 5px; /* Fügt einen kleinen Abstand über der Nachricht hinzu */
}
</style>
