<template>
  <div class="arrow-timeline">
    <template v-for="(element, index) in items">
      <div :key="index" class="arrow-element" :class="element.class" :style="cssProps">
        {{ element.label }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array },
    colors: { type: Object },
  },
  computed: {
    cssProps() {
      return {
        '--timeline-element-active-background': this.colors.active,
        '--timeline-element-upcoming-background': this.colors.upcoming,
        '--timeline-element-previous-background': this.colors.previous,
        '--timeline-element-warning-background': this.colors.warning,
      };
    },
  },
  data() {
    return {
      timelineElementBackground: '#aa3',
    };
  },
};
</script>

<style scoped lang="scss">
$timeline-background: $body-bg;
$timeline-element-default-bg: $primary;
$timeline-text: #fff;

.arrow-timeline {
  margin: 0.5em 0;
  padding: 0 20px 0 0;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 1em;
  background: none repeat scroll 0 0 $timeline-background;
}

.arrow-element {
  display: inline-block;
  background: none repeat scroll 0 0 $timeline-element-default-bg;
  position: relative;
  list-style: none outside none;
  line-height: 18px;
  padding: 12px 6px 10px 26px;
  color: $timeline-text;
}
.arrow-element.active {
  background: none repeat scroll 0 0 var(--timeline-element-active-background);
}
.arrow-element.upcoming {
  background: none repeat scroll 0 0 var(--timeline-element-upcoming-background);
}
.arrow-element.previous {
  background: none repeat scroll 0 0 var(--timeline-element-previous-background);
}
.arrow-element.warning {
  background: none repeat scroll 0 0 var(--timeline-element-warning-background);
}
.arrow-element:first-child {
  padding-left: 12px;
}
.arrow-element:first-child:before {
  border: 0 none;
}

.arrow-element:before {
  content: '';
  position: absolute;
  top: 0;
  height: 0;
  border-left: 20px solid $timeline-background;
  border-bottom: 20px inset transparent;
  border-top: 20px inset transparent;
  width: 0;
  left: 0;
}
.arrow-element:after {
  content: '';
  height: 0;
  border-left: 20px solid $timeline-element-default-bg;
  right: -20px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  z-index: 2;
  width: 0;
  position: absolute;
  top: 0;
}
.arrow-element.active:after {
  border-left: 20px solid var(--timeline-element-active-background);
}
.arrow-element.upcoming:after {
  border-left: 20px solid var(--timeline-element-upcoming-background);
}
.arrow-element.previous:after {
  border-left: 20px solid var(--timeline-element-previous-background);
}
.arrow-element.warning:after {
  border-left: 20px solid var(--timeline-element-warning-background);
}
</style>
