<template>
  <div>
    <AccordionCard :state="cards.internetConnectionPresentState">
      <template v-slot:header>
        {{ $t('internetConnectionPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('internetConnectionPresentState') }}</div>
        <div class="flex-column select"></div>
        {{ $t('internetConnectionPresentState_description') }}
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.internetConnectionPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.lanConnectionPresentState">
      <template v-slot:header>
        {{ $t('lanConnectionPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('lanConnectionPresentState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.lanConnectionPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.internetConnectionAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.internetConnectionAttachments"
            attachmentType="internetConnectionAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isInternetConnectionAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="internetConnectionAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isInternetConnectionAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.internetConnectionNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.internetConnectionNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'InternetConnection',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isInternetConnectionAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isInternetConnectionAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
