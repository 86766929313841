<template>
  <div>
    <AccordionCard :state="cards.roofSidesAssignedState">
      <template v-slot:header>
        {{ $t('roofSidesAssignedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('roofSidesAssignedState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.roofSidesAssignedState"
            :options="getEnumValues('OneToEightState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofSidesAttachments">
      <template v-slot:header>
        {{ $t('roofSidesAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofSidesAttachments') }}
        </div>

        <div class="flex-column select">
          <p>
            {{ $t('roofSidesAttachments_description') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[18].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofSidesAttachments"
            attachmentType="roofSidesAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isRoofSidesAttachmentsploadComplete"
            :parentId="project.id"
            parentType="roofSidesAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isRoofSidesAttachmentsploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofSidesMeasurements">
      <template v-slot:header>
        {{ $t('roofSidesMeasurements') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofSidesMeasurements') }}
        </div>
        <div class="flex-column select">
          {{ $t('roofSidesMeasurements_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.roofSidesMeasurements"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <RoofSideShadow :cards="cards" :project="project" />
    <RoofSideSketch :cards="cards" :project="project" />

    <AccordionCard :state="cards.roofSidesOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofSidesOptionalAttachments"
            attachmentType="roofSidesOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isRoofSidesOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="roofSidesOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isRoofSidesOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofSidesNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.roofSidesNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import RoofSideShadow from './RoofSideShadow.vue';
import RoofSideSketch from './RoofSideSketch.vue';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
    RoofSideShadow,
    RoofSideSketch,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isRoofSidesAttachmentsploadComplete: false,
      isRoofSidesOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isRoofSidesAttachmentsploadComplete = true;
          this.isRoofSidesOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
