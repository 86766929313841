<template>
  <div>
    <AccordionCard :state="cards.acInstallationLocationState">
      <template v-slot:header>
        {{ $t('acInstallationLocationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('acInstallationLocationState') }}
        </div>
        <div class="flex-column select">
          {{ $t('acInstallationLocationState_description') }}
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.acInstallationLocationState"
            :options="getEnumValues('HouseLocationState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.acInstallationLocationAttachments">
      <template v-slot:header>
        {{ $t('acInstallationLocationAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('acInstallationLocationAttachments') }}
        </div>
        <div class="flex-column select">
          <p>{{ $t('acInstallationLocationAttachments_description') }}</p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[25].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select"></div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.acInstallationLocationAttachments"
            attachmentType="acInstallationLocationAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isAcInstallationLocationAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="acInstallationLocationAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isAcInstallationLocationAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.mateboxToZsDistance">
      <template v-slot:header> {{ $t('mateboxToZsDistance') }}</template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('mateboxToZsDistance') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.ac.mateboxToZsDistance" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.breakthroughCountState">
      <template v-slot:header>
        {{ $t('breakthroughCountState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('breakthroughCountState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.breakthroughCountState"
            :options="getEnumValues('OneToEightState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cabelPathDescription">
      <template v-slot:header>
        {{ $t('cabelPathDescription') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cabelPathDescription') }}
        </div>
        <div class="flex-column select">
          {{ $t('cabelPathDescription_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.cabelPathDescription"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.locationAndCablePathOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.locationAndCablePathOptionalAttachments"
            attachmentType="locationAndCablePathOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isLocationAndCablePathOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="locationAndCablePathOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isLocationAndCablePathOptionalAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.locationAndCablePathNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.locationAndCablePathNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    InputText,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isAcInstallationLocationAttachmentsUploadComplete: false,
      isLocationAndCablePathOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isAcInstallationLocationAttachmentsUploadComplete = true;
          this.isLocationAndCablePathOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
