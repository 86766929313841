<template>
  <div class="project-acceptance-report" v-if="project">
    <b-row>
      <b-col lg="8" md="8" sm="12">
        <legend>
          <strong>{{ $t('acceptanceReport') }}: {{ $t('generate_report') }}</strong>
        </legend>
        <b-row>
          <b-col lg="4" md="7" sm="7">
            <AttachmentList
              v-if="project.reportAttachmentsBuildState === 'FINISHED'"
              :attachments="project.reportAttachments"
              :attachmentType="attachmentType"
              v-on:attachment-list:delete="onDeleteAttachment"
            ></AttachmentList>
          </b-col>
          <b-col lg="8" md="5" sm="5">
            <router-link
              class="btn btn-success pull-right ml-2"
              :to="{
                name: 'ReportPrintPage',
                params: { projectNumber: project.number },
              }"
              target="_blank"
            >
              <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
            </router-link>
            <ButtonWait
              v-if="!isGuest"
              :startCallback="startReportBuild"
              :pollCallback="fetchBuildState"
              class="pull-right"
            >
              <template v-slot:buttonText>
                {{ $t('generate_report') }}
              </template>
            </ButtonWait>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" md="4" sm="12">
        <legend>
          <strong>{{ $t('downloads') }}</strong>
        </legend>
        <b-row>
          <b-col lg="10" md="10" sm="10">
            {{ $t('acceptanceReportImageAttachments') }}
          </b-col>
          <b-col lg="2" md="2" sm="2">
            <ZipDownload
              attachmentType="acceptanceReportImageAttachments"
              :attachments="project.acceptanceReportImageAttachmentsZip"
              :project="project"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12" md="12" sm="12">
        <legend>
          <strong>{{ $t('acceptanceReport') }}: {{ $t('verification') }}</strong>
        </legend>
        <div class="verification-flex m-1">
          <VerificationState
            :project="project"
            verificationStateType="substructureVerificationState"
          />
          <VerificationState :project="project" verificationStateType="acVerificationState" />
          <VerificationState :project="project" verificationStateType="dcVerificationState" />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col lg="12" md="12" sm="12">
        <legend>
          <strong>{{ $t('acceptanceReport') }}: {{ $t('approvement') }}</strong>
        </legend>
        <div class="verification-flex m-1">
          <VerificationState
            :project="project"
            verificationStateType="acceptanceApprovementVerificationState"
          />
          <Card>
            <template #title>{{ $t('acceptanceApprovementNote') }} </template>
            <template #content>
              <b-form-textarea
                id="description"
                v-model="project.acceptanceApprovementNote"
                placeholder="..."
                rows="4"
                max-rows="4"
              ></b-form-textarea>
            </template>
          </Card>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col lg="12" md="12" sm="12">
        <legend>
          <strong>{{ $t('acceptanceReport') }}: {{ $t('collected_data') }}</strong>
        </legend>
        <MobileProjectsReport :project="project" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MobileProjectsReport from '@/views/Projects/MobileProjectsReport';
import ZipDownload from '@/components/ZipDownload';
import ButtonWait from '@/components/ButtonWait.vue';
import AttachmentList from '@/components/AttachmentList';
import VerificationState from '@/components/VerificationState.vue';
import Card from 'primevue/card';

export default {
  components: {
    ButtonWait,
    AttachmentList,
    MobileProjectsReport,
    ZipDownload,
    VerificationState,
    Card,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      attachmentType: 'acceptanceReportAttachments',
    };
  },
  computed: {
    ...mapGetters('auth', ['isGuest']),
  },
  methods: {
    ...mapActions(['buildReport', 'fetchReportAttachmentsBuildState']),
    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startReportBuild() {
      if (this.project) {
        await this.buildReport(this.project.id);
      }
    },
    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    fetchBuildState() {
      this.fetchReportAttachmentsBuildState(this.project.number);
      return this.project.reportAttachmentsBuildState;
    },
    /**
     * Bubble up AttachmentList delete event
     */
    onDeleteAttachment(event) {
      this.$emit('project-report-attachment-list:delete', {
        attachmentId: event.attachmentId,
        attachmentType: this.attachmentType,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.verification-flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.verification-flex > div {
  flex: 1;
  min-width: 350px;
}
</style>
