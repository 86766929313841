<template>
  <div>
    <AccordionCard :state="cards.qcellsWallboxContractState">
      <template v-slot:header>
        {{ $t('qcellsWallboxContractState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('qcellsWallboxContractState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.qcellsWallboxContractState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxLocationAttachments">
      <template v-slot:header>
        {{ $t('serviceWallboxLocationAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxLocationAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxLocationAttachments"
            attachmentType="serviceWallboxLocationAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceWallboxLocationAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceWallboxLocationAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceWallboxLocationAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxCablePathNotes">
      <template v-slot:header>
        {{ $t('serviceWallboxCablePathNotes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxCablePathNotes') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxCablePathNotes"
            attachmentType="serviceWallboxCablePathNotes"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceWallboxCablePathNotesUploadComplete"
            :parentId="project.id"
            parentType="serviceWallboxCablePathNotes"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceWallboxCablePathNotesUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxAttachments">
      <template v-slot:header>
        {{ $t('serviceWallboxAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxAttachments"
            attachmentType="serviceWallboxAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceWallboxAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceWallboxAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceWallboxAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceWallboxAccessCardAttachments">
      <template v-slot:header>
        {{ $t('serviceWallboxAccessCardAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceWallboxAccessCardAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceWallboxAccessCardAttachments"
            attachmentType="serviceWallboxAccessCardAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceWallboxAccessCardAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceWallboxAccessCardAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceWallboxAccessCardAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceFiLsWallboxAttachments">
      <template v-slot:header>
        {{ $t('serviceFiLsWallboxAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceFiLsWallboxAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceFiLsWallboxAttachments"
            attachmentType="serviceFiLsWallboxAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceFiLsWallboxAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceFiLsWallboxAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceFiLsWallboxAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isserviceWallboxLocationAttachmentsUploadComplete: false,
      isserviceWallboxCablePathNotesUploadComplete: false,
      isserviceWallboxAttachmentsUploadComplete: false,
      isserviceWallboxAccessCardAttachmentsUploadComplete: false,
      isserviceFiLsWallboxAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isserviceWallboxLocationAttachmentsUploadComplete = true;
          this.isserviceWallboxCablePathNotesUploadComplete = true;
          this.isserviceWallboxAttachmentsUploadComplete = true;
          this.isserviceWallboxAccessCardAttachmentsUploadComplete = true;
          this.isserviceFiLsWallboxAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
