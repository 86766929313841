<template>
  <div class="project-messages">
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <legend>
          <strong>Freigabe</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('employerClearanceAt')"
          label-for="employerClearanceAt"
          label-class="text-md-right"
          :label-cols="7"
          breakpoint="md"
        >
          <div v-if="project.employerClearanceAt" class="form-text">
            {{ $datetime(project.employerClearanceAt) }}
          </div>
          <div v-else class="form-text">N/A</div>
        </b-form-group>
      </b-col>

      <b-col lg="9" md="9" sm="12">
        <div v-if="boilerplate">
          <legend>
            <strong>Freigabe per E-Mail bestätigen</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('subject')"
            label-for="subject"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <b-form-input v-model="boilerplate.subject" type="text" :placeholder="$t('subject')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('body')"
            label-for="body"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <ckeditor
              :editor="editor"
              v-model="boilerplate.body"
              :config="editorConfig"
              @ready="onEditorReady"
            ></ckeditor>
          </b-form-group>
          <b-form-group
            horizontal
            label-for="button"
            label-class="text-md-left"
            :label-cols="1"
            breakpoint="md"
          >
            <ConfirmButton
              colorVariant="bg"
              v-bind:disabled="buttonsDisabled"
              v-bind:id="project.id"
              v-on:confirm-button:confirmed="onSendProjectMail"
            >
              <template v-slot:buttonText>
                Freigabe bestätigen
              </template>
              <template v-slot:header>
                Freigabe per E-Mail bestätigen
              </template>
              <template v-slot:content>
                <div>
                  E-mail wird versendet an:
                  <b>{{ recipientOperator }}</b> und <b>{{ recipientEmployer }}</b
                  >.
                </div>
              </template>
              <template v-slot:confirmButtonText>
                Versand bestätigen
              </template>
            </ConfirmButton>
            <div v-if="buttonsDisabled" class="error-message ml-2">{{ disabledReason }}</div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { genderSalutation } from '@/helpers/text';
import ConfirmButton from '@/components/ConfirmButton';
import dayjs from 'dayjs';

export default {
  name: 'EmployerApproval',
  components: { ckeditor: CKEditor.component, ConfirmButton },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      boilerplate: {
        subject: 'Freigabe Zusatzleistungen Kunde {{lastname}}, {{firstname}}, {{zip}}, {{city}}',
        body:
          '<p>Sehr geehrte Damen und Herren,</p>' +
          '<p>hiermit erteilt EWB die Freigabe zur Durchführung von Zusatzleistungen bei o.a. Kunden durch die SSP.</p>',
      },
      editor: InlineEditor,
      editorConfig: {},
      buttonsDisabled: false,
      recipientOperator: null,
      recipientEmployer: null,
      disabledReason: null,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['getCurrentUser']),
  },
  methods: {
    ...mapActions(['sendEmployerApprovalMail']),
    onEditorReady(event) {},
    onSelectMessageBoilerplate() {
      this.$nextTick(() => {
        this.update();
      });
    },
    async onSendProjectMail() {
      await this.sendEmployerApprovalMail({
        projectId: this.project.id,
        message: this.boilerplate,
      });
    },
    setRecipients() {
      this.recipientOperator = 'netzanfrage@ssp-elektro.com';
      this.recipientEmployer = this.getCurrentUser.email;
    },
    parseEmailTemplate() {
      if (this.boilerplate) {
        this.boilerplate.body = this.boilerplate.body.replace(
          /{{lastname}}/,
          this.project.customer.lastname
        );
        this.boilerplate.subject = this.boilerplate.subject.replace(
          /{{lastname}}/,
          this.project.customer.lastname
        );
        this.boilerplate.subject = this.boilerplate.subject.replace(
          /{{firstname}}/,
          this.project.customer.firstname
        );
        this.boilerplate.subject = this.boilerplate.subject.replace(
          /{{zip}}/,
          this.project.customer.zip
        );
        this.boilerplate.subject = this.boilerplate.subject.replace(
          /{{city}}/,
          this.project.customer.city
        );
      }
    },
    update() {
      this.parseEmailTemplate();
      this.setRecipients();
    },
  },

  async mounted() {
    this.update();
  },
};
</script>

<style scoped lang="scss">
.error-message {
  color: red;
  display: inline-block;
}
</style>
