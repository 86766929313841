<template>
  <div>
    <AccordionCard :state="cards.equipotentialBusBarPresentState">
      <template v-slot:header>
        {{ $t('equipotentialBusBarPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('equipotentialBusBarPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.equipotentialBusBarPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.secondaryEquipotentialBusBarPresentState">
      <template v-slot:header>
        {{ $t('secondaryEquipotentialBusBarPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('secondaryEquipotentialBusBarPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.secondaryEquipotentialBusBarPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.potentialGroundRodPresentState">
      <template v-slot:header>
        {{ $t('potentialGroundRodPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('potentialGroundRodPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.potentialGroundRodPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.earthingSupply16mmState">
      <template v-slot:header>
        {{ $t('earthingSupply16mmState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('earthingSupply16mmState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.earthingSupply16mmState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceEarthingAttachments">
      <template v-slot:header>
        {{ $t('serviceEarthingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('serviceEarthingAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceEarthingAttachments"
            attachmentType="serviceEarthingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceEarthingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceEarthingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceEarthingAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  components: {
    AccordionCard,
    SelectButton,
    AttachmentGallery,
    DropzoneUpload,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isserviceEarthingAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchEnumValues']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isserviceEarthingAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
