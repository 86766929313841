<template>
  <div>
    <AccordionCard :state="cards.equipotentialBusBarAttachments">
      <template v-slot:header>
        {{ $t('equipotentialBusBarAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('equipotentialBusBarAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[41].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.equipotentialBusBarAttachments"
            attachmentType="equipotentialBusBarAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isequipotentialBusBarAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="equipotentialBusBarAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isequipotentialBusBarAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.potentialGroundRodAttachments">
      <template v-slot:header>
        {{ $t('potentialGroundRodAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('potentialGroundRodAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[4].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.potentialGroundRodAttachments"
            attachmentType="potentialGroundRodAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ispotentialGroundRodAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="potentialGroundRodAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="ispotentialGroundRodAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.potentialOptional"
      :project="project"
      notesType="potentialNotes"
      :attachments="project.potentialOptionalAttachments"
      attachmentType="potentialOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isequipotentialBusBarAttachmentsUploadComplete: false,
      ispotentialGroundRodAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isequipotentialBusBarAttachmentsUploadComplete = true;
          this.ispotentialGroundRodAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
