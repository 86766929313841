<template>
  <div class="signature">
    <VueSignaturePad
      class="signature-pad inset-shadow"
      ref="signaturePad"
      height="400px"
      :options="options"
    />
    <div class="signature-buttons">
      <b-button class="btn-sm mr-2 mt-3" variant="bg" @click="onSaveSignature">{{
        $t('save')
      }}</b-button>
      <b-button class="btn-sm mt-3" variant="bg" @click="undo">{{ $t('undo') }}</b-button>
    </div>
  </div>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad';

export default {
  components: {
    VueSignaturePad,
  },
  props: {
    /**
     * Provide a function which is called on save
     */
    onSaveCallback: Function,
    type: null,
    data: null,
  },
  data() {
    return {
      options: {
        onBegin: () => {
          // this.$refs.signaturePad.resizeCanvas();
          // this.$refs.signaturePad.fromDataURL(this.data);
        },
      },
    };
  },
  methods: {
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    onSaveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/svg+xml');
      console.log(isEmpty);
      console.log(data);
      if (!isEmpty) {
        this.onSaveCallback(data, this.type);
      } else {
        this.onSaveCallback(null, this.type);
      }
    },
    onInitSignaturePad(collapseId, isJustShown) {
      this.$nextTick(() => {
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.resizeCanvas();
        }
        if (this.$refs.signaturePad) {
          this.$refs.signaturePad.fromDataURL(this.data);
        }
      });
    },
  },
  mounted() {
    this.$refs.signaturePad.clearSignature();
    this.$refs.signaturePad.fromDataURL(this.data);
    window.addEventListener('resize', this.onInitSignaturePad);
    this.$root.$on('bv::collapse::state', this.onInitSignaturePad);
  },
  unmounted() {
    window.removeEventListener('resize', this.onInitSignaturePad);
  },

  watch: {
    /**
     * Trigger remount of Lightbox
     */
    data(newData, oldData) {
      // console.log(newData, oldData);
      //   if (newVal) {
      // this.$nextTick(() => {
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.fromDataURL(newData);
      // });
      //   }
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-pad {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.signature-buttons {
  text-align: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.inset-shadow {
  -moz-box-shadow: inset 0px 0px 12px -8px #000000;
  -webkit-box-shadow: inset 0px 0px 12px -8px #000000;
  box-shadow: inset 0px 0px 12px -8px #000000;
}
</style>
