<template>
  <b-card :title="$t('communication')" class="mb-4">
    <b-card-text class="customer-card-grid-container">
      <div class="confirm-button">
        {{ $t('initialInformationSentToCustomerAt') }}
      </div>
      <div class="">
        <span v-if="project.initialInformationSentToCustomerAt">
          {{ $datetime(project.initialInformationSentToCustomerAt) }}
          ({{ $fromNow(project.initialInformationSentToCustomerAt) }})</span
        >
        <span v-else>N/A</span>
      </div>
      <div class="confirm-button">
        {{ $t('missingDocumentsSentToCustomerAt') }}
      </div>
      <div class="">
        <span v-if="project.missingDocumentsSentToCustomerAt">
          {{ $datetime(project.missingDocumentsSentToCustomerAt) }}
          ({{ $fromNow(project.missingDocumentsSentToCustomerAt) }})</span
        >
        <span v-else>N/A</span>
      </div>
      <div class="confirm-button">
        {{ $t('netRegistrationSentAt') }}
      </div>
      <div class="">
        <span v-if="project.netRegistrationSentAt">
          {{ $datetime(project.netRegistrationSentAt) }}
          ({{ $fromNow(project.netRegistrationSentAt) }})</span
        >
        <span v-else>N/A</span>
      </div>
      <div class="confirm-button">
        {{ $t('operationRegistrationSentAt') }}
      </div>
      <div class="">
        <span v-if="project.operationRegistrationSentAt">
          {{ $datetime(project.operationRegistrationSentAt) }}
          ({{ $fromNow(project.operationRegistrationSentAt) }})</span
        >
        <span v-else>N/A</span>
      </div>
      <div class="confirm-button">
        {{ $t('appointmentConfirmationSentAt') }}
      </div>
      <div class="">
        <span v-if="project.appointmentConfirmationSentAt">
          {{ $datetime(project.appointmentConfirmationSentAt) }}
          ({{ $fromNow(project.appointmentConfirmationSentAt) }})</span
        >
        <span v-else>N/A</span>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    project: { type: Object },
  },
};
</script>

<style scoped lang="scss">
.customer-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 0.5em;
}
</style>
