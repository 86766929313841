<template>
  <div>
    <AccordionCard :state="cards.acceptanceFaultFree">
      <template v-slot:header> A. {{ $t('acceptanceFaultFree') }} </template>
      <template>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('signatureApprovalCustomer') }}
          </div>
          <div class="flex-column select">
            <Signature
              v-if="project"
              :onSaveCallback="saveSignature"
              type="signatureApprovalCustomer"
              :data="project.enpalReport.signatureApprovalCustomer"
            />
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('signatureApprovalCustomer') }}
          </div>
          <div class="flex-column select">
            <Signature
              v-if="project"
              :onSaveCallback="saveSignature"
              type="signatureApprovalTechnician"
              :data="project.enpalReport.signatureApprovalTechnician"
            />
          </div>
        </div>
      </template>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import Signature from '@/components/Signature';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    Signature,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {};
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'saveReportSignature']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },

    /**
     *
     */
    saveSignature(data, type) {
      console.log(type, data);
      this.project.enpalReport[type] = data;
      this.saveReportSignature({
        projectId: this.project.id,
        payload: data,
        signatureType: type,
      });
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
/** signature pad */
.signature-pad {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.signature-buttons {
  text-align: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.inset-shadow {
  -moz-box-shadow: inset 0px 0px 12px -8px #000000;
  -webkit-box-shadow: inset 0px 0px 12px -8px #000000;
  box-shadow: inset 0px 0px 12px -8px #000000;
}
</style>
