<template>
  <div>
    <AccordionCard :state="cards.eavesHeight">
      <template v-slot:header> {{ $t('eavesHeight') }} </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('eavesHeight1InMeters') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.eavesHeight1" />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('eavesHeight2InMeters') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.eavesHeight2" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.rafterInsulation">
      <template v-slot:header>
        {{ $t('rafterInsulationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('rafterInsulationState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.rafterInsulationState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.rafterInsulationState === 'YES'">
        <div class="flex-column name">{{ $t('rafterInsulationNotes') }}</div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.rafterInsulationNotes" />
        </div>
      </div>
    </AccordionCard>

    <LightningProtection :cards="cards" :project="project" />

    <AccordionCard :state="cards.bricksRemoveableState">
      <template v-slot:header>
        {{ $t('bricksRemoveableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bricksRemoveableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.bricksRemoveableState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.bricksRemoveableState === 'NO'">
        <div class="flex-column name">{{ $t('why_not') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.bricksRemoveableNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofPitchInDegrees">
      <template v-slot:header> {{ $t('roofPitchInDegrees') }} </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofPitchInDegrees') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.roofPitchInDegrees" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.outerRoofOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.outerRoofOptionalAttachments"
            attachmentType="outerRoofOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isOuterRoofOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="outerRoofOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isOuterRoofOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.outerRoofNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.outerRoofNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import LightningProtection from './LightningProtection.vue';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
    InputText,
    LightningProtection,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isOuterRoofOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isOuterRoofOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
