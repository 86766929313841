<template>
  <div v-if="project">
    <ArrowTimeline :items="projectStates" :colors="colors" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ArrowTimeline from '@/components/ArrowTimeline';

export default {
  components: {
    ArrowTimeline,
  },
  props: {
    project: { type: Object },
  },
  computed: {
    ...mapGetters(['getEnumValues']),
    projectStates() {
      const projectStates = [];
      let stateClass = 'previous';
      this.getEnumValues('ProjectState').map((state) => {
        if (this.project.projectState === state) {
          stateClass = 'active';
        }

        if (
          this.project.projectState === state &&
          (state === 'PROJECT_STATE_CANCELLATION' ||
            state === 'PROJECT_STATE_BACK_TO_EMPLOYER' ||
            state === 'PROJECT_STATE_INTERNAL_COMPLAINT' ||
            state === 'PROJECT_STATE_SERVICE_MISSION' ||
            state === 'PROJECT_STATE_EXTERNAL_COMPLAINT')
        ) {
          projectStates.push({ label: this.$t(state), class: 'warning' });
        } else if (
          state !== 'PROJECT_STATE_CANCELLATION' &&
          state !== 'PROJECT_STATE_BACK_TO_EMPLOYER' &&
          state !== 'PROJECT_STATE_INTERNAL_COMPLAINT' &&
          state !== 'PROJECT_STATE_SERVICE_MISSION' &&
          state !== 'PROJECT_STATE_EXTERNAL_COMPLAINT'
        ) {
          projectStates.push({ label: this.$t(state), class: stateClass });
        }

        if (this.project.projectState === state) {
          stateClass = 'upcoming';
        }
      });
      return projectStates;
    },
  },
  data() {
    return {
      colors: {
        active: '#e8c547',
        upcoming: '#a3aeb7',
        previous: '#0cac64',
        warning: '#ff7d47',
      },
    };
  },
};
</script>
