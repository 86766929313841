<template>
  <div class="project-documents">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('upload') }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('projectAttachmentType')"
          label-for="projectAttachmentType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('projectAttachmentType')"
            v-model="selectedProjectAttachmentType"
            id="projectAttachmentType"
            :options="filteredProjectAttachmentTypes"
            :allow-empty="false"
            :show-labels="false"
            v-on:search-change="onProjectAttachmentTypeSearch"
            :internal-search="false"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>

          <DropzoneUpload
            class="mt-2"
            :complete="isAttachmentUploadComplete"
            :parentId="project.id"
            :parentType="selectedProjectAttachmentType"
            :uploadCallback="onUploadProjectAttachments"
            :disabled="isUploadDisabled"
            :multiUpload="true"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <div class="accordion" role="tablist">
          <!-- Bilder vom Auftraggeber -->
          <AccordionSimpleCard cardId="accordion-installationImageAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('installationImageAttachments') }}
                <ZipDownload
                  attachmentType="installationImageAttachments"
                  :attachments="project.installationImageAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>

            <AttachmentGallery
              :attachments="project.installationImageAttachments"
              attachmentType="installationImageAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>
          </AccordionSimpleCard>

          <!-- Bilder Aufmaß -->
          <AccordionSimpleCard cardId="accordion-measurementAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('measurementAttachments') }}
                <ZipDownload
                  attachmentType="measurementAttachments"
                  :attachments="project.measurementAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>

            <legend>
              <strong>{{ $t('measurementAttachments') }} - Allgemein</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.measurementAttachments"
              attachmentType="measurementAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <!-- Ziegel vermörtelt ? - Zusätzliches Foto -->
            <legend>
              <strong>{{ $t('bricksPlasteredAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.bricksPlasteredAttachments"
              attachmentType="bricksPlasteredAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <!-- Pappdocken vorhanden? - Zusätzliches Foto-->
            <legend>
              <strong>{{ $t('cardboardPoppetAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.cardboardPoppetAttachments"
              attachmentType="cardboardPoppetAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('pedimentAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.pedimentAttachments"
              attachmentType="pedimentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('pitchedRoof') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.pitchedRoofOptionalAttachments"
              attachmentType="pitchedRoofOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('rafterVisibleAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.rafterVisibleAttachments"
              attachmentType="rafterVisibleAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('rafter') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.rafterOptionalAttachments"
              attachmentType="rafterOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('outerRoof') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.outerRoofOptionalAttachments"
              attachmentType="outerRoofOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('bricksFrontAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.bricksFrontAttachments"
              attachmentType="bricksFrontAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('bricksBackAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.bricksBackAttachments"
              attachmentType="bricksBackAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('bricks') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.bricksOptionalAttachments"
              attachmentType="bricksOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofSidesAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofSidesAttachments"
              attachmentType="roofSidesAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofSidesShadowsAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofSidesShadowsAttachments"
              attachmentType="roofSidesShadowsAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofSidesSketchAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofSidesSketchAttachments"
              attachmentType="roofSidesSketchAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofSides') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofSidesOptionalAttachments"
              attachmentType="roofSidesOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofAccessAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofAccessAttachments"
              attachmentType="roofAccessAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('roofAccess') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.roofAccessOptionalAttachments"
              attachmentType="roofAccessOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('hakAndZsSameRoomAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.hakAndZsSameRoomAttachments"
              attachmentType="hakAndZsSameRoomAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('hakAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.hakAttachments"
              attachmentType="hakAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('hak') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.hakOptionalAttachments"
              attachmentType="hakOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('zsWithCoverAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.zsWithCoverAttachments"
              attachmentType="zsWithCoverAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('zsWithoutCoverAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.zsWithoutCoverAttachments"
              attachmentType="zsWithoutCoverAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('zs') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.zsOptionalAttachments"
              attachmentType="zsOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('mainElectricMeterAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.mainElectricMeterAttachments"
              attachmentType="mainElectricMeterAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('seconaryElectricMeterAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.seconaryElectricMeterAttachments"
              attachmentType="seconaryElectricMeterAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('electricMeter') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.electricMeterOptionalAttachments"
              attachmentType="electricMeterOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('acInstallationLocationAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.acInstallationLocationAttachments"
              attachmentType="acInstallationLocationAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('locationAndCable') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.locationAndCablePathOptionalAttachments"
              attachmentType="locationAndCablePathOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('equipotentialBusBarPresentAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.equipotentialBusBarPresentAttachments"
              attachmentType="equipotentialBusBarPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('earthingPresentAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.earthingPresentAttachments"
              attachmentType="earthingPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('earthingUnit') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.earthingOptionalAttachments"
              attachmentType="earthingOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('internetConnection') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.internetConnectionAttachments"
              attachmentType="internetConnectionAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('heavyCurrentPresentAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.heavyCurrentPresentAttachments"
              attachmentType="heavyCurrentPresentAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('wallboxLocationAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.wallboxLocationAttachments"
              attachmentType="wallboxLocationAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('wallbox') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.wallboxOptionalAttachments"
              attachmentType="wallboxOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('flatRoofSurfaceAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.flatRoofSurfaceAttachments"
              attachmentType="flatRoofSurfaceAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>

            <legend>
              <strong>{{ $t('flatRoof') }} {{ $t('optionalAttachments') }}</strong>
            </legend>
            <AttachmentGallery
              :attachments="project.flatRoofOptionalAttachments"
              attachmentType="flatRoofOptionalAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>
          </AccordionSimpleCard>

          <!-- Bilder Team DC -->
          <AccordionSimpleCard cardId="accordion-teamDCAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('teamDCAttachments') }}
                <ZipDownload
                  attachmentType="teamDCAttachments"
                  :attachments="project.teamDCAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>

            <AttachmentGallery
              :attachments="project.teamDCAttachments"
              attachmentType="teamDCAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>
          </AccordionSimpleCard>

          <!-- Bilder Team AC -->
          <AccordionSimpleCard cardId="accordion-teamACAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('teamACAttachments') }}
                <ZipDownload
                  attachmentType="teamACAttachments"
                  :attachments="project.teamACAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>

            <AttachmentGallery
              :attachments="project.teamACAttachments"
              attachmentType="teamACAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentGallery>
          </AccordionSimpleCard>

          <span v-if="isAdmin">
            <!-- Montagedokumentation -->
            <AccordionSimpleCard cardId="accordion-installationTeamDocumentationAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('installationTeamDocumentationAttachments') }}
                  <ZipDownload
                    attachmentType="installationTeamDocumentationAttachments"
                    :attachments="project.installationTeamDocumentationAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.installationTeamDocumentationAttachments"
                attachmentType="installationTeamDocumentationAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Inbetriebnahme -->
            <AccordionSimpleCard cardId="accordion-operationRegistrationImageAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('operationRegistrationImageAttachments') }}
                  <ZipDownload
                    attachmentType="operationRegistrationImageAttachments"
                    :attachments="project.operationRegistrationImageAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.operationRegistrationImageAttachments"
                attachmentType="operationRegistrationImageAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Vorschäden -->
            <AccordionSimpleCard cardId="accordion-damageAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('damageAttachments') }}
                  <ZipDownload
                    attachmentType="damageAttachments"
                    :attachments="project.damageAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.damageAttachments"
                attachmentType="damageAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Anschalten der Anlage -->
            <AccordionSimpleCard cardId="accordion-systemPowerOnAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('systemPowerOnAttachments') }}
                  <ZipDownload
                    attachmentType="systemPowerOnAttachments"
                    :attachments="project.systemPowerOnAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.systemPowerOnAttachments"
                attachmentType="systemPowerOnAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Zählertausch -->
            <AccordionSimpleCard cardId="accordion-electricMeterChangeAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('electricMeterChangeAttachments') }}
                  <ZipDownload
                    attachmentType="electricMeterChangeAttachments"
                    :attachments="project.electricMeterChangeAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.electricMeterChangeAttachments"
                attachmentType="electricMeterChangeAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Unterschrift -->
            <AccordionSimpleCard cardId="accordion-signatureAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('signatureAttachments') }}
                  <ZipDownload
                    attachmentType="signatureAttachments"
                    :attachments="project.signatureAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.signatureAttachments"
                attachmentType="signatureAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Gerüst -->
            <AccordionSimpleCard cardId="accordion-scaffoldAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('scaffoldAttachments') }}
                  <ZipDownload
                    attachmentType="scaffoldAttachments"
                    :attachments="project.scaffoldAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.scaffoldAttachments"
                attachmentType="scaffoldAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Ziegel & UK -->
            <AccordionSimpleCard cardId="accordion-brickAndSubstructureAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('brickAndSubstructureAttachments') }}
                  <ZipDownload
                    attachmentType="brickAndSubstructureAttachments"
                    :attachments="project.brickAndSubstructureAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.brickAndSubstructureAttachments"
                attachmentType="brickAndSubstructureAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Module -->
            <AccordionSimpleCard cardId="accordion-solarPanelAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('solarPanelAttachments') }}
                  <ZipDownload
                    attachmentType="solarPanelAttachments"
                    :attachments="project.solarPanelAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.solarPanelAttachments"
                attachmentType="solarPanelAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Kabelverlegung -->
            <AccordionSimpleCard cardId="accordion-cableManagementAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('cableManagementAttachments') }}
                  <ZipDownload
                    attachmentType="cableManagementAttachments"
                    :attachments="project.cableManagementAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.cableManagementAttachments"
                attachmentType="cableManagementAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Potentialausgleichschiene -->
            <AccordionSimpleCard cardId="accordion-equipotentialBusBarAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('equipotentialBusBarAttachments') }}
                  <ZipDownload
                    attachmentType="equipotentialBusBarAttachments"
                    :attachments="project.equipotentialBusBarAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.equipotentialBusBarAttachments"
                attachmentType="equipotentialBusBarAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Wechselrichter -->
            <AccordionSimpleCard cardId="accordion-inverterAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('inverterAttachments') }}
                  <ZipDownload
                    attachmentType="inverterAttachments"
                    :attachments="project.inverterAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.inverterAttachments"
                attachmentType="inverterAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Speicher -->
            <AccordionSimpleCard cardId="accordion-storageAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('storageAttachments') }}
                  <ZipDownload
                    attachmentType="storageAttachments"
                    :attachments="project.storageAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.storageAttachments"
                attachmentType="storageAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>

            <!-- Bilder Zählerschrank und AC-Box -->
            <AccordionSimpleCard cardId="accordion-electricMeterAttachments">
              <template v-slot:header>
                <slot name="header">
                  {{ $t('electricMeterAttachments') }}
                  <ZipDownload
                    attachmentType="electricMeterAttachments"
                    :attachments="project.electricMeterAttachmentsZip"
                    :project="project"
                    :light="true"
                  />
                </slot>
              </template>

              <AttachmentGallery
                :attachments="project.electricMeterAttachments"
                attachmentType="electricMeterAttachments"
                v-on:attachment-list:delete="onDeleteProjectAttachment"
              ></AttachmentGallery>
            </AccordionSimpleCard>
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <div class="accordion" role="tablist">
          <!-- Anbebot Q-Cells (automatisch generiert)  -->
          <AccordionSimpleCard cardId="accordion-documents-projectOfferAttachments">
            <template v-slot:header>
              <slot name="header">{{ $t('offers_qcells') }} ({{ $t('autoGenerated') }}) </slot>
            </template>

            <div>
              <ButtonWait
                :startCallback="startProjectOfferBuild"
                :pollCallback="updateProjectOfferBuildState"
                class=""
              >
                <template v-slot:buttonText>
                  {{ $t('generate_offer') }}
                </template>
              </ButtonWait>
              <router-link
                class="btn btn-success ml-2 mt-3"
                :to="{
                  name: 'ProjectOfferPrintPage',
                  params: { projectNumber: project.number },
                }"
                target="_blank"
              >
                <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
              </router-link>
            </div>

            <AttachmentList
              v-if="
                project.projectOfferAttachments &&
                  project.projectOfferAttachmentsBuildState === 'FINISHED'
              "
              :attachments="project.projectOfferAttachments"
              attachmentType="projectOfferAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Aufmaßprotokoll (automatisch generiert)  -->
          <AccordionSimpleCard cardId="accordion-documents-measurement-report">
            <template v-slot:header>
              <slot name="header"> {{ $t('measurementReport') }} ({{ $t('autoGenerated') }})</slot>
            </template>
            <div>
              <ButtonWait
                :startCallback="startMeasurementReportBuild"
                :pollCallback="fetchMeasurementReportBuildState"
                class=""
              >
                <template v-slot:buttonText>
                  {{ $t('generate_report') }}
                </template>
              </ButtonWait>
              <router-link
                class="btn btn-success ml-2 mt-3"
                :to="{
                  name: 'MeasurementPrintPage',
                  params: { projectNumber: project.number },
                }"
                target="_blank"
              >
                <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
              </router-link>
            </div>
            <AttachmentList
              v-if="
                project.measurementReportAttachments &&
                  project.measurementReportAttachmentsBuildState === 'FINISHED'
              "
              :attachments="project.measurementReportAttachments"
              attachmentType="measurementReportAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Abnahmeprotokoll (automatisch generiert)  -->
          <AccordionSimpleCard cardId="accordion-documents-acceptance-report">
            <template v-slot:header>
              <slot name="header"> {{ $t('acceptanceReport') }} ({{ $t('autoGenerated') }})</slot>
            </template>
            <div>
              <ButtonWait
                :startCallback="startReportBuild"
                :pollCallback="fetchReportBuildState"
                class=""
              >
                <template v-slot:buttonText>
                  {{ $t('generate_report') }}
                </template>
              </ButtonWait>
              <router-link
                class="btn btn-success ml-2 mt-3"
                :to="{
                  name: 'ReportPrintPage',
                  params: { projectNumber: project.number },
                }"
                target="_blank"
              >
                <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
              </router-link>
            </div>
            <AttachmentList
              v-if="project.reportAttachmentsBuildState === 'FINISHED'"
              :attachments="project.acceptanceReportAttachments"
              attachmentType="acceptanceReportAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Netzanmeldung (automatisch generiert) -->
          <AccordionSimpleCard cardId="accordion-documents-netRegistrationAttachmentsGenerated">
            <template v-slot:header>
              <slot name="header">
                {{ $t('netRegistrationAttachments') }} ({{ $t('autoGenerated') }})
                <ZipDownload
                  v-if="project.netRegistrationAttachmentsBuildState === 'FINISHED'"
                  attachmentType="netRegistrationAttachmentsGenerated"
                  :attachments="project.netRegistrationAttachmentsGeneratedZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>
            <p>
              <small>
                <strong v-if="project.powerCompany">{{ project.powerCompany.company }}</strong>
                {{ $t('is_approved_for_net_registration_generation') }} :
                <b-badge
                  v-if="project.powerCompany"
                  :variant="
                    getYesNoStateColor(project.powerCompany.netRegistrationDocumentsApprovedState)
                  "
                  >{{ $t(project.powerCompany.netRegistrationDocumentsApprovedState) }}</b-badge
                ></small
              >
            </p>
            <p>
              <ButtonWait
                v-if="project.client && project.client.name !== 'SOMV Project Consulting GmbH'"
                :startCallback="startNetRegistrationAttachmentsBuild"
                :pollCallback="updateNetRegistrationAttachmentsBuildState"
              >
                <template v-slot:buttonText>
                  {{ $t('generate_net_registration_attachments') }}
                </template>
              </ButtonWait>
            </p>
            <AttachmentList
              v-if="project.netRegistrationAttachmentsBuildState === 'FINISHED'"
              :attachments="project.netRegistrationAttachmentsGenerated"
              attachmentType="netRegistrationAttachmentsGenerated"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Netzanmeldung (hochgeladen) -->
          <AccordionSimpleCard cardId="accordion-documents-netRegistrationAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('netRegistrationAttachments') }} ({{ $t('uploaded') }})
                <ZipDownload
                  attachmentType="netRegistrationAttachments"
                  :attachments="project.netRegistrationAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>
            <AttachmentList
              :attachments="project.netRegistrationAttachments"
              attachmentType="netRegistrationAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Downloads -->
          <AccordionSimpleCard cardId="accordion-documents-downloads">
            <template v-slot:header>
              <slot name="header">
                {{ $t('downloads') }} {{ $t('combinedNetRegistrationAttachmentsZip') }}
              </slot>
            </template>

            <p>
              {{ $t('combinedNetRegistrationAttachmentsZip') }}
              <ZipDownload
                attachmentType="combinedNetRegistrationAttachments"
                :attachments="project.combinedNetRegistrationAttachmentsZip"
                :project="project"
              />
            </p>
          </AccordionSimpleCard>

          <!-- Inbetriebnahmemeldung (automatisch generiert) -->
          <AccordionSimpleCard
            cardId="accordion-documents-operationRegistrationAttachmentsGenerated"
          >
            <template v-slot:header>
              <slot name="header">
                {{ $t('operationRegistrationAttachments') }} ({{ $t('autoGenerated') }})
                <ZipDownload
                  v-if="project.operationRegistrationAttachmentsBuildState === 'FINISHED'"
                  attachmentType="operationRegistrationAttachmentsGenerated"
                  :attachments="project.operationRegistrationAttachmentsGeneratedZip"
                  :project="project"
                  :light="true"
              /></slot>
            </template>
            <p>
              <small>
                <strong v-if="project.powerCompany">{{ project.powerCompany.company }}</strong>
                {{ $t('is_approved_for_operation_registration_generation') }}:
                <b-badge
                  v-if="project.powerCompany"
                  :variant="
                    getYesNoStateColor(
                      project.powerCompany.operationRegistrationDocumentsApprovedState
                    )
                  "
                  >{{
                    $t(project.powerCompany.operationRegistrationDocumentsApprovedState)
                  }}</b-badge
                ></small
              >
            </p>
            <p>
              <ButtonWait
                v-if="project.client && project.client.name !== 'SOMV Project Consulting GmbH'"
                :startCallback="startoperationRegistrationAttachmentsBuild"
                :pollCallback="updateoperationRegistrationAttachmentsBuildState"
              >
                <template v-slot:buttonText>
                  {{ $t('generate_operation_registration_attachments') }}
                </template>
              </ButtonWait>
            </p>
            <AttachmentList
              v-if="project.operationRegistrationAttachmentsBuildState === 'FINISHED'"
              :attachments="project.operationRegistrationAttachmentsGenerated"
              attachmentType="operationRegistrationAttachmentsGenerated"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Inbetriebnahmemeldung (hochgeladen) -->
          <AccordionSimpleCard cardId="accordion-documents-operationRegistrationAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('operationRegistrationAttachments') }} ({{ $t('uploaded') }})
                <ZipDownload
                  attachmentType="operationRegistrationAttachments"
                  :attachments="project.operationRegistrationAttachmentsZip"
                  :project="project"
                  :light="true"
                />
              </slot>
            </template>

            <AttachmentList
              :attachments="project.operationRegistrationAttachments"
              attachmentType="operationRegistrationAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Übersichtsschaltbild (automatisch generiert) -->
          <AccordionSimpleCard cardId="accordion-documents-overviewSchematicAttachmentsGenerated">
            <template v-slot:header>
              <slot name="header">
                {{ $t('overviewSchematicAttachments') }} ({{ $t('autoGenerated') }})
                <ZipDownload
                  v-if="project.overviewSchematicAttachmentsBuildState === 'FINISHED'"
                  attachmentType="overviewSchematicAttachmentsGenerated"
                  :attachments="project.overviewSchematicAttachmentsGeneratedZip"
                  :project="project"
                  :light="true"
                />
                <span class="error ml-2" v-if="!requirementsValidState('overviewSchematic')">{{
                  invalidFeedbackNA
                }}</span>
              </slot>
            </template>
            <p>
              <ButtonWait
                v-if="project.client && project.client.name !== 'SOMV Project Consulting GmbH'"
                :startCallback="startOverviewSchematicAttachmentsBuild"
                :pollCallback="updateOverviewSchematicAttachmentsBuildState"
              >
                <template v-slot:buttonText>
                  {{ $t('generate_overview_schematic_attachments') }}
                </template>
              </ButtonWait>
            </p>
            <AttachmentList
              v-if="project.overviewSchematicAttachmentsBuildState === 'FINISHED'"
              :attachments="project.overviewSchematicAttachmentsGenerated"
              attachmentType="overviewSchematicAttachmentsGenerated"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Übersichtsschaltbild  (hochgeladen) -->
          <AccordionSimpleCard cardId="accordion-documents-overviewSchematicAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('overviewSchematicAttachments') }} ({{ $t('uploaded') }})
                <ZipDownload
                  attachmentType="overviewSchematicAttachments"
                  :attachments="project.overviewSchematicAttachmentsZip"
                  :project="project"
                  :light="true"
                />
                <span class="error" v-if="!requirementsValidState('overviewSchematic')">{{
                  invalidFeedbackNA
                }}</span>
              </slot>
            </template>
            <AttachmentList
              :attachments="project.overviewSchematicAttachments"
              attachmentType="overviewSchematicAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Vollmacht -->
          <AccordionSimpleCard cardId="accordion-documents-mandateAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('mandateAttachments') }}
                <ZipDownload
                  attachmentType="mandateAttachments"
                  :attachments="project.mandateAttachmentsZip"
                  :project="project"
                  :light="true"
                />
                <span class="error" v-if="!requirementsValidState('mandateState')">{{
                  invalidFeedbackNA
                }}</span></slot
              >
            </template>
            <AttachmentList
              :attachments="project.mandateAttachments"
              attachmentType="mandateAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Lageplan -->
          <AccordionSimpleCard cardId="accordion-documents-layoutPlanAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('layoutPlanAttachments') }}
                <ZipDownload
                  attachmentType="layoutPlanAttachments"
                  :attachments="project.layoutPlanAttachmentsZip"
                  :project="project"
                  :light="true"
                />
                <span class="error" v-if="!requirementsValidState('layoutPlanState')">{{
                  invalidFeedbackNA
                }}</span>
              </slot>
            </template>
            <AttachmentList
              :attachments="project.layoutPlanAttachments"
              attachmentType="layoutPlanAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Projektbeschreibung -->
          <AccordionSimpleCard cardId="accordion-documents-projectDescriptionAttachments">
            <template v-slot:header>
              <slot name="header">
                {{ $t('projectDescriptionAttachments') }}
                <ZipDownload
                  attachmentType="projectDescriptionAttachments"
                  :attachments="project.projectDescriptionAttachmentsZip"
                  :project="project"
                  :light="true"
              /></slot>
            </template>
            <AttachmentList
              :attachments="project.projectDescriptionAttachments"
              attachmentType="projectDescriptionAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>

          <!-- Weitere Dokumente -->
          <AccordionSimpleCard cardId="accordion-documents-miscAttachments" v-if="isAdmin">
            <template v-slot:header>
              <slot name="header">
                {{ $t('miscAttachments') }}
                <ZipDownload
                  attachmentType="miscAttachments"
                  :attachments="project.miscAttachmentsZip"
                  :project="project"
                  :light="true"
              /></slot>
            </template>
            <AttachmentList
              :attachments="project.miscAttachments"
              attachmentType="miscAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </AccordionSimpleCard>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import AttachmentGallery from '@/components/AttachmentGallery';
import ZipDownload from '@/components/ZipDownload';
import ButtonWait from '@/components/ButtonWait.vue';
import { getYesNoStateColor } from '@/helpers/colors';
import AccordionSimpleCard from '@/components/AccordionSimpleCard';

export default {
  components: {
    Multiselect,
    DropzoneUpload,
    AttachmentList,
    AttachmentGallery,
    ZipDownload,
    ButtonWait,
    AccordionSimpleCard,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      selectedProjectAttachmentType: null,
      filteredProjectAttachmentTypes: [],
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient', 'getCurrentUser']),
    ...mapGetters(['getEnumValues']),
    isUploadDisabled() {
      return this.selectedProjectAttachmentType === null ? true : false;
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'fetchNetRegistrationAttachmentsBuildState',
      'fetchOperationRegistrationAttachmentsBuildState',
      'fetchOverviewSchematicAttachmentsBuildState',
      'buildNetRegistration',
      'buildOperationRegistration',
      'buildOverviewSchematic',
      'buildReport',
      'fetchReportAttachmentsBuildState',
      'buildMeasurementReport',
      'serviceMeasurementReport',
      'fetchMeasurementReportAttachmentsBuildState',
      'fetchProjectOfferAttachmentsBuildState',
      'buildProjectOffer',
    ]),
    /** @todo changes to project are lost */
    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    async onUploadProjectAttachments(payload) {
      for (const file of payload.files) {
        await this.uploadProjectAttachment({
          file,
          parentId: payload.parentId,
          parentType: payload.parentType,
        });
      }
      if (payload.parentType === 'layoutPlanAttachments') {
        await this.fetchProjectByNumber(this.number);
      }
    },
    onProjectAttachmentTypeSearch(query) {
      this.filteredProjectAttachmentTypes = [];
      this.getProjectAttachmentTypes.map((projectAttachmentType) => {
        if (
          this.$t(projectAttachmentType)
            .toLowerCase()
            .indexOf(query) >= 0
        ) {
          this.filteredProjectAttachmentTypes.push(projectAttachmentType);
        }
      });
    },
    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startNetRegistrationAttachmentsBuild() {
      if (this.project) {
        await this.buildNetRegistration(this.project.id);
      }
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateNetRegistrationAttachmentsBuildState() {
      this.fetchNetRegistrationAttachmentsBuildState(this.project.number);
      return this.project.netRegistrationAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startoperationRegistrationAttachmentsBuild() {
      if (this.project) {
        await this.buildOperationRegistration(this.project.id);
      }
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateoperationRegistrationAttachmentsBuildState() {
      this.fetchOperationRegistrationAttachmentsBuildState(this.project.number);
      return this.project.operationRegistrationAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startOverviewSchematicAttachmentsBuild() {
      if (this.project) {
        await this.buildOverviewSchematic(this.project.id);
      }
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateOverviewSchematicAttachmentsBuildState() {
      this.fetchOverviewSchematicAttachmentsBuildState(this.project.number);
      // console.log(this.project.overviewSchematicAttachmentsBuildState);
      return this.project.overviewSchematicAttachmentsBuildState;
    },

    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startReportBuild() {
      if (this.project) {
        await this.buildReport(this.project.id);
      }
    },
    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    fetchReportBuildState() {
      this.fetchReportAttachmentsBuildState(this.project.number);
      return this.project.reportAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startMeasurementReportBuild() {
      if (this.project) {
        await this.buildMeasurementReport(this.project.id);
      }
    },
    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    fetchMeasurementReportBuildState() {
      this.fetchMeasurementReportAttachmentsBuildState(this.project.number);
      return this.project.measurementReportAttachmentsBuildState;
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateProjectOfferBuildState() {
      this.fetchProjectOfferAttachmentsBuildState(this.project.number);
      return this.project.projectOfferAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startProjectOfferBuild() {
      if (this.project) {
        await this.buildProjectOffer(this.project.id);
      }
    },

    getYesNoStateColor,
  },

  async mounted() {
    this.filteredProjectAttachmentTypes = this.isClient
      ? this.getEnumValues('EmployerProjectAttachmentType')
      : this.getEnumValues('ProjectAttachmentType');

    // console.log(this.filteredProjectAttachmentTypes);

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>
<style lang="scss">
.error {
  color: $danger;
}
</style>
