<template>
  <div>
    <AccordionCard :state="cards.stringPlanAttachments">
      <template v-slot:header>
        {{ $t('stringPlanAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('stringPlanAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('stringPlanAttachments_description') }}

          {{ $t('panelBottomViewAttachments_description') }} <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[47].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.stringPlanAttachments"
            attachmentType="stringPlanAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isstringPlanAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="stringPlanAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isstringPlanAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.allPanelsVisibleAttachments">
      <template v-slot:header>
        {{ $t('allPanelsVisibleAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('allPanelsVisibleAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[37].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.allPanelsVisibleAttachments"
            attachmentType="allPanelsVisibleAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isallPanelsVisibleAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="allPanelsVisibleAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isallPanelsVisibleAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <div v-if="isPitchedRoof">
      <AccordionCard :state="cards.clampAreaAttachments">
        <template v-slot:header>
          {{ $t('clampAreaAttachments') }}
        </template>

        <div class="flex-row">
          <div class="flex-column name">{{ $t('clampAreaAttachments') }}</div>
          <div class="flex-column select">
            <div>
              {{ $t('templateAttachments') }}:
              <AttachmentGallery
                :attachments="getReportTemplateImages[40].templateAttachments"
                :deletable="false"
                :sortByName="false"
                :showFilename="false"
              ></AttachmentGallery>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column name"></div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.clampAreaAttachments"
              attachmentType="clampAreaAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="isclampAreaAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="clampAreaAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="isclampAreaAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </AccordionCard>

      <AccordionCard :state="cards.trayfDistanceAttachments">
        <template v-slot:header>
          {{ $t('trayfDistanceAttachments') }}
        </template>

        <div class="flex-row">
          <div class="flex-column name">{{ $t('trayfDistanceAttachments') }}</div>
          <div class="flex-column select">
            <div>
              {{ $t('templateAttachments') }}:
              <AttachmentGallery
                :attachments="getReportTemplateImages[30].templateAttachments"
                :deletable="false"
                :sortByName="false"
                :showFilename="false"
              ></AttachmentGallery>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column name"></div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.trayfDistanceAttachments"
              attachmentType="trayfDistanceAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="istrayfDistanceAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="trayfDistanceAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="istrayfDistanceAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </AccordionCard>

      <AccordionCard :state="cards.railOverlap">
        <template v-slot:header>
          {{ $t('solarPanelE1State') }}
        </template>

        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('solarPanelE1State') }}
          </div>
          <div class="flex-column select">
            <SelectButton
              v-model="project.enpalReport.solarPanelE1State"
              :options="getEnumValues('YesNo')"
            >
              <template #option="props">
                {{ $t(props.option) }}
              </template>
            </SelectButton>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column name">{{ $t('railOverlapAttachments') }}</div>
          <div class="flex-column select">
            <div>
              {{ $t('templateAttachments') }}:
              <AttachmentGallery
                :attachments="getReportTemplateImages[32].templateAttachments"
                :deletable="false"
                :sortByName="false"
                :showFilename="false"
              ></AttachmentGallery>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <div class="flex-column name"></div>
          <div class="flex-column select">
            <AttachmentGallery
              :attachments="project.railOverlapAttachments"
              attachmentType="railOverlapAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
              :sortByName="false"
            ></AttachmentGallery>
            <DropzoneUpload
              class="mt-2"
              :complete="israilOverlapAttachmentsUploadComplete"
              :parentId="project.id"
              parentType="railOverlapAttachments"
              :uploadCallback="onUploadProjectAttachment"
              v-on:dropzone-upload:reset="israilOverlapAttachmentsUploadComplete = false"
              acceptedFileTypes="image/*"
            />
          </div>
        </div>
      </AccordionCard>
    </div>

    <AccordionCard :state="cards.panelBottomViewAttachments">
      <template v-slot:header>
        {{ $t('panelBottomViewAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('panelBottomViewAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('panelBottomViewAttachments_description') }} <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[33].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.panelBottomViewAttachments"
            attachmentType="panelBottomViewAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ispanelBottomViewAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="panelBottomViewAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="ispanelBottomViewAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.solarModuleTypePlateAttachments">
      <template v-slot:header>
        {{ $t('solarModuleTypePlateAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('solarModuleTypePlateAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('solarModuleTypePlateAttachments_description') }} <br />
          <AttachmentGallery
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.solarModuleTypePlateAttachments"
            attachmentType="solarModuleTypePlateAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isSolarModuleTypePlateAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="solarModuleTypePlateAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isSolarModuleTypePlateAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.solarPanelsOptional"
      :project="project"
      notesType="solarPanelNotes"
      :attachments="project.solarPanelsOptionalAttachments"
      attachmentType="solarPanelsOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isstringPlanAttachmentsUploadComplete: false,
      isallPanelsVisibleAttachmentsUploadComplete: false,
      ispanelBottomViewAttachmentsUploadComplete: false,
      israilOverlapAttachmentsUploadComplete: false,
      isclampAreaAttachmentsUploadComplete: false,
      istrayfDistanceAttachmentsUploadComplete: false,
      isSolarModuleTypePlateAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isstringPlanAttachmentsUploadComplete = true;
          this.isallPanelsVisibleAttachmentsUploadComplete = true;
          this.ispanelBottomViewAttachmentsUploadComplete = true;
          this.israilOverlapAttachmentsUploadComplete = true;
          this.isclampAreaAttachmentsUploadComplete = true;
          this.istrayfDistanceAttachmentsUploadComplete = true;
          this.isSolarModuleTypePlateUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
