<template>
  <div>
    <AccordionCard :state="cards.brickAndUkAssemblyType">
      <template v-slot:header>
        {{ $t('brickAndUkAssemblyType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkAssemblyType') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.brickAndUkAssemblyType"
            :options="getEnumValues('BrickAndUkAssemblyType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickCoverWidthAttachments">
      <template v-slot:header>
        {{ $t('brickCoverWidthAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('brickCoverWidthAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[29].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.brickCoverWidthAttachments"
            attachmentType="brickCoverWidthAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isbrickCoverWidthAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="brickCoverWidthAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isbrickCoverWidthAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctBrickHandlingAttachments">
      <template v-slot:header>
        {{ $t('correctBrickHandlingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('correctBrickHandlingAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[42].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.correctBrickHandlingAttachments"
            attachmentType="correctBrickHandlingAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscorrectBrickHandlingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="correctBrickHandlingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscorrectBrickHandlingAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndUkA2State">
      <template v-slot:header>
        {{ $t('brickAndUkA2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkA2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.brickAndUkA2State"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndUkBState">
      <template v-slot:header>
        {{ $t('brickAndUkBState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkBState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.brickAndUkBState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndSubstructureAttachments">
      <template v-slot:header>
        {{ $t('brickAndSubstructureAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('brickAndSubstructureAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[38].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.brickAndSubstructureAttachments"
            attachmentType="brickAndSubstructureAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isbrickAndSubstructureAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="brickAndSubstructureAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isbrickAndSubstructureAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableAndPlugAttachedToRailState">
      <template v-slot:header>
        {{ $t('cableAndPlugAttachedToRailState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableAndPlugAttachedToRailState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.cableAndPlugAttachedToRailState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofHookWithEarthingAttachments">
      <template v-slot:header>
        {{ $t('roofHookWithEarthingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofHookWithEarthingAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('roofHookWithEarthingAttachments_description') }}
          <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[31].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofHookWithEarthingAttachments"
            attachmentType="roofHookWithEarthingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isroofHookWithEarthingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="roofHookWithEarthingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isroofHookWithEarthingAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.railsEarthed">
      <template v-slot:header>
        {{ $t('railsEarthed') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('railsEarthed') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.railsEarthed"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.correctCableInputAttachments">
      <template v-slot:header>
        {{ $t('correctCableInputAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctCableInputAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('correctCableInputAttachments_description') }}
          <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[43].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.correctCableInputAttachments"
            attachmentType="correctCableInputAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscorrectCableInputAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="correctCableInputAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscorrectCableInputAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.protectedUnderBrickCableRoutingAttachments">
      <template v-slot:header>
        {{ $t('protectedUnderBrickCableRoutingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('protectedUnderBrickCableRoutingAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('protectedUnderBrickCableRoutingAttachments_description') }}
          <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[39].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.protectedUnderBrickCableRoutingAttachments"
            attachmentType="protectedUnderBrickCableRoutingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isprotectedUnderBrickCableRoutingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="protectedUnderBrickCableRoutingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isprotectedUnderBrickCableRoutingAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.brickAndUkOptional"
      :project="project"
      notesType="brickAndUkOptionalNotes"
      :attachments="project.brickAndSubstructureOptionalAttachments"
      attachmentType="brickAndSubstructureOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    AccordionCardOptionalInput,
    // InputText,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isbrickCoverWidthAttachmentsUploadComplete: false,
      iscorrectBrickHandlingAttachmentsUploadComplete: false,
      isbrickAndSubstructureAttachmentsUploadComplete: false,
      isroofHookWithEarthingAttachmentsUploadComplete: false,
      iscorrectCableInputAttachmentsUploadComplete: false,
      isprotectedUnderBrickCableRoutingAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isbrickCoverWidthAttachmentsUploadComplete = true;
          this.iscorrectBrickHandlingAttachmentsUploadComplete = true;
          this.isbrickAndSubstructureAttachmentsUploadComplete = true;
          this.isroofHookWithEarthingAttachmentsUploadComplete = true;
          this.iscorrectCableInputAttachmentsUploadComplete = true;
          this.isprotectedUnderBrickCableRoutingAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
