<template>
  <div>
    <AccordionCard :state="cards.internetConnectionTypeState">
      <template v-slot:header>
        {{ $t('internetConnectionTypeState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('internetConnectionTypeState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.internetConnectionTypeState"
            :options="getEnumValues('InternetConnectionType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.lanConnectionPresentState">
      <template v-slot:header>
        {{ $t('lanConnectionPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('lanConnectionPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.lanConnectionPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';

export default {
  components: {
    AccordionCard,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchEnumValues']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    await this.fetchEnumValues('InternetConnectionType');
  },
};
</script>

<style lang="scss" scoped></style>
