<template>
  <div>
    <AccordionCard :state="cards.jobSafetyEquipmentUsedState">
      <template v-slot:header>
        {{ $t('jobSafetyEquipmentUsedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('jobSafetyEquipmentUsedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.jobSafetyEquipmentUsedState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.scaffoldAttachments">
      <template v-slot:header>
        {{ $t('scaffoldAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('scaffoldAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.scaffoldAttachments"
            attachmentType="scaffoldAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isScaffoldAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="scaffoldAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isScaffoldAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.jobSafetyOptional"
      :project="project"
      notesType="jobSafetyOptionalNotes"
      :attachments="project.jobSafetyOptionalAttachments"
      attachmentType="jobSafetyOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isScaffoldAttachmentsUploadComplete: false,
      isjobSafetyOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isScaffoldAttachmentsUploadComplete = true;
          this.isjobSafetyOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
