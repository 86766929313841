<template>
  <div class="item-select">
    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      :value="items"
      :filters.sync="tableState.filters"
      :lazy="true"
      :loading="isLoading"
      :paginator="true"
      :totalRecords="getItemCount"
      :rows.sync="tableState.pagination.pageSize"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 15, 20, 50, 100, 200]"
      dataKey="number"
      :rowHover="true"
      :stateKey="tableState.filterName"
      filterDisplay="row"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      stateStorage="local"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h4 class="page-title">{{ $t('item_inventory') }}</h4>
          </div>
          <div class="table-header-right">
            <button class="btn btn-sm btn-inverse" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column field="wholesaleNr" :header="$t('itemNumber')" sortable :showFilterMenu="false">
        <template #body="slotProps">{{ slotProps.data.wholesaleNr }}</template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="description" :header="$t('description')" sortable :showFilterMenu="false">
        <template #body="slotProps"><span v-html="slotProps.data.description"></span> </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>
      <Column
        field="unit"
        :header="$t('unit')"
        sortable
        filterMatchMode="equals"
        :headerStyle="{ width: '90px' }"
      >
        <template #body="slotProps"> {{ $t(slotProps.data.unit) }} </template>
      </Column>

      <Column
        field="addRemoveCount"
        :headerStyle="{ width: '90px' }"
        :header="$t('add') + '/' + $t('remove')"
      >
        <template #body="slotProps">
          <div class="item-add-remove-grid">
            <InputText
              type="text"
              v-model="slotProps.data.addRemoveCount"
              class="mr-2"
              style="max-width:50px"
            />
            <button
              class="btn btn-sm btn-success mr-2"
              @click.prevent="onAddItemToProject(slotProps.data)"
            >
              <i class="fa fa-plus" />
            </button>
            <button
              class="btn btn-sm btn-danger "
              @click.prevent="onRemoveItemFromProject(slotProps.data)"
            >
              <i class="fa fa-minus" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';

export default {
  name: 'ItemSelect',
  components: {
    DataTable,
    Column,
    InputText,
  },
  props: {
    itemType: { type: String, default: 'STANDARD' },
  },
  data() {
    return {
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        inventory: { value: null, matchMode: FilterMatchMode.EQUALS },
        unit: { value: null, matchMode: FilterMatchMode.EQUALS },
        price: { value: null, matchMode: FilterMatchMode.EQUALS },
        wholesaleNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        pagination: { skip: 0, pageSize: 10 },
        sorting: { sortField: 'number', sortOrder: 1 },
        filters: this.defaultFilters,
        filterName: 'itemselect-table-filters',
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getItems', 'getItemsByType', 'getItemCount']),
    items() {
      // return this.getItemsByType(this.itemType);
      return this.getItems;
    },
    pageOffset() {
      return this.tableState.pagination.skip * this.tableState.pagination.pageSize;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
  },
  methods: {
    ...mapActions(['fetchItemsByType', 'fetchItems', 'fetchItemsPaginated']),
    onAddItemToProject(item) {
      this.$emit('item-select:add-item', item);
    },
    onRemoveItemFromProject(item) {
      this.$emit('item-select:remove-item', item);
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination = { skip: event.page, pageSize: event.rows };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },

    async onRemoveFilters(event) {
      this.tableState.sorting = this.defaultSorting;
      this.tableState.pagination = this.defaultPagination;
      this.tableState.filters = this.defaultFilters;

      localStorage.removeItem(this.tableState.filterName);
      await this.loadTableData();
    },

    async onStateRestore(event) {
      // this.setupFilters();

      if (this.tableState) {
        this.tableState.sorting.sortField = event.sortField
          ? event.sortField
          : this.defaultSorting.sortField;
        this.tableState.sorting.sortOrder = event.sortOrder
          ? event.sortOrder
          : this.defaultSorting.sortOrder;
        this.tableState.pagination.skip = 0;
        this.tableState.pagination.pageSize = event.rows;
      }
    },

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchItemsPaginated({
        pagination: this.tableState.pagination,
        sorting: this.tableState.sorting,
        filters: { ...this.tableState.filters },
      });
    },
  },
  async created() {},
  async mounted() {
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
.item-add-remove-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
h4.page-title {
  font-size: 15px;
}
</style>
