<template>
  <div>
    <div class="accordion" role="tablist" v-if="project && project.enpalReport">
      <!-- A. Abnahme mangelfrei -->
      <AcceptanceFaultFree :cards="cards" :project="project" />

      <!-- B. Inbetriebnahme erfolgt -->
      <OperationFinished :cards="cards" :project="project" />

      <!-- D. Inbetriebnahme erfolgt -->
      <ExistingDamage :cards="cards" :project="project" />

      <h1>1. {{ $t('jobSafety') }}</h1>
      <JobSafety :cards="cards" :project="project" />

      <div v-if="isFlatRoof">
        <h1>2. {{ $t('ballastAndUk') }}</h1>
        <BallastAndUk :cards="cards" :project="project" />
      </div>

      <div v-if="isPitchedRoof">
        <h1>2. {{ $t('brickAndUk') }}</h1>
        <BrickAndSubstructure :cards="cards" :project="project" />
      </div>

      <h1>3. {{ $t('solarPanels') }}</h1>
      <SolarPanels :cards="cards" :project="project" />

      <h1>4. {{ $t('cableManagement') }}</h1>
      <CableManagement :cards="cards" :project="project" />

      <h1>
        5. {{ $t('inverter') }} / {{ $t('matebox') }} / {{ $t('storage') }} &
        {{ $t('variousBreakthroughs') }}
      </h1>
      <Inverter :cards="cards" :project="project" />

      <h1>6. {{ $t('potential') }}</h1>
      <Potential :cards="cards" :project="project" />

      <h1>7. {{ $t('hak') }}</h1>
      <Hak :cards="cards" :project="project" />

      <h1>
        8. {{ $t('electricMeterCabinet') }} & {{ $t('gak') }} & {{ $t('variousBreakthroughs') }}
      </h1>
      <ElectricMeter :cards="cards" :project="project" />

      <h1>9. {{ $t('wallbox') }}</h1>
      <Wallbox :cards="cards" :project="project" />

      <h1>10. {{ $t('monitoring') }} & {{ $t('configuration') }}</h1>
      <MonitoringAndConfiguration :cards="cards" :project="project" />

      <h1>11. {{ $t('measurements') }}</h1>
      <Measurement :cards="cards" :project="project" />

      <h1>12. {{ $t('documents') }}</h1>
      <Documents :cards="cards" :project="project" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AcceptanceFaultFree from './MobileReport/AcceptanceFaultFree.vue';
import OperationFinished from './MobileReport/OperationFinished.vue';
import ExistingDamage from './MobileReport/ExistingDamage.vue';
import JobSafety from './MobileReport/JobSafety.vue';
import BallastAndUk from './MobileReport/BallastAndUk.vue';
import BrickAndSubstructure from './MobileReport/BrickAndSubstructure.vue';
import SolarPanels from './MobileReport/SolarPanels.vue';
import CableManagement from './MobileReport/CableManagement.vue';
import Inverter from './MobileReport/Inverter.vue';
import Potential from './MobileReport/Potential.vue';
import Hak from './MobileReport/Hak.vue';
import ElectricMeter from './MobileReport/ElectricMeter.vue';
import MonitoringAndConfiguration from './MobileReport/MonitoringAndConfiguration.vue';
import Measurement from './MobileReport/Measurement.vue';
import Wallbox from './MobileReport/Wallbox.vue';
import Documents from './MobileReport/Documents.vue';

export default {
  name: 'MobileProjectsReport',
  components: {
    AcceptanceFaultFree,
    OperationFinished,
    ExistingDamage,
    JobSafety,
    BallastAndUk,
    BrickAndSubstructure,
    SolarPanels,
    CableManagement,
    Inverter,
    Potential,
    ElectricMeter,
    MonitoringAndConfiguration,
    Measurement,
    Hak,
    Wallbox,
    Documents,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      cards: {
        /** A. Abnahme mangelfrei */
        acceptanceFaultFree: {
          name: 'acceptanceFaultFree',
          opened: false,
          navigateTo: this.goToCard,
          successor: 'operationFinished',
          predecessor: null,
          completed: this.completedState,
        },
        /** Inbetriebnahme erfolgt */
        operationFinished: {
          name: 'operationFinished',
          navigateTo: this.goToCard,
          successor: 'existingDamage',
          predecessor: 'acceptanceFaultFree',
          opened: false,
          completed: this.completedState,
        },
        /** Vorschäden */
        existingDamage: {
          name: 'existingDamage',
          navigateTo: this.goToCard,
          successor: 'jobSafetyEquipmentUsedState',
          predecessor: 'operationFinished',
          opened: false,
          completed: this.completedState,
        },
        /** Arbeitskleidung */
        jobSafetyEquipmentUsedState: {
          name: 'jobSafetyEquipmentUsedState',
          navigateTo: this.goToCard,
          successor: 'scaffoldAttachments',
          predecessor: 'existingDamage',
          opened: false,
          completed: this.completedState,
        },
        /** Foto Gerüst/RSS */
        scaffoldAttachments: {
          name: 'scaffoldAttachments',
          navigateTo: this.goToCard,
          successor: 'jobSafetyOptional',
          predecessor: 'jobSafetyEquipmentUsedState',
          opened: false,
          completed: this.completedState,
        },
        /** Arbeitssicherheit Optionale Fotos */
        jobSafetyOptional: {
          name: 'jobSafetyOptional',
          navigateTo: this.goToCard,
          successor: 'brickAndUkAssemblyType',
          predecessor: 'scaffoldAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Montageart*/
        brickAndUkAssemblyType: {
          name: 'brickAndUkAssemblyType',
          navigateTo: this.goToCard,
          successor: 'ballastRockWeightOrBrickCoverWidthAttachments',
          predecessor: 'jobSafetyOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Gewicht eines Ballaststeins*/
        ballastRockWeight: {
          name: 'ballastRockWeight',
          navigateTo: this.goToCard,
          successor: 'ballastAttachments',
          predecessor: 'brickAndUkAssemblyType',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Gesamte UK mit Ballastierung sichtbar */
        ballastAttachments: {
          name: 'ballastAttachments',
          navigateTo: this.goToCard,
          successor: 'railToRoofDistanceAttachments',
          predecessor: 'ballastRockWeight',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung / Ziegel & UK - Abstand Schienen zum Dachrand */
        railToRoofDistanceAttachments: {
          name: 'railToRoofDistanceAttachments',
          navigateTo: this.goToCard,
          successor: 'cableAndPlugAttachedToRailState',
          predecessor: 'ballastAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Kabel & Stecker an Schiene befestigt (keine Berürhung Dach) */
        cableAndPlugAttachedToRailState: {
          name: 'cableAndPlugAttachedToRailState',
          navigateTo: this.goToCard,
          successor: 'earthingSubstructureAttachmentsOrRoofHookWithEarthingAttachments',
          predecessor: 'railToRoofDistanceAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Erdung UK */
        earthingSubstructureAttachments: {
          name: 'earthingSubstructureAttachments',
          navigateTo: this.goToCard,
          successor: 'railsEarthed',
          predecessor: 'cableAndPlugAttachedToRailState',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Schienen untereinnander geerdet? */
        railsEarthed: {
          name: 'railsEarthed',
          navigateTo: this.goToCard,
          successor: 'brickAndUkOptionalOrCorrectCableInputAttachments',
          predecessor: 'earthingSubstructureAttachmentsOrRoofHookWithEarthingAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ballastierung & UK - Optionale Fotos / Notizen */
        brickAndUkOptional: {
          name: 'brickAndUkOptional',
          navigateTo: this.goToCard,
          successor: 'brickCoverWidthAttachments',
          predecessor: 'railsEarthedOrProtectedUnderBrickCableRoutingAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Ziegeldeckbreite */
        brickCoverWidthAttachments: {
          name: 'brickCoverWidthAttachments',
          navigateTo: this.goToCard,
          successor: 'correctBrickHandlingAttachments',
          predecessor: 'brickAndUkAssemblyType',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Ziegeldeckbreite */
        correctBrickHandlingAttachments: {
          name: 'correctBrickHandlingAttachments',
          navigateTo: this.goToCard,
          successor: 'brickAndUkA2State',
          predecessor: 'brickCoverWidthAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Mit Flex bearbeitet? */
        brickAndUkA2State: {
          name: 'brickAndUkA2State',
          navigateTo: this.goToCard,
          successor: 'brickAndUkBState',
          predecessor: 'correctBrickHandlingAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Abstand zwischen Ziegel und darunter liegendem Haken */
        brickAndUkBState: {
          name: 'brickAndUkBState',
          navigateTo: this.goToCard,
          successor: 'brickAndSubstructureAttachments',
          predecessor: 'brickAndUkA2State',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Gesamte UK Haken sichtbar */
        brickAndSubstructureAttachments: {
          name: 'brickAndSubstructureAttachments',
          navigateTo: this.goToCard,
          successor: 'cableAndPlugAttachedToRailState',
          predecessor: 'brickAndUkBState',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Befestigung Dachhakenfuß mit Erdung */
        roofHookWithEarthingAttachments: {
          name: 'roofHookWithEarthingAttachments',
          navigateTo: this.goToCard,
          successor: 'railsEarthed',
          predecessor: 'cableAndPlugAttachedToRailState',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Korrekte Kabeleinführung */
        correctCableInputAttachments: {
          name: 'correctCableInputAttachments',
          navigateTo: this.goToCard,
          successor: 'protectedUnderBrickCableRoutingAttachments',
          predecessor: 'railsEarthed',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel & UK - Geschützte Kabelführung unter Ziegel */
        protectedUnderBrickCableRoutingAttachments: {
          name: 'protectedUnderBrickCableRoutingAttachments',
          navigateTo: this.goToCard,
          successor: 'stringPlanAttachments',
          predecessor: 'correctCableInputAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Stringplan */
        stringPlanAttachments: {
          name: 'stringPlanAttachments',
          navigateTo: this.goToCard,
          successor: 'allPanelsVisibleAttachments',
          predecessor: 'brickAndUkOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Gesamtansicht alle Module sichtbar */
        allPanelsVisibleAttachments: {
          name: 'allPanelsVisibleAttachments',
          navigateTo: this.goToCard,
          successor: 'panelBottomViewAttachmentsOrClampAreaAttachments',
          predecessor: 'stringPlanAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Klemmbereiche */
        clampAreaAttachments: {
          name: 'clampAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'trayfDistanceAttachments',
          predecessor: 'allPanelsVisibleAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Abstand Traufe, First & Ortgänge eingehalten */
        trayfDistanceAttachments: {
          name: 'trayfDistanceAttachments',
          navigateTo: this.goToCard,
          successor: 'railOverlap',
          predecessor: 'clampAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Schienenüberstand nach Haken eingehalten max. 40 cm */
        railOverlap: {
          name: 'railOverlap',
          navigateTo: this.goToCard,
          successor: 'panelBottomViewAttachments',
          predecessor: 'trayfDistanceAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Foto unter Module */
        panelBottomViewAttachments: {
          name: 'panelBottomViewAttachments',
          navigateTo: this.goToCard,
          successor: 'solarModuleTypePlateAttachments',
          predecessor: 'railOverlap',
          opened: false,
          completed: this.completedState,
        },
        /** Typenschild des Solarmoduls */
        solarModuleTypePlateAttachments: {
          name: 'solarModuleTypePlateAttachments',
          navigateTo: this.goToCard,
          successor: 'solarPanelsOptional',
          predecessor: 'panelBottomViewAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Module - Optionale Fotos / Notizen */
        solarPanelsOptional: {
          name: 'solarPanelsOptional',
          navigateTo: this.goToCard,
          successor: 'cableManagementC2State',
          predecessor: 'solarModuleTypePlateAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Kabelverlegung - Kabel sauber und geschützt über Installationskanal oder Rohr verlegt. Falls Außenbereich entsprechend UV- beständig */
        cableManagementC2State: {
          name: 'cableManagementC2State',
          navigateTo: this.goToCard,
          successor: 'cableManagementAttachments',
          predecessor: 'solarPanelsOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Kabelverlegung -DC Außenverlegung: Bohrung von außen schräg nach oben. Durchbruch sauber abgedichtet (Keine Wassereindringung) */
        cableManagementAttachments: {
          name: 'cableManagementAttachments',
          navigateTo: this.goToCard,
          successor: 'cableAlongFacadeAttachments',
          predecessor: 'cableManagementC2State',
          opened: false,
          completed: this.completedState,
        },
        /** Kabelverlegung - Leitungsführung entlang Fassade */
        cableAlongFacadeAttachments: {
          name: 'cableAlongFacadeAttachments',
          navigateTo: this.goToCard,
          successor: 'cableManagementOptional',
          predecessor: 'cableManagementAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Kabelverlegung - Optionale Fotos / Notizen */
        cableManagementOptional: {
          name: 'cableManagementOptional',
          navigateTo: this.goToCard,
          successor: 'inverterSerialNumberAttachments',
          predecessor: 'cableAlongFacadeAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Wechselrichter - Typenschild Wechselrichter Seriennummer lesbar */
        inverterSerialNumberAttachments: {
          name: 'inverterSerialNumberAttachments',
          navigateTo: this.goToCard,
          successor: 'mateboxSerialNumberAttachments',
          predecessor: 'cableManagementOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Wechselrichter - Typenschild Matebox Seriennummer lesbar */
        mateboxSerialNumberAttachments: {
          name: 'mateboxSerialNumberAttachments',
          navigateTo: this.goToCard,
          successor: 'storageSerialNumberAttachments',
          predecessor: 'inverterSerialNumberAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Wechselrichter - Typenschild Speicher Seriennummer lesbar */
        storageSerialNumberAttachments: {
          name: 'storageSerialNumberAttachments',
          navigateTo: this.goToCard,
          successor: 'dongleSerialNumberAttachments',
          predecessor: 'mateboxSerialNumberAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Wechselrichter - Typenschild Dongle Seriennummer lesbar */
        dongleSerialNumberAttachments: {
          name: 'dongleSerialNumberAttachments',
          navigateTo: this.goToCard,
          successor: 'inverterAttachments',
          predecessor: 'storageSerialNumberAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Wechselrichter - Frontalansicht: Wechselrichter, Matebox & Speicher sichtbar */
        inverterAttachments: {
          name: 'inverterAttachments',
          navigateTo: this.goToCard,
          successor: 'openMateboxAttachments',
          predecessor: 'dongleSerialNumberAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Open Matebox Attachments */
        openMateboxAttachments: {
          name: 'openMateboxAttachments',
          navigateTo: this.goToCard,
          successor: 'cableBetweenMateboxAndElectricMeterAttachments',
          predecessor: 'inverterAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Cable Management Between Matebox And Meter Cabinet Attachments */
        cableBetweenMateboxAndElectricMeterAttachments: {
          name: 'cableBetweenMateboxAndElectricMeterAttachments',
          navigateTo: this.goToCard,
          successor: 'variousBreakthroughsWithPossibleSealingsAttachments',
          predecessor: 'openMateboxAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Various Breakthroughs With Possible Sealings Attachments */
        variousBreakthroughsWithPossibleSealingsAttachments: {
          name: 'variousBreakthroughsWithPossibleSealingsAttachments',
          navigateTo: this.goToCard,
          successor: 'inverterOptional',
          predecessor: 'cableBetweenMateboxAndElectricMeterAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Wechselrichter - Optionale Fotos / Notizen */
        inverterOptional: {
          name: 'inverterOptional',
          navigateTo: this.goToCard,
          successor: 'equipotentialBusBarAttachments',
          predecessor: 'variousBreakthroughsWithPossibleSealingsAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Potential - Komponenten angeschlossen und wie folgt beschriftet: Ableitung,ACÜSS,WR,SP,GAK,UK */
        equipotentialBusBarAttachments: {
          name: 'equipotentialBusBarAttachments',
          navigateTo: this.goToCard,
          successor: 'potentialGroundRodAttachments',
          predecessor: 'inverterOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Potential - Foto Tiefenerder/Banderder */
        potentialGroundRodAttachments: {
          name: 'potentialGroundRodAttachments',
          navigateTo: this.goToCard,
          successor: 'potentialOptional',
          predecessor: 'equipotentialBusBarAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Potential - Optionale Fotos / Notizen */
        potentialOptional: {
          name: 'potentialOptional',
          navigateTo: this.goToCard,
          successor: 'openHAKAttachments',
          predecessor: 'potentialGroundRodAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** HAK - Offener Hak */
        openHAKAttachments: {
          name: 'openHAKAttachments',
          navigateTo: this.goToCard,
          successor: 'closedHAKWithSealAttachments',
          predecessor: 'potentialOptional',
          opened: false,
          completed: this.completedState,
        },

        /** HAK - Verschlossener HAK mit Plombe */
        closedHAKWithSealAttachments: {
          name: 'closedHAKWithSealAttachments',
          navigateTo: this.goToCard,
          successor: 'electricMeterAttachments',
          predecessor: 'openHAKAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Zählerschrank & GAK - Zählerschrank inkl. Beschriftung der verbauten Komponenten */
        electricMeterAttachments: {
          name: 'electricMeterAttachments',
          navigateTo: this.goToCard,
          successor: 'electricMeterReadingAttachments',
          predecessor: 'closedHAKWithSealAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Zählerschrank & GAK  - Zählerstand lesbar */
        electricMeterReadingAttachments: {
          name: 'electricMeterReadingAttachments',
          navigateTo: this.goToCard,
          successor: 'gakAttachments',
          predecessor: 'electricMeterAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Zählerschrank & GAK - GAK komplett sichtbar */
        gakAttachments: {
          name: 'gakAttachments',
          navigateTo: this.goToCard,
          successor: 'openMeterCabinetPrePostAreaAttachments', // Erste der neuen Karten
          predecessor: 'electricMeterReadingAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Zählerschrank & GAK - Offener Zählerschrank mit Vorzählerbereich, Nachzählerbereich */
        openMeterCabinetPrePostAreaAttachments: {
          name: 'openMeterCabinetPrePostAreaAttachments',
          navigateTo: this.goToCard,
          successor: 'closedMeterCabinetInscribedComponentsAttachments',
          predecessor: 'gakAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Zählerschrank & GAK - Geschlossener Zählerschrank inkl. Beschriftung der verbauten Komponenten */
        closedMeterCabinetInscribedComponentsAttachments: {
          name: 'closedMeterCabinetInscribedComponentsAttachments',
          navigateTo: this.goToCard,
          successor: 'closedPreMeterAreaWithPlombsAttachments',
          predecessor: 'openMeterCabinetPrePostAreaAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Zählerschrank & GAK - Verschlossener Vorzählerbereich mit Plomben */
        closedPreMeterAreaWithPlombsAttachments: {
          name: 'closedPreMeterAreaWithPlombsAttachments',
          navigateTo: this.goToCard,
          successor: 'breakthroughsWithSealingsMeterCabinetAttachments',
          predecessor: 'closedMeterCabinetInscribedComponentsAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Zählerschrank & GAK - Diverse Durchbrüche mit eventuellen Abdichtungen */
        breakthroughsWithSealingsMeterCabinetAttachments: {
          name: 'breakthroughsWithSealingsMeterCabinetAttachments',
          navigateTo: this.goToCard,
          successor: 'electricMeterOptional',
          predecessor: 'closedPreMeterAreaWithPlombsAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Zählerschrank & GAK - Optionale Fotos / Notizen */
        electricMeterOptional: {
          name: 'electricMeterOptional',
          navigateTo: this.goToCard,
          successor: 'photoOfWallboxAttachments', // Erste der neuen Karten
          predecessor: 'breakthroughsWithSealingsMeterCabinetAttachments',
          opened: false,
          completed: this.completedState,
        },

        /**  Wallbox - Foto von der Wallbox */
        photoOfWallboxAttachments: {
          name: 'photoOfWallboxAttachments',
          navigateTo: this.goToCard,
          successor: 'accessCardsOfWallboxAttachments',
          predecessor: 'electricMeterOptional',
          opened: false,
          completed: this.completedState,
        },

        /**  Wallbox - Zugangskarten der Wallbox */
        accessCardsOfWallboxAttachments: {
          name: 'accessCardsOfWallboxAttachments',
          navigateTo: this.goToCard,
          successor: 'circuitBreakerAndSafetySwitchForWallboxAttachments',
          predecessor: 'photoOfWallboxAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Wallbox - FI und LS für die Wallbox (geschlossen Verteilung bzw. ZS) */
        circuitBreakerAndSafetySwitchForWallboxAttachments: {
          name: 'circuitBreakerAndSafetySwitchForWallboxAttachments',
          navigateTo: this.goToCard,
          successor: 'cablingBetweenWallboxAndDistributionAttachments',
          predecessor: 'accessCardsOfWallboxAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Wallbox -  Kabelverlegung (Zwischen Wallbox und ZS/Verteilung) */
        cablingBetweenWallboxAndDistributionAttachments: {
          name: 'cablingBetweenWallboxAndDistributionAttachments',
          navigateTo: this.goToCard,
          successor: 'screenshotDeviceFlow', // Verknüpfung zur nächsten Karte
          predecessor: 'circuitBreakerAndSafetySwitchForWallboxAttachments',
          opened: false,
          completed: this.completedState,
        },

        /** Monitoring & Konfiguration - Screenshot Gerätefluss */
        screenshotDeviceFlow: {
          name: 'screenshotDeviceFlow',
          navigateTo: this.goToCard,
          successor: 'screenshotModeOfOperation',
          predecessor: 'cablingBetweenWallboxAndDistributionAttachments', // Anpassung der Vorgängerkarte
          opened: false,
          completed: this.completedState,
        },

        /** Monitoring & Konfiguration - Screenshot Betriebsmodus: Eigenverbrauch */
        screenshotModeOfOperation: {
          name: 'screenshotModeOfOperation',
          navigateTo: this.goToCard,
          successor: 'netConnectionTerms',
          predecessor: 'screenshotDeviceFlow',
          opened: false,
          completed: this.completedState,
        },
        /** Monitoring & Konfiguration -Netzanschlussbedingungen */
        netConnectionTerms: {
          name: 'netConnectionTerms',
          navigateTo: this.goToCard,
          successor: 'monitoringOptional',
          predecessor: 'screenshotModeOfOperation',
          opened: false,
          completed: this.completedState,
        },
        /** Monitoring & Konfiguration - Optionale Fotos / Notizen */
        monitoringOptional: {
          name: 'monitoringOptional',
          navigateTo: this.goToCard,
          successor: 'measurementResultAttachments',
          predecessor: 'netConnectionTerms',
          opened: false,
          completed: this.completedState,
        },

        /** Messung -Fotos Messresultate Benning PV 1.1 / PV 2 Messung Auto Strings */
        measurementResultAttachments: {
          name: 'measurementResultAttachments',
          navigateTo: this.goToCard,
          successor: 'measurementProtocolInclPhotosAttachments',
          predecessor: 'monitoringOptional',
          opened: false,
          completed: this.completedState,
        },

        /** Messung -Fotos Messresultate Benning PV 1.1 / PV 2 Messung Auto Strings */
        measurementProtocolInclPhotosAttachments: {
          name: 'measurementProtocolInclPhotosAttachments',
          navigateTo: this.goToCard,
          successor: 'measurementsOptional',
          predecessor: 'measurementResultAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Messung - Optionale Fotos / Notizen */
        measurementsOptional: {
          name: 'measurementsOptional',
          navigateTo: this.goToCard,
          successor: 'warrantyQCellsAttachments',
          predecessor: 'measurementProtocolInclPhotosAttachments',
          opened: false,
          completed: this.completedState,
        },
        /** Dokumente - Garantieschein von QCells */
        warrantyQCellsAttachments: {
          name: 'warrantyQCellsAttachments',
          navigateTo: this.goToCard,
          successor: 'acceptanceProtocolCustomerSignatureAttachments',
          predecessor: 'measurementsOptional',
          opened: false,
          completed: this.completedState,
        },
        /** Dokumente - Abnahmeprotokoll mit Kundendaten und Unterschrift */
        acceptanceProtocolCustomerSignatureAttachments: {
          name: 'acceptanceProtocolCustomerSignatureAttachments',
          navigateTo: this.goToCard,
          successor: null,
          predecessor: 'warrantyQCellsAttachments',
          opened: false,
          completed: this.completedState,
        },
      },
      selectedProjectAttachmentType: null,
      /** track Upload states for clearing dropzone preview */
    };
  },
  computed: {
    ...mapGetters(['getReportTemplateImages']),
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'saveReportSignature',
      'updateProject',
      'fetchEnumValues',
    ]),

    /**
     * save the project and open the target card
     * @todo DRY
     */
    async goToCard(target, update = true) {
      // console.log('next()', target);

      let card = target;
      if (target === 'ballastRockWeightOrBrickCoverWidthAttachments') {
        card = this.isFlatRoof ? 'ballastRockWeight' : 'brickCoverWidthAttachments';
      }
      if (target === 'earthingSubstructureAttachmentsOrRoofHookWithEarthingAttachments') {
        card = this.isFlatRoof
          ? 'earthingSubstructureAttachments'
          : 'roofHookWithEarthingAttachments';
      }
      if (target === 'brickAndUkOptionalOrCorrectCableInputAttachments') {
        card = this.isFlatRoof ? 'brickAndUkOptional' : 'correctCableInputAttachments';
      }
      if (target === 'earthingSubstructureAttachmentsOrRoofHookWithEarthingAttachments') {
        card = this.isFlatRoof
          ? 'earthingSubstructureAttachments'
          : 'roofHookWithEarthingAttachments';
      }
      if (target === 'railsEarthedOrProtectedUnderBrickCableRoutingAttachments') {
        card = this.isFlatRoof ? 'railsEarthed' : 'protectedUnderBrickCableRoutingAttachments';
      }
      if (target === 'panelBottomViewAttachmentsOrClampAreaAttachments') {
        card = this.isFlatRoof ? 'panelBottomViewAttachments' : 'clampAreaAttachments';
      }
      if (target === 'cableManagementOptionalOrCableBetweenMateboxAndElectricMeterAttachments') {
        card = this.isFlatRoof
          ? 'cableManagementOptional'
          : 'cableBetweenMateboxAndElectricMeterAttachments';
      }
      if (
        target === 'cableAlongFacadeAttachmentsOrCableBetweenMateboxAndElectricMeterAttachments'
      ) {
        card = this.isFlatRoof
          ? 'cableAlongFacadeAttachments'
          : 'cableBetweenMateboxAndElectricMeterAttachments';
      }

      this.toggle(this.cards[card]);

      this.$nextTick(() => {
        this.scrollTo(card);
      });

      if (update) {
        await this.updateProject(this.project);
      }
    },

    toggle(item) {
      item.opened = !item.opened;
    },

    /**
     * Emit a scroll event, the AccordionCard Component will resolve it
     */
    scrollTo(name) {
      this.$eventHub.$emit('scroll-to-accordion', name);
    },

    /**
     * Map a completed state to each field
     * @todo DRY
     */
    completedState(type, newValue = null) {
      // console.log('completedState():', type, newValue);
      this.project.enpalReport.completed = this.project.enpalReport.completed
        ? this.project.enpalReport.completed
        : [];

      const existingField = this.project.enpalReport.completed.find((field) => field.type === type);

      if (!existingField) {
        const newField = { type: type, value: false };

        this.project.enpalReport.completed.push(newField);
        return newField.value;
      } else if (newValue !== null) {
        existingField.value = newValue;
      }

      return existingField.value;
    },

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },
  },
  async mounted() {
    await this.fetchEnumValues('BrickAndUkAssemblyType');
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.flex > div {
  flex: 1;
  min-width: 300px;
}

/** accordion */
.accordion button {
  text-align: left;
  padding: 0.8em 0.5em;
}
.accordion .card,
.accordion .card-header {
  border: 0;
  border-radius: 0em;
}
.accordion .btn.btn-block {
  border: 0;
  border-radius: 0em;
  font-weight: bold;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
