<template>
  <div>
    <AccordionCard :state="cards.zsLocationState">
      <template v-slot:header>
        {{ $t('zsLocationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsLocationState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.zsLocationState"
            :options="getEnumValues('HouseLocationState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.zsWithCoverAttachments">
      <template v-slot:header>
        {{ $t('zsWithCoverAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsWithCoverAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsWithCoverAttachments"
            attachmentType="serviceZsWithCoverAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isZsWithCoverAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsWithCoverAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isZsWithCoverAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.zsWithoutCoverAttachments">
      <template v-slot:header>
        {{ $t('zsWithoutCoverAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('zsWithoutCoverAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsWithoutCoverAttachments"
            attachmentType="serviceZsWithoutCoverAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isZsWithoutCoverAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsWithoutCoverAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isZsWithoutCoverAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsSerialNumberAttachments">
      <template v-slot:header>
        {{ $t('serviceZsSerialNumberAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsSerialNumberAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsSerialNumberAttachments"
            attachmentType="serviceZsSerialNumberAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceZsSerialNumberAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsSerialNumberAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceZsSerialNumberAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsPostCounterAreaAttachments">
      <template v-slot:header>
        {{ $t('serviceZsPostCounterAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsPostCounterAreaAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsPostCounterAreaAttachments"
            attachmentType="serviceZsPostCounterAreaAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceZsPostCounterAreaAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsPostCounterAreaAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceZsPostCounterAreaAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsPreCounterAreaAttachments">
      <template v-slot:header>
        {{ $t('serviceZsPreCounterAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsPreCounterAreaAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsPreCounterAreaAttachments"
            attachmentType="serviceZsPreCounterAreaAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceZsPreCounterAreaAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsPreCounterAreaAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceZsPreCounterAreaAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceZsDistributionAreaAttachments">
      <template v-slot:header>
        {{ $t('serviceZsDistributionAreaAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceZsDistributionAreaAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceZsDistributionAreaAttachments"
            attachmentType="serviceZsDistributionAreaAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceZsDistributionAreaAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceZsDistributionAreaAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isserviceZsDistributionAreaAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.additionalSubDistributionState">
      <template v-slot:header>
        {{ $t('additionalSubDistributionState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('additionalSubDistributionState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.additionalSubDistributionState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.serviceReport.additionalSubDistributionState === 'YES'">
        <div class="flex-column name">
          {{ $t('additionalSubDistributionState') }}
        </div>
        <div class="flex-column select">
          <p>{{ $t('upload_addtional_images') }}</p>
        </div>
      </div>
      <div class="flex-row" v-if="project.serviceReport.additionalSubDistributionState === 'YES'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceAdditionalSubDistributionAttachments"
            attachmentType="serviceAdditionalSubDistributionAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceAdditionalSubDistributionAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceAdditionalSubDistributionAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isserviceAdditionalSubDistributionAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    // Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isZsWithCoverAttachmentsUploadComplete: false,
      isZsWithoutCoverAttachmentsUploadComplete: false,
      isZsOptionalAttachmentsUploadComplete: false,
      isserviceZsSerialNumberAttachmentsUploadComplete: false,
      isserviceZsPostCounterAreaAttachmentsUploadComplete: false,
      isserviceZsPreCounterAreaAttachmentsUploadComplete: false,
      isserviceZsDistributionAreaAttachmentsUploadComplete: false,
      isserviceAdditionalSubDistributionAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchEnumValues']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    await this.fetchEnumValues('HouseLocationState');
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isZsWithCoverAttachmentsUploadComplete = true;
          this.isZsWithoutCoverAttachmentsUploadComplete = true;
          this.isZsOptionalAttachmentsUploadComplete = true;
          this.isserviceZsPostCounterAreaAttachmentsUploadComplete = true;
          this.isserviceZsPreCounterAreaAttachmentsUploadComplete = true;
          this.isserviceZsDistributionAreaAttachmentsUploadComplete = true;
          this.isserviceAdditionalSubDistributionAttachmentsUploadComplete = true;
          this.isserviceZsSerialNumberAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
