<template>
  <div class="project-registration" v-if="project.powerCompany">
    <b-row>
      <b-col lg="5" md="5" sm="12">
        <legend>
          <strong>Anmeldung starten bei {{ project.powerCompany.company }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('portalRegistrationType')"
          label-for="portalRegistrationType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('portalRegistrationType')"
            v-model="portalRegistrationType"
            :options="portalRegistrationTypes"
            :allow-empty="false"
            :show-labels="false"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          label-for="button"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <ConfirmButton
            class="mt-2"
            colorVariant="bg"
            :confirmCallback="startRegistration"
            :disabled="buildState == 'IN_PROGRESS'"
          >
            <template v-slot:header> {{ $t('start_registration') }}? </template>
            <template v-slot:buttonText>
              {{ $t('start_registration') }}
            </template>
            <template v-slot:confirmButtonText>
              {{ $t('start_registration') }}
            </template>
          </ConfirmButton>
        </b-form-group>
      </b-col>
      <b-col lg="7" md="7" sm="12">
        <legend>
          <strong>Anmeldungen</strong>
          <i
            class="fa fa-refresh ml-2 registration-refresh"
            :class="{ disabled: buildState == 'IN_PROGRESS' }"
            @click="onStartRegistrationPolling()"
          />
          <Spinner class="state-spinner" :size="15" v-if="buildState === 'IN_PROGRESS'" />
        </legend>

        <DataTable
          class="p-datatable-sm registrations-table"
          :value="project.registrations"
          :loading="loading"
          dataKey="id"
          :rowHover="true"
        >
          <template #empty>
            {{ $t('no_data_found') }}
          </template>
          <template #loading>
            {{ $t('loading') }}
          </template>

          <Column
            field="number"
            header="#"
            sortable
            :headerStyle="{ width: '60px' }"
            :bodyStyle="'text-align: center;width: 60px'"
          >
            <template #body="slotProps">
              {{ slotProps.data.number.toString().padStart(4, '0') }}
            </template>
          </Column>
          <Column field="referenceId" :header="$t('referenceId')" sortable>
            <template #body="slotProps">
              <div
                v-if="slotProps.data.state !== 'IN_PROGRESS' && slotProps.data.state !== 'DELETING'"
              >
                <a
                  target="_blank"
                  :href="
                    'https://netzportal.bayernwerk.de/node/' + slotProps.data.referenceId + '/edit'
                  "
                >
                  <i class="fa fa-link mr-1"> </i>{{ $t(slotProps.data.referenceId) }}
                </a>
              </div>
              <div v-else>{{ $t(slotProps.data.referenceId) }}</div>
            </template>
          </Column>
          <Column field="powerCompany.company" :header="$t('powerCompany')" sortable> </Column>
          <Column field="type" :header="$t('type')" sortable>
            <template #body="slotProps">
              {{ $t(slotProps.data.type) }}
            </template>
          </Column>
          <Column field="state" :header="$t('state')" sortable>
            <template #body="slotProps">
              <b-badge :variant="getRegistrationStateColor(slotProps.data.state)">
                {{ $t(slotProps.data.state) }}
              </b-badge>

              <Spinner
                class="state-spinner"
                :size="15"
                v-if="slotProps.data.state === 'IN_PROGRESS' || slotProps.data.state === 'DELETING'"
              />
            </template>
          </Column>
          <Column field="createdAt" :header="$t('createdAt')" sortable>
            <template #body="slotProps">
              {{ $datetime(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column :headerStyle="{ width: '100px' }" :bodyStyle="'text-align: center;width: 100px'">
            <template #body="slotProps">
              <ConfirmButton
                :confirmCallback="onTrashRegistration"
                :callbackId="slotProps.data.id"
                colorVariant="danger"
                :disabled="buildState === 'IN_PROGRESS'"
              >
                <template v-slot:header>
                  {{ $t('confirm_delete') }}
                </template>
                <template v-slot:buttonText>
                  {{ $t('delete') }}
                </template>
                <template v-slot:confirmButtonText>
                  {{ $t('delete') }}
                </template>
              </ConfirmButton>
            </template>
          </Column>
        </DataTable>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmButton from '@/components/ConfirmButton.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Spinner from '@/components/Spinner.vue';
import { getRegistrationStateColor } from '@/helpers/colors';
import Multiselect from 'vue-multiselect';

export default {
  components: { ConfirmButton, DataTable, Column, Spinner, Multiselect },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      portalRegistrationTypes: [
        'Anmeldung einer Erzeugungsanlage',
        'Anmeldung eines Stromspeichers',
      ],
      portalRegistrationType: 'Anmeldung einer Erzeugungsanlage',
      loading: true,
      pollBuildState: null,
      buildState: 'NOT_STARTED',
      buildTimer: 0,
      timeoutInSeconds: 600,
      intervalInMilliseconds: 10000,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues']),
  },
  methods: {
    ...mapActions([
      'fetchEnumValues',
      'startPortalRegistration',
      'fetchPortalRegistrationState',
      'deleteRegistration',
    ]),
    getRegistrationStateColor,
    async startRegistration() {
      if (this.project) {
        await this.startPortalRegistration(this.project.number);
        this.onStartRegistrationPolling();
      }
    },

    async onTrashRegistration(registrationId) {
      await this.deleteRegistration({
        projectNumber: this.project.number,
        registrationId: registrationId,
      });
      this.onStartRegistrationPolling();
    },

    async onStartRegistrationPolling() {
      console.log('onStartRegistrationPolling()');
      this.buildState = 'IN_PROGRESS';

      /** poll the build state until finished */
      this.pollBuildState = setInterval(async () => {
        await this.fetchPortalRegistrationState(this.project.number);
        this.buildState = this.project.portalRegistrationState;
        console.log('pollBuildState', this.buildState, this.buildTimer);

        /** Stop polling when wait is finished */
        if (this.buildState === 'FINISHED') {
          clearInterval(this.pollBuildState);
          this.buildTimer = 0;
        }
        /* stop polling when timeout is reached */
        if ((this.buildTimer * this.intervalInMilliseconds) / 1000 > this.timeoutInSeconds) {
          this.buildTimer = 0;
          this.buildState = 'NOT_STARTED';
          clearInterval(this.pollBuildState);
        }
        this.buildTimer++;
      }, this.intervalInMilliseconds);
    },
  },

  async mounted() {},
  async created() {
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.state-spinner.spinner {
  position: relative;
  left: 0.4rem;
  top: 2px;
}
.registration-refresh {
  cursor: pointer;
  color: $gray-700;
}
.registration-refresh.disabled {
  cursor: not-allowed;
  color: $gray-400;
}
.registrations-table {
  max-width: 1400px;
}
</style>
