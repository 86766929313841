<template>
  <div>
    <AccordionCard :state="cards.brickAndUkAssemblyType">
      <template v-slot:header>
        {{ $t('brickAndUkAssemblyType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkAssemblyType') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.brickAndUkAssemblyType"
            :options="getEnumValues('BrickAndUkAssemblyType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.ballastRockWeight">
      <template v-slot:header>
        {{ $t('ballastRockWeight') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('ballastRockWeight') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.enpalReport.ballastRockWeight" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.ballastAttachments">
      <template v-slot:header>
        {{ $t('ballastAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('ballastAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.ballastAttachments"
            attachmentType="ballastAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isballastAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="ballastAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isballastAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.railToRoofDistanceAttachments">
      <template v-slot:header>
        {{ $t('railToRoofDistanceAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('railToRoofDistanceAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.railToRoofDistanceAttachments"
            attachmentType="railToRoofDistanceAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="israilToRoofDistanceAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="railToRoofDistanceAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="israilToRoofDistanceAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableAndPlugAttachedToRailState">
      <template v-slot:header>
        {{ $t('cableAndPlugAttachedToRailState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableAndPlugAttachedToRailState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.cableAndPlugAttachedToRailState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.earthingSubstructureAttachments">
      <template v-slot:header>
        {{ $t('earthingSubstructureAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('earthingSubstructureAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.earthingSubstructureAttachments"
            attachmentType="earthingSubstructureAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isearthingSubstructureAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="earthingSubstructureAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isearthingSubstructureAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.railsEarthed">
      <template v-slot:header>
        {{ $t('railsEarthed') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('railsEarthed') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.enpalReport.railsEarthed"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.brickAndUkOptional"
      :project="project"
      notesType="brickAndUkOptionalNotes"
      :attachments="project.brickAndSubstructureOptionalAttachments"
      attachmentType="brickAndSubstructureOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    AccordionCardOptionalInput,
    InputText,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isballastAttachmentsUploadComplete: false,
      israilToRoofDistanceAttachmentsUploadComplete: false,
      isearthingSubstructureAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isballastAttachmentsUploadComplete = true;
          this.israilToRoofDistanceAttachmentsUploadComplete = true;
          this.isearthingSubstructureAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
