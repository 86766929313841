<template>
  <div>
    <AccordionCard :state="cards.installationLocationWithInverterAndMateboxState">
      <template v-slot:header>
        {{ $t('installationLocationWithInverterAndMateboxState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('installationLocationWithInverterAndMateboxState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.installationLocationWithInverterAndMateboxState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div
        class="flex-row"
        v-if="project.serviceReport.installationLocationWithInverterAndMateboxState === 'YES'"
      >
        <div class="flex-column name">
          {{ $t('image') }}
        </div>
        <div class="flex-column select">
          <p>
            {{ $t('upload_addtional_images') }}
          </p>
        </div>
      </div>
      <div
        class="flex-row"
        v-if="project.serviceReport.installationLocationWithInverterAndMateboxState === 'YES'"
      >
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceInstallationLocationWithInverterAndMateboxAttachments"
            attachmentType="serviceInstallationLocationWithInverterAndMateboxAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceInstallationLocationWithInverterAndMateboxAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceInstallationLocationWithInverterAndMateboxAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isserviceInstallationLocationWithInverterAndMateboxAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceSecondaryInstallationLocationAttachments">
      <template v-slot:header>
        {{ $t('serviceSecondaryInstallationLocationAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceSecondaryInstallationLocationAttachments') }}
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceSecondaryInstallationLocationAttachments"
            attachmentType="serviceSecondaryInstallationLocationAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceSecondaryInstallationLocationAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceSecondaryInstallationLocationAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isserviceSecondaryInstallationLocationAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cablePathDistance">
      <template v-slot:header> {{ $t('cablePathDistance') }}</template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cablePathDistance') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.serviceReport.cablePathDistance" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCablePath360Attachments">
      <template v-slot:header>
        {{ $t('serviceCablePath360Attachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceCablePath360Attachments') }}
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCablePath360Attachments"
            attachmentType="serviceCablePath360Attachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceCablePath360AttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCablePath360Attachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceCablePath360AttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.cableSelection">
      <template v-slot:header> {{ $t('cableSelection') }}</template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableSelection') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.serviceReport.cableSelection" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceOpenMatebox">
      <template v-slot:header>
        {{ $t('serviceOpenMatebox') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceOpenMatebox') }}
        </div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceOpenMatebox"
            attachmentType="serviceOpenMatebox"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceOpenMateboxUploadComplete"
            :parentId="project.id"
            parentType="serviceOpenMatebox"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceOpenMateboxUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import InputText from 'primevue/inputtext';

export default {
  components: {
    AccordionCard,
    SelectButton,
    AttachmentGallery,
    DropzoneUpload,
    InputText,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isserviceInstallationLocationWithInverterAndMateboxAttachmentsUploadComplete: false,
      isserviceSecondaryInstallationLocationAttachmentsUploadComplete: false,
      isserviceCablePath360AttachmentsUploadComplete: false,
      isserviceOpenMateboxUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchEnumValues']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isserviceInstallationLocationWithInverterAndMateboxAttachmentsUploadComplete = true;
          this.isserviceSecondaryInstallationLocationAttachmentsUploadComplete = true;
          this.isserviceCablePath360AttachmentsUploadComplete = true;
          this.isserviceOpenMateboxUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
