<template>
  <b-card no-body class="accordion-card">
    <div class="scroll-anchor" :ref="state.name"></div>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle:[state.name] variant="bg">
        <span class="when-open"><i class="la la-angle-down"></i></span>
        <span class="when-closed"><i class="la la-angle-right"></i></span>
        <slot name="header"></slot>
        <i v-if="completed" class="fa fa-lg fa-check text-success ml-2"></i>
        <i v-if="!completed" class="fa fa-lg fa-exclamation text-warning ml-2"></i>
      </b-button>
    </b-card-header>
    <b-collapse
      v-model="state.opened"
      :id="state.name"
      accordion="measurement-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <slot></slot>
        </b-card-text>
        <hr />
        <b-button v-if="hasPrevButton && !isGuest" @click="onPrev()" variant="bg">
          {{ $t('previous') }}
        </b-button>

        <b-button
          v-if="hasNextButton && !isGuest"
          @click="onNext()"
          variant="bg"
          class="pull-right"
        >
          {{ $t('next') }}
        </b-button>

        <ToggleButton
          v-model="completed"
          onLabel="Abgeschlossen"
          offLabel="Offen"
          onIcon="fa fa-check"
          offIcon="fa fa-exclamation"
          class="pull-right mr-2"
        />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ToggleButton from 'primevue/togglebutton';

export default {
  name: 'AccordionCard',
  components: {
    ToggleButton,
  },
  props: {
    state: { type: Object },
  },
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isGuest', 'getEnumValues']),

    hasPrevButton() {
      return this.state.predecessor ? true : false;
    },
    hasNextButton() {
      return this.state.successor ? true : false;
    },
    completed: {
      get() {
        return this.state.completed(this.state.name);
      },
      set(value) {
        this.state.completed(this.state.name, value);
      },
    },
  },

  methods: {
    async onNext() {
      // console.log('onNext()', this.state, this.state.successor);
      // await this.state.next(this.state.successor);
      await this.state.navigateTo(this.state.successor);
    },
    async onPrev() {
      // console.log('onPrev()', this.state);
      // await this.state.prev(this.state.predecessor);
      await this.state.navigateTo(this.state.predecessor);
    },

    scrollTo() {
      // console.log('scrollTo():', this.$refs[this.state.name]);
      if (this.$refs[this.state.name]) {
        this.$refs[this.state.name].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
  },
  async created() {
    this.$eventHub.$on('scroll-to-accordion', (name) => {
      // console.log('scroll-to-accordion received by', this.state.name);
      if (this.state.name === name) {
        // console.log('scroll-to-accordion processed by', this.state.name, name);
        this.scrollTo();
      }
    });
  },
  async mounted() {
    // console.log(this.state.name, this.$refs[this.state.name]);
  },
};
</script>

<style lang="scss" scoped>
.accordion-card {
  position: relative;
}

.scroll-anchor {
  position: absolute;
  border: 0px solid rebeccapurple;
  // background: red;
  // color: white;
  // height: 10px;
  // width: 10px;
  top: -10px;
}

/** accordion */
.accordion button {
  text-align: left;
  padding: 0.7em 0.5em;
}
.accordion .card,
.accordion .card-header {
  border: 0;
  border-radius: 0em;
}
.accordion .btn.btn-block {
  border: 0;
  border-radius: 0em;
  font-weight: normal;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

::v-deep .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #fff;
}

::v-deep .p-togglebutton.p-button .p-button-icon-left {
  color: #fff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: $green;
  border-color: #ced4da;
  color: #fff;
}

.p-togglebutton.p-button {
  background: $orange;
  border: 1px solid #fff;
  color: #fff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.7em 0.5em;
}

.p-togglebutton.p-button.p-highlight {
  background: $green;
  border-color: $green;
  color: #fff;
}
</style>
