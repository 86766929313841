<template>
  <div>
    <vue-dropzone
      v-if="!disabled"
      ref="myVueDropzone"
      id="customdropzone"
      :options="dropzoneOptions"
      v-on:vdropzone-file-added="onAddedFile"
      v-on:vdropzone-files-added="onAddedFiles"
      v-on:vdropzone-file-added-manually="onAddedFileManually"
      v-on:vdropzone-removed-file="onRemovedFile"
      v-on:vdropzone-complete="onComplete"
      :useCustomSlot="true"
      :include-styling="true"
    >
      <div>
        <i class="fa fa-2x fa-upload"></i>
        <div class="upload-text">
          Dateien hier ablegen um hochzuladen. Oder klicken für Dateiauswahl.
        </div>
      </div>
    </vue-dropzone>
    <div class="disabled" v-if="disabled">Bitte zuerst Typ auswählen!</div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'DropzoneUpload',
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    parentId: String,
    parentType: String,
    uploadCallback: Function,
    disabled: { type: Boolean, default: false },
    complete: { default: false },
    acceptedFileTypes: { type: String, default: '' },
    multiUpload: { type: Boolean, default: false },
  },
  data: function() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: () => {
          return '';
        },
        // previewTemplate: this.template(),
        thumbnailWidth: 120,
        thumbnailHeight: 80,
        clickable: true,
        acceptedFiles: this.acceptedFileTypes,
        //previewsContainer: "#previews"
      },
    };
  },
  methods: {
    deleteFile: () => {},

    onAddedFile: function(file) {
      if (this.multiUpload) {
        return;
      }
      if (file.manuallyAdded) {
        return;
      }
      // console.log('onAddedFile', file);
      this.uploadCallback({
        file,
        parentId: this.parentId,
        parentType: this.parentType,
      });
    },
    onAddedFiles: function(files) {
      if (!this.multiUpload) {
        return;
      }
      // console.log('onAddedFiles', files);
      this.uploadCallback({
        files,
        parentId: this.parentId,
        parentType: this.parentType,
      });
    },
    onAddedFileManually: function(file) {
      // console.log('addedFileManually')
    },
    onComplete: function(response) {
      // console.log('onComplete')
      // this.$refs.myVueDropzone.removeAllFiles()
    },
    onRemovedFile: function(file) {
      // console.log(file)
      // if (file.status === 'canceled') return;
      // if (!file.id) return;
      //
      // this.$emit('dropzone-upload:removed-file', file);
    },
    template: function() {
      return `
                    <div class="container dz-preview dz-file-preview">
                      <div class="row">
                        <div class="col-sm dz-image">
                            <img data-dz-thumbnail />
                        </div>
                        <div class="col-sm dz-filename">
                          <span data-dz-name></span>
                        </div>
                        <div class="col-sm size">
                          <span data-dz-size></span>
                        </div>
                        <div class="col-sm">
                            <button data-dz-remove type="button" class="btn-danger btn mr-xs">Löschen</button>
                        </div>
                      </div>
                    </div>

                    <div class="dz-error-message"><span data-dz-errormessage></span></div>
                    <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                    <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>
        `;
    },
  },
  async mounted() {
    // this.files.forEach(file => this.$refs.myVueDropzone.manuallyAddFile({
    //     id: file.id,
    //     name: file.filename,
    //     size: file.size,
    //     type: file.mimetype
    // }, file.path))
  },
  watch: {
    complete: function(val) {
      // console.log('complete watcher', val);

      if (val && this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles();
        this.$emit('dropzone-upload:reset');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dropzone {
  padding: 15px 10px 0 10px;
  min-height: 120px;
}
.disabled {
  margin-top: 0.5em;
  color: #d62839;
}
@media (max-width: 768px) {
  .dropzone {
    padding: 0.5em;
    min-height: 50px;
  }
  .upload-text {
    display: none;
  }
}
</style>
