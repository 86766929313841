<template>
  <b-dropdown :disabled="disabled" variant="danger" class="delete-dropdown" no-caret right>
    <template v-slot:button-content>
      <slot name="buttonText">Löschen</slot>
    </template>
    <b-dropdown-text class="fw-semi-bold">
      Löschen bestätigen
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-text class="small">
      Kann nicht rückgängig gemacht werden! Auf die unten stehende Schaltfläche klicken um löschen
      zu bestätigen.
    </b-dropdown-text>
    <b-dropdown-group>
      <b-button :disabled="disabled" variant="danger" @click="confirm">Löschen</b-button>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ConfirmDeleteButton',
  props: {
    id: [String, Number],
    disabled: Boolean,
    confirmCallback: Function,
  },
  methods: {
    confirm() {
      if (this.id) {
        // the old way
        this.$emit('confirm-delete-button:confirmed', this.id);
      }

      if (this.confirmCallback) {
        // the new way
        this.confirmCallback();
      }
    },
  },
};
</script>

<style>
.delete-dropdown {
  margin-right: 5px;
}
.delete-dropdown .dropdown-menu {
  padding: 15px;
  width: 230px;
}
.delete-dropdown button {
  width: 100%;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
  padding: 0.5rem 1rem;
}
</style>
