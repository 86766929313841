<template>
  <div>
    <AccordionCard :state="cards.roofSidesShadowsState">
      <template v-slot:header>
        {{ $t('roofSidesShadowsState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofSidesShadowsState') }}
        </div>
        <div class="flex-column select">
          {{ $t('roofSidesShadowsState_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.roofSidesShadowsState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.roofSidesShadowsState === 'YES'">
        <div class="flex-column name">
          {{ $t('roofSidesShadowsAttachments') }}
        </div>

        <div class="flex-column select">
          <p>
            {{ $t('roofSidesShadowsAttachments_description') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[8].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row" v-if="project.measurement.pitchedRoof.roofSidesShadowsState === 'YES'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofSidesShadowsAttachments"
            attachmentType="roofSidesShadowsAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isRoofSidesShadowsAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="roofSidesShadowsAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isRoofSidesShadowsAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isRoofSidesShadowsAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isRoofSidesShadowsAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
