<template>
  <div class="tasks">
    <DataTable
      class="p-datatable-sm"
      :rowClass="rowClass"
      :rowHover="true"
      :value="tasks"
      :loading="isLoading"
      editMode="cell"
      dataKey="number"
      :lazy="true"
      filterDisplay="row"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="filterName"
      :totalRecords="getTaskCount"
      :paginator="true"
      :rows.sync="tableState.pagination.rowsPerPage"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left"></div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>

            <div class="button-right">
              <button class="btn btn-success mb-2 right" @click="onCreateTask">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('new') }}
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '60px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.number === '_new'">{{ $t(slotProps.data.number) }}</span>
          <span v-else>
            {{ slotProps.data.number.toString().padStart(5, '0') }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        v-if="!isProjectContext"
        field="project.number"
        filterField="projectNumber"
        sortable
        :header="$t('project') + ' #'"
        :styles="{ width: '7%' }"
        :headerStyle="{ width: '60px' }"
        headerClass="center"
        className="center"
      >
        <template #body="slotProps">
          <router-link
            v-if="slotProps.data.project && slotProps.data.project.number"
            target="_blank"
            :to="{
              name: 'ProjectEditPage',
              params: { projectNumber: slotProps.data.project.number },
            }"
            >{{ slotProps.data.project.number.toString().padStart(4, '0') }}
            <i class="fa fa-sm fa-external-link"></i>
          </router-link>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        v-if="!isProjectContext"
        field="project"
        :header="$t('customer')"
        :styles="{ width: '10%' }"
        filterField="projectCustomerSearch"
        sortable
      >
        <template #body="slotProps">
          <multiselect
            class="multiselect-project"
            :placeholder="$t('filter_by_lastname')"
            v-model="slotProps.data.project"
            :options="filteredProjects"
            track-by="number"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            :internal-search="false"
            :show-labels="true"
            :allow-empty="false"
            v-on:search-change="onProjectSearch"
            v-on:select="onSelectProject(slotProps)"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
            <template slot="option" slot-scope="{ option }">
              <div>{{ option.customer.lastname }}, {{ option.customer.firstname }},</div>
              <div>
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </div>
            </template>
          </multiselect>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="createdAt"
        :header="$t('createdAt')"
        sortable
        :headerStyle="{ width: '100px' }"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.createdAt">
            {{ $datetime(slotProps.data.createdAt) }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column field="description" :header="$t('task')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="inCharge"
        :header="$t('inCharge')"
        :styles="{ width: '10%' }"
        sortable
        filterField="inChargeSearch"
      >
        <template #body="slotProps">
          <multiselect
            class="multiselect-users"
            :placeholder="'Mitarbeiter ' + $t('select') + '/suchen'"
            v-model="slotProps.data.inCharge"
            :options="filteredUsers"
            track-by="number"
            :internal-search="false"
            :show-labels="false"
            :allow-empty="true"
            :deselectLabel="$t('deselectLabel')"
            selectLabel=""
            selectedLabel=""
            v-on:search-change="onUserSearch"
            v-on:select="onSelectUser(slotProps)"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="option" slot-scope="{ option }"
              >{{ option.firstname }} {{ option.lastname }}
            </template>
            <template slot="tag" slot-scope="{ option }">
              <div>{{ option.firstname }} {{ option.lastname }}</div>
            </template>
            <template slot="noResult">
              <div>{{ $t('no_results') }}</div>
            </template>
          </multiselect>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="taskState"
        :header="$t('taskState')"
        :showFilterMenu="false"
        :styles="{ width: '7%' }"
        sortable
      >
        <template #body="slotProps">
          <b-badge :variant="getTaskStateColor(slotProps.data.taskState)">
            {{ $t(slotProps.data.taskState) }}
          </b-badge>
        </template>

        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('TaskState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getTaskStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getTaskStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>

        <template #filter>
          <MultiSelect
            :value="tableState.customFilters.taskStates"
            :options="taskStates"
            @input="onTaskStateFilter"
            optionLabel="label"
            :placeholder="$t('taskState')"
            display="chip"
            :style="{ 'max-width': '100px' }"
          />
        </template>
      </Column>

      <Column field="notes" :header="$t('notes')" sortable>
        <template #editor="slotProps">
          <InputText v-model="slotProps.data[slotProps.column.field]" />
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column :styles="{ 'min-width': '50px' }" v-if="isAdmin">
        <template #body="slotProps">
          <ConfirmButton
            colorVariant="white"
            :callbackId="slotProps.data.number"
            :confirmCallback="onDeleteTask"
            class="btn-icon"
          >
            <template v-slot:buttonText>
              <i class="link-icon fa fa-lg fa-times mr-2"></i>
            </template>
            <template v-slot:header> {{ $t('delete_task') }}? </template>
            <template v-slot:confirmButtonText>
              {{ $t('delete_task') }}
            </template>
          </ConfirmButton>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Multiselect from 'vue-multiselect';
import MultiSelect from 'primevue/multiselect';
import { getTaskStateColor } from '@/helpers/colors';
import { FilterMatchMode } from 'primevue/api';
import ConfirmButton from '@/components/ConfirmButton';

export default {
  name: 'TaskTable',
  components: {
    DataTable,
    Column,
    InputText,
    Calendar,
    Dropdown,
    Multiselect,
    MultiSelect,
    ConfirmButton,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      /** filters for user/inCharge multiselect */
      filteredUsers: [],
      usersFilter: {
        pagination: {
          skip: 0,
          pageSize: 200,
        },
        sorting: {
          sortField: 'lastname',
          sortOrder: 1,
        },
        filterName: 'tasks-user-filters',
        filters: {
          lastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          firstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      /** filters for project multiselect */
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'tasks-project-filters',
        filters: {
          number: { value: null, matchMode: FilterMatchMode.EQUALS },
          customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
          customerFirstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
      },

      /** persistent table filters  */
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        projectCustomerSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inChargeSearch: { value: null, matchMode: FilterMatchMode.CONTAINS },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      defaultCustomFilters: {
        taskStates: [],
      },

      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 20,
        },
        // filterName: 'tasks-table-filters',
        // customFilterName: 'tasks-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'isLoading',
      'getTasks',
      'getEnumValues',
      'getProjects',
      'getUsers',
      'getTaskCount',
      'getUserCount',
    ]),

    /**
     * If a project was provided via props we are in single project context.
     * Only tasks for the given project are shown and the columns for project search and selection are hidden
     */
    isProjectContext() {
      return this.project ? true : false;
    },

    tasks() {
      return this.getTasks;
    },
    taskStates() {
      const taskStates = this.getEnumValues('TaskState').map((taskState) => {
        return { value: taskState, label: this.$t(taskState) };
      });
      return taskStates;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.rowsPerPage;
    },

    filterName() {
      return this.isProjectContext
        ? 'project-' + this.project.number + '-tasks-table-filters'
        : 'tasks-table-filters';
    },

    customFilterName() {
      return this.isProjectContext
        ? 'project-' + this.project.number + '-tasks-custom-table-filters'
        : 'tasks-custom-table-filters';
    },
  },

  methods: {
    ...mapActions([
      'fetchEnumValues',
      'fetchTasks',
      'createTask',
      'duplicateTask',
      'updateTask',
      'initTask',
      'fetchProjects',
      'fetchProjectsPaginated',
      'fetchProjectsPaginatedByClient',
      'deleteTask',
      'sendTaskMail',
      'fetchUsersPaginated',
    ]),

    /**
     * Load remote table data
     */
    async loadTaskData() {
      /** Force to filter by project context */
      if (this.isProjectContext) {
        this.tableState.filters.projectNumber.value = this.project.number.toString();
      }

      await this.fetchTasks({
        page: this.pageOffset,
        pageSize: this.tableState.pagination.rowsPerPage,
        sortField: this.tableState.sortField,
        sortOrder: this.tableState.sortOrder,
        filters: { ...this.tableState.filters, ...this.tableState.customFilters },
      });

      this.filteredProjects = this.getProjects;
    },

    onCreateTask() {
      this.initTask();
    },

    onDeleteTask(taskNumber) {
      // console.log('onDeleteTask():', taskNumber);
      this.deleteTask(taskNumber);
    },

    /**
     * send task data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;
      data[field] = newValue;
      this.save(data);
    },

    async save(task) {
      // console.log(task);

      /** Force project assignment */
      if (this.isProjectContext) {
        task.project = this.project;
      }

      if (task.number === '_new') {
        await this.createTask(task);
      } else {
        await this.updateTask(task);
      }
    },

    /**
     * Reset filter settings
     */
    async onRemoveFilters(event) {
      this.tableState.filters = this.defaultFilters;
      this.tableState.customFilters = this.defaultCustomFilters;

      localStorage.removeItem(this.filterName);
      localStorage.removeItem(this.customFilterName);
      await this.loadTaskData();
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      // console.log('onPage', event);
      this.loadTaskData();
    },
    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTaskData();
    },
    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTaskData();
    },

    /**
     * restore filters from local storage
     * @param {*} event
     */
    async onStateRestore(event) {
      // console.log('onStateRestore', event);

      this.setupFilters();
      if (this.tableState) {
        this.tableState.sortField = event.sortField ? event.sortField : this.defaultSortField;
        this.tableState.sortOrder = event.sortOrder ? event.sortOrder : this.defaultSortOrder;
        this.tableState.pagination.page = 0;
        this.tableState.pagination.rowsPerPage = event.rows;
        this.tableState.pagination.rowsPerPage = event.rows;
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
      // console.log('onStateRestore', this.tableState.customFilters);
    },

    setupFilters() {
      // console.log('setupFilters', this.tableState);

      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.pagination.page = 0;
      this.tableState.pagination.rowsPerPage = 50;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sortField = this.tableState.sortField
        ? this.tableState.sortField
        : this.defaultSortField;

      this.tableState.sortOrder = this.tableState.sortOrder
        ? this.tableState.sortOrder
        : this.defaultSortOrder;
    },
    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    /**
     * Fetch project data from graphql
     */
    async loadProjectData() {
      if (this.isClient) {
        await this.fetchProjectsPaginatedByClient({
          clientId: this.getCurrentUser.client.id,
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      } else if (this.isAdmin) {
        await this.fetchProjectsPaginated({
          page: this.projectsFilter.pagination.page,
          pageSize: this.projectsFilter.pagination.rowsPerPage,
          sortField: this.projectsFilter.sortField,
          sortOrder: this.projectsFilter.sortOrder,
          filters: { ...this.projectsFilter.filters },
        });
      }
      this.filteredProjects = this.getProjects;
    },

    /**
     * Fetch user data from graphql
     */
    async loadUserData() {
      await this.fetchUsersPaginated({
        pagination: this.usersFilter.pagination,
        sorting: this.usersFilter.sorting,
        filters: { ...this.usersFilter.filters },
      });
      this.filteredUsers = this.getUsers;
      // console.log('loadUserData()', this.getUserCount, this.filteredUsers);
    },

    /**
     * search in dropdown for a user to assign to the appointment
     */
    async onUserSearch(rawQuery) {
      // console.log('onUserSearch()', rawQuery);
      const query = rawQuery.toLowerCase();
      this.usersFilter.filters.lastname.value = query;
      await this.loadUserData();
    },

    /**
     * search in dropdown for a project to assign to the appointment
     */
    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.projectsFilter.filters.customerLastname.value = query;
      await this.loadProjectData();
    },

    /**
     * When an user is selected from multiselect save it to the task in the corresponding row
     */
    async onSelectUser(row) {
      const { data } = row;
      // console.log('onSelectUser()', data);
      this.save(data);
    },

    /**
     * When a project is selected from multiselect save it to the task in the corresponding row
     */
    async onSelectProject(row) {
      const { data } = row;
      this.save(data);
    },

    async onTaskStateFilter(value) {
      this.tableState.customFilters.taskStates = this.taskStates.filter((taskState) =>
        value.includes(taskState)
      );
      localStorage.setItem(this.customFilterName, JSON.stringify(this.tableState.customFilters));
      await this.loadTaskData();
    },

    getTaskStateColor,
  },
  async created() {
    this.setupFilters();

    // console.log('created()', this.tableState);
  },
  async mounted() {
    await this.fetchEnumValues('TaskState');
    await this.loadUserData();
    await this.loadTaskData();
    if (!this.isProjectContext) {
      await this.loadProjectData();
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-users {
  min-width: 230px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-users .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
</style>
