<template>
  <b-card class="mb-4 audit-card">
    <b-card-title :class="{ danger: isOutdated }">
      {{ $t('editing_state') }}
      <i v-if="isOutdated" class="fa fa-exclamation ml-1"></i>
    </b-card-title>
    <b-card-text>
      <div>
        <span v-if="isOutdated">
          {{ $t('project_outdated') }} <br />
          {{ $t('please_reload') }}:
          <i @click="refreshPage" class="fa fa-lg fa-refresh ml-1 reload"></i>
        </span>
        <b-badge v-else :variant="getYesNoStateColor('YES')">
          OK
        </b-badge>
      </div>
      <div v-if="projectEditingState">
        <h5 class="mt-3">{{ $t('current_users') }}</h5>
        <ul>
          <li v-for="audit in projectEditingState.recentProjectUsers" :key="audit._id">
            {{ $time(audit.createdAt) }}: {{ audit.user.firstname }} {{ audit.user.lastname }}
          </li>
        </ul>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  getYesNoStateColor,
  getProjectStateColor,
  yesNoStateReversedColor,
} from '@/helpers/colors';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    project: { type: Object },
  },
  data() {
    return {
      projectEditingState: null,
      pollState: null,
      intervalInMilliseconds: 30000,
    };
  },
  computed: {
    isOutdated() {
      if (!this.projectEditingState) return false;

      return this.projectEditingState.projectIsOutdated;
    },
  },
  methods: {
    ...mapActions(['fetchProjectIsOutdated', 'fetchProjectEditingState']),

    refreshPage() {
      location.reload();
    },

    async pollIsOutdated() {
      this.pollState = setInterval(async () => {
        this.projectEditingState = await this.fetchProjectEditingState(this.project.number);
        // console.log('pollIsOutdated()', this.isOutdated, this.projectEditingState);
      }, this.intervalInMilliseconds);
    },

    /**
     * Stop polling when component is destroyed
     */
    stopPolling() {
      clearInterval(this.pollState);
    },

    getYesNoStateColor,
    getProjectStateColor,
    yesNoStateReversedColor,
  },
  async mounted() {
    if (this.project.number && this.project.number !== '_new') {
      this.projectEditingState = await this.fetchProjectEditingState(this.project.number);
      await this.pollIsOutdated();
    }
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>

<style scoped lang="scss">
.audit-card {
  max-width: 20rem;
}

.audit-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1em;
  row-gap: 0.5em;
}

ul {
  list-style: none;
  padding-left: 0;
}

.danger {
  color: $danger;
}

i.reload {
  color: $danger;
  cursor: pointer;
}
</style>
