<template>
  <div>
    <AccordionCard :state="cards.roofType">
      <template v-slot:header> 1. {{ $t('roof_type') }} </template>
      <template>
        <div class="flex-row">
          <div class="flex-column name">
            {{ $t('type') }}
          </div>
          <div class="flex-column select">
            <SelectButton
              v-model="project.measurement.roofType"
              :options="getEnumValues('RoofType')"
            >
              <template #option="props">
                {{ $t(props.option) }}
              </template>
            </SelectButton>
          </div>
        </div>
      </template>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {};
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {},
};
</script>
