<template>
  <div>
    <AccordionCard :state="cards.screenshotDeviceFlow">
      <template v-slot:header>
        {{ $t('screenshotDeviceFlow') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('screenshotDeviceFlow') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[46].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.screenshotDeviceFlow"
            attachmentType="screenshotDeviceFlow"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isscreenshotDeviceFlowUploadComplete"
            :parentId="project.id"
            parentType="screenshotDeviceFlow"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isscreenshotDeviceFlowUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.screenshotModeOfOperation">
      <template v-slot:header>
        {{ $t('screenshotModeOfOperation') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('screenshotModeOfOperation') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.screenshotModeOfOperation"
            attachmentType="screenshotModeOfOperation"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isscreenshotModeOfOperationUploadComplete"
            :parentId="project.id"
            parentType="screenshotModeOfOperation"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isscreenshotModeOfOperationUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.netConnectionTerms">
      <template v-slot:header>
        {{ $t('netConnectionTerms') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('netConnectionTerms') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.netConnectionTerms"
            attachmentType="netConnectionTerms"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isnetConnectionTermsUploadComplete"
            :parentId="project.id"
            parentType="netConnectionTerms"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isnetConnectionTermsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.monitoringOptional"
      :project="project"
      notesType="monitoringNotes"
      :attachments="project.monitoringOptionalAttachments"
      attachmentType="monitoringOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isscreenshotDeviceFlowUploadComplete: false,
      isscreenshotModeOfOperationUploadComplete: false,
      isnetConnectionTermsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isscreenshotDeviceFlowUploadComplete = true;
          this.isscreenshotModeOfOperationUploadComplete = true;
          this.isnetConnectionTermsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
