<template>
  <div class="project-items">
    <DataTable class="p-datatable-sm" :value="items" :loading="loading" :rowHover="true">
      <template #header>
        <div>
          <h4 class="page-title">{{ $t('project_items') }}</h4>
          <div class="table-header">
            <ConfirmDeleteButton :confirmCallback="onRemoveAllItemsFromProject">
              <template v-slot:buttonText>
                <i class="fa fa-trash mr-2"></i>{{ $t('remove_all_items') }}
              </template>
            </ConfirmDeleteButton>
            <ButtonWait
              v-if="items.length > 0"
              :startCallback="startOrderExportBuild"
              :pollCallback="fetchExportBuildState"
              class="pull-right"
            >
              <template v-slot:buttonText>
                {{ $t('generate_order_list') }}
              </template>
            </ButtonWait>
          </div>
        </div>
      </template>

      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column field="item.wholesaleNr" :header="$t('itemNumber')" sortable>
        <template #body="slotProps">{{ slotProps.data.item.wholesaleNr }}</template>
      </Column>

      <Column field="item.description" :header="$t('description')" sortable>
        <template #body="slotProps">
          <span v-html="slotProps.data.item.description"></span>
        </template>
      </Column>

      <Column
        field="count"
        :header="$t('count')"
        sortable
        :headerStyle="{ width: '90px', 'text-align': 'center' }"
        :bodyStyle="{ width: '90px', 'text-align': 'center' }"
      >
      </Column>

      <Column
        field="item.unit"
        :header="$t('unit')"
        sortable
        filterMatchMode="equals"
        :headerStyle="{ width: '90px' }"
      >
        <template #body="slotProps"> {{ $t(slotProps.data.item.unit) }} </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';
import ButtonWait from '@/components/ButtonWait.vue';

export default {
  name: 'ProjectItems',
  components: {
    DataTable,
    Column,
    ConfirmDeleteButton,
    ButtonWait,
  },
  props: {
    items: {
      type: Array,
    },
    project: { type: Object },
  },
  data() {
    return {
      loading: true,
      filters: {},
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient', 'isGuest']),
  },
  methods: {
    ...mapActions(['fetchEmployers', 'fetchUserAttachmentUrl']),
    ...mapActions('auth', ['buildUserOrderExport', 'refreshUser']),

    onRemoveAllItemsFromProject() {
      this.$emit('item-select:remove-all-items');
    },

    async startOrderExportBuild() {
      if (this.getCurrentUser) {
        const userId = this.getCurrentUser.id;
        const projectNumber = this.project?.number;

        await this.buildUserOrderExport({ userId, projectNumber });
      }
    },
    async fetchExportBuildState() {
      await this.refreshUser();

      if (this.getCurrentUser.orderExportBuildState === 'FINISHED') {
        this.downloadOrderExport('orderExportAttachment');
      }
      return this.getCurrentUser.orderExportBuildState;
    },
    async downloadOrderExport(attachmentType) {
      try {
        const url = await this.fetchUserAttachmentUrl({
          userId: this.getCurrentUser.id,
          attachmentType: attachmentType,
        });
        window.open(url);
      } catch (error) {
        console.error('Error fetching attachment URL:', error);
      }
    },
  },
  async created() {
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

h4.page-title {
  font-size: 15px;
}

.p-datatable-sm .p-datatable-header {
  background-color: #f8f9fa;
}

.p-datatable-sm .p-datatable-thead > tr > th {
  background-color: #f8f9fa;
}
</style>
