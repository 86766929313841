<template>
  <b-card no-body class="accordion-card">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle:[cardId] variant="bg">
        <span class="when-open"><i class="la la-angle-down"></i></span
        ><span class="when-closed"><i class="la la-angle-right"></i></span>
        <slot name="header"></slot>
      </b-button>
    </b-card-header>
    <b-collapse :id="cardId" accordion="some-accordion" role="tabpanel">
      <b-card-body>
        <b-card-text>
          <slot></slot>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: 'AccordionSimpleCard',
  components: {},
  props: {
    cardId: { type: String },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/** accordion */
.accordion button {
  text-align: left;
  padding: 0.4em 1em;
}
.accordion-card {
  position: relative;
}
.accordion-card header button {
  min-height: 42px;
}
.accordion .card,
.accordion .card-header {
  border: 0;
  border-radius: 0em;
  padding: 0;
}
.accordion .btn.btn-block {
  border: 0;
  border-radius: 0em;
  font-weight: normal;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
