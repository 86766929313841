<template>
  <div>
    <AccordionCard :state="cards.lightningProtectionState">
      <template v-slot:header>
        {{ $t('lightningProtectionState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('lightningProtectionState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.lightningProtectionState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div
        class="flex-row"
        v-if="project.measurement.pitchedRoof.lightningProtectionState === 'YES'"
      >
        <div class="flex-column name">{{ $t('integrateLightningProtectionState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.integrateLightningProtectionState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isOuterRoofOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isOuterRoofOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
