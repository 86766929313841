<template>
  <div>
    <AccordionCard :state="cards.flatRoofMeasurements">
      <template v-slot:header> {{ $t('flatRoofMeasurements') }} </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('buildingHeight') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.flatRoof.buildingHeight" />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofDimension') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.flatRoof.roofDimension" />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRoofPitchInDegrees') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.flatRoof.flatRoofPitchInDegrees" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRoofingMaterialType">
      <template v-slot:header>
        {{ $t('flatRoofingMaterialType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRoofingMaterialType') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.flatRoof.flatRoofingMaterialType"
            :options="getEnumValues('FlatRoofingMaterialType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.flatRoof.flatRoofingMaterialType === 'OTHER'">
        <div class="flex-column name">{{ $t('flatRoofingMaterialNotes') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.flatRoof.flatRoofingMaterialNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRoofSurfaceAttachments">
      <template v-slot:header>
        {{ $t('flatRoofSurfaceAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('flatRoofSurfaceAttachments') }}
        </div>
        <div class="flex-column select">
          <p>
            {{ $t('flatRoofSurfaceAttachments_description') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[7].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.flatRoofSurfaceAttachments"
            attachmentType="flatRoofSurfaceAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isFlatRoofSurfaceAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="flatRoofSurfaceAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isFlatRoofSurfaceAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.atticPresentState">
      <template v-slot:header>
        {{ $t('atticPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('atticPresentState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.flatRoof.atticPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.flatRoof.atticPresentState === 'YES'">
        <div class="flex-column name">{{ $t('atticPresentNotes') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.flatRoof.atticPresentNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <LightningProtection :cards="cards" :project="project" />
    <RoofSideShadow :cards="cards" :project="project" />
    <RoofSideSketch :cards="cards" :project="project" />

    <AccordionCard :state="cards.flatRoofOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.flatRoofOptionalAttachments"
            attachmentType="flatRoofOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isFlatRoofOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="flatRoofOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isFlatRoofOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.flatRoofNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.flatRoof.flatRoofNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import LightningProtection from './LightningProtection.vue';
import RoofSideShadow from './RoofSideShadow.vue';
import RoofSideSketch from './RoofSideSketch.vue';

export default {
  name: 'FlatRoof',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
    InputText,
    LightningProtection,
    RoofSideShadow,
    RoofSideSketch,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isFlatRoofSurfaceAttachmentsUploadComplete: false,
      isFlatRoofOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isFlatRoofSurfaceAttachmentsUploadComplete = true;
          this.isFlatRoofOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
