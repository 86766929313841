<template>
  <div>
    <AccordionCard :state="cards.scaffoldingPossibleState">
      <template v-slot:header>
        {{ $t('scaffoldingPossibleState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('scaffoldingPossibleState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.scaffoldingPossibleState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div
        class="flex-row"
        v-if="project.measurement.pitchedRoof.scaffoldingPossibleState === 'NO'"
      >
        <div class="flex-column name">{{ $t('why_not') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.scaffoldingPossibleNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'Scaffolding',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {};
  },
  computed: { ...mapGetters(['getEnumValues']) },
  methods: {},
  async mounted() {},
};
</script>
