<template>
  <div>
    <AccordionCard :state="cards.measurementResultAttachments">
      <template v-slot:header>
        {{ $t('measurementResultAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('measurementResultAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[34].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.measurementResultAttachments"
            attachmentType="measurementResultAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ismeasurementResultAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="measurementResultAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="ismeasurementResultAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <AccordionCard :state="cards.measurementProtocolInclPhotosAttachments">
      <template v-slot:header>
        {{ $t('measurementProtocolInclPhotosAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('measurementProtocolInclPhotosAttachments') }}</div>
        <div class="flex-column select"></div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.measurementProtocolInclPhotosAttachments"
            attachmentType="measurementProtocolInclPhotosAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="ismeasurementProtocolInclPhotosAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="measurementProtocolInclPhotosAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              ismeasurementProtocolInclPhotosAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.measurementsOptional"
      :project="project"
      notesType="measurementNotes"
      :attachments="project.measurementsOptionalAttachments"
      attachmentType="measurementsOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      ismeasurementResultAttachmentsUploadComplete: false,
      ismeasurementProtocolInclPhotosAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.ismeasurementResultAttachmentsUploadComplete = true;
          this.ismeasurementProtocolInclPhotosAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
