<template>
  <div class="technical-review">
    <b-row class="mb-4">
      <b-col lg="6" md="6" sm="12">
        <div v-if="project.technicalReviewDescription != ''">
          <legend>
            <strong>{{ $t('technicalReviewDescription') }} (alt)</strong>
          </legend>

          <div v-html="project.technicalReviewDescription"></div>
        </div>

        <legend>
          <strong>{{ $t('technicalReviewDescription') }}</strong>
        </legend>

        <div class="form-grid-container-left" v-if="project.technicalReview">
          <div class="label">{{ $t('dcInspectedState') }}</div>
          <div>
            <multiselect
              :placeholder="$t('dcInspectedState')"
              v-model="project.technicalReview.dcInspectedState"
              :options="yesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getYesNoStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </div>

          <div class="label">{{ $t('dcNotes') }}</div>
          <div>
            <ckeditor
              :editor="editor"
              v-model="project.technicalReview.dcNotes"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="label">{{ $t('acInspectedState') }}</div>
          <div>
            <multiselect
              :placeholder="$t('acInspectedState')"
              v-model="project.technicalReview.acInspectedState"
              :options="yesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getYesNoStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </div>

          <div class="label">{{ $t('acNotes') }}</div>
          <div>
            <ckeditor
              :editor="editor"
              v-model="project.technicalReview.acNotes"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </div>

        <!-- <legend>
          <strong>{{ $t('additionalOrders') }}</strong>
        </legend>

        <div class="form-grid-container-left" v-if="project.technicalReview">
          <div class="label">{{ $t('additionalOrders') }}</div>
          <div>
            <ckeditor
              :editor="editor"
              v-model="project.technicalReview.additionalOrders"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="label">{{ $t('ordered') }}</div>
          <div>
            <multiselect
              :placeholder="$t('additionalOrdersDispatchedState')"
              v-model="project.technicalReview.additionalOrdersDispatchedState"
              :options="yesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getYesNoStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </div>

          <div class="label">{{ $t('orderDate') }}</div>
          <div>
            <Calendar
              v-model="additionalOrdersDispatchedAt"
              selectionMode="single"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
            >
            </Calendar>
          </div>
        </div> -->
      </b-col>

      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('technicalReviewDescription') }}</strong>
        </legend>

        <div class="form-grid-container-left" v-if="project.technicalReview">
          <!-- <div class="label">{{ $t('preliminaryExaminationSuccessfulState') }}</div>
          <div>
            <multiselect
              :placeholder="$t('preliminaryExaminationSuccessfulState')"
              v-model="project.technicalReview.preliminaryExaminationSuccessfulState"
              :options="yesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getYesNoStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </div> -->

          <div class="label">{{ $t('installationTeamNotes') }}</div>
          <div>
            <ckeditor
              :editor="editor"
              v-model="project.technicalReview.installationTeamNotes"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="label">{{ $t('onSiteAppointmentProtocolUploadedToVlinkState') }}</div>
          <div>
            <multiselect
              :placeholder="$t('onSiteAppointmentProtocolUploadedToVlinkState')"
              v-model="project.technicalReview.onSiteAppointmentProtocolUploadedToVlinkState"
              :options="yesNo"
              :show-labels="false"
              :allow-empty="false"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getYesNoStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </div>

          <div class="label">{{ $t('preferredDeliveryInVlinkAt') }}</div>
          <div>
            <Calendar
              v-model="preferredDeliveryInVlinkAt"
              selectionMode="single"
              dateFormat="dd.mm.yy"
              :placeholder="$t('date')"
              :showWeek="true"
              :yearNavigator="true"
              :monthNavigator="true"
            >
            </Calendar>
          </div>

          <div class="label">{{ $t('appointmentNotes') }}</div>
          <div>
            <ckeditor
              :editor="editor"
              v-model="project.technicalReview.appointmentNotes"
              :config="editorConfig"
            ></ckeditor>
          </div>

          <div class="label">{{ $t('dcTimeExpenditure') }}</div>
          <div>
            <InputText type="text" v-model="project.technicalReview.dcTimeExpenditure" />
          </div>

          <div class="label">{{ $t('acTimeExpenditure') }}</div>
          <div>
            <InputText type="text" v-model="project.technicalReview.acTimeExpenditure" />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Multiselect from 'vue-multiselect';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor, yesNoStateReversedColor } from '@/helpers/colors';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';

export default {
  name: 'ItemSelect',
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
    Calendar,
    InputText,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      editor: InlineEditor,
      editorConfig: {},
      isAttachmentUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters([]),
    preferredDeliveryInVlinkAt: {
      get() {
        return this.$calendarFormat(this.project.technicalReview.preferredDeliveryInVlinkAt);
      },
      set(value) {
        this.project.technicalReview.preferredDeliveryInVlinkAt = value;
      },
    },
    additionalOrdersDispatchedAt: {
      get() {
        return this.$calendarFormat(this.project.technicalReview.additionalOrdersDispatchedAt);
      },
      set(value) {
        this.project.technicalReview.additionalOrdersDispatchedAt = value;
      },
    },
    yesNo,
  },
  methods: {
    ...mapActions([]),

    getYesNoStateColor,
    yesNoStateReversedColor,
  },
  async mounted() {},
};
</script>

<style scoped lang="scss">
.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left .label {
  text-align: right;
}
</style>
