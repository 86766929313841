<template>
  <div>
    <AccordionCard :state="cards.electricMeterAttachments">
      <template v-slot:header>
        {{ $t('electricMeterAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('electricMeterAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[52].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.electricMeterAttachments"
            attachmentType="electricMeterAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iselectricMeterAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="electricMeterAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iselectricMeterAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.electricMeterReadingAttachments">
      <template v-slot:header>
        {{ $t('electricMeterReadingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('electricMeterReadingAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[23].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.electricMeterReadingAttachments"
            attachmentType="electricMeterReadingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iselectricMeterReadingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="electricMeterReadingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iselectricMeterReadingAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.gakAttachments">
      <template v-slot:header>
        {{ $t('gakAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('gakAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[36].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.gakAttachments"
            attachmentType="gakAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isgakAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="gakAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isgakAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <!-- Offener Zählerschrank mit Vorzählerbereich, Nachzählerbereich -->
    <AccordionCard :state="cards.openMeterCabinetPrePostAreaAttachments">
      <template v-slot:header>
        {{ $t('openMeterCabinetPrePostAreaAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">{{ $t('openMeterCabinetPrePostAreaAttachments') }}</div>
        <div class="flex-column select">
          <div></div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.openMeterCabinetPrePostAreaAttachments"
            attachmentType="openMeterCabinetPrePostAreaAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isOpenMeterCabinetPrePostAreaAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="openMeterCabinetPrePostAreaAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isOpenMeterCabinetPrePostAreaAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <!-- Geschlossener Zählerschrank inkl. Beschriftung der verbauten Komponenten -->
    <AccordionCard :state="cards.closedMeterCabinetInscribedComponentsAttachments">
      <template v-slot:header>
        {{ $t('closedMeterCabinetInscribedComponentsAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('closedMeterCabinetInscribedComponentsAttachments') }}
        </div>
        <div class="flex-column select"></div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.closedMeterCabinetInscribedComponentsAttachments"
            attachmentType="closedMeterCabinetInscribedComponentsAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isClosedMeterCabinetInscribedComponentsAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="closedMeterCabinetInscribedComponentsAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isClosedMeterCabinetInscribedComponentsAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <!-- Verschlossener Vorzählerbereich mit Plomben -->
    <AccordionCard :state="cards.closedPreMeterAreaWithPlombsAttachments">
      <template v-slot:header>
        {{ $t('closedPreMeterAreaWithPlombsAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">{{ $t('closedPreMeterAreaWithPlombsAttachments') }}</div>
        <div class="flex-column select"></div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.closedPreMeterAreaWithPlombsAttachments"
            attachmentType="closedPreMeterAreaWithPlombsAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isClosedPreMeterAreaWithPlombsAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="closedPreMeterAreaWithPlombsAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isClosedPreMeterAreaWithPlombsAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
    <!-- Diverse Durchbrüche mit eventuellen Abdichtungen -->
    <AccordionCard :state="cards.breakthroughsWithSealingsMeterCabinetAttachments">
      <template v-slot:header>
        {{ $t('breakthroughsWithSealingsMeterCabinetAttachments') }}
      </template>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('breakthroughsWithSealingsMeterCabinetAttachments') }}
        </div>
        <div class="flex-column select"></div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.breakthroughsWithSealingsMeterCabinetAttachments"
            attachmentType="breakthroughsWithSealingsMeterCabinetAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isBreakthroughsWithSealingsMeterCabinetAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="breakthroughsWithSealingsMeterCabinetAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isBreakthroughsWithSealingsMeterCabinetAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.electricMeterOptional"
      :project="project"
      notesType="electricMeterNotes"
      :attachments="project.electricMeterAndGakOptionalAttachments"
      attachmentType="electricMeterAndGakOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      iselectricMeterAttachmentsUploadComplete: false,
      iselectricMeterReadingAttachmentsUploadComplete: false,
      isgakAttachmentsUploadComplete: false,
      isBreakthroughsWithSealingsMeterCabinetAttachmentsUploadComplete: false,
      isClosedMeterCabinetInscribedComponentsAttachmentsUploadComplete: false,
      isClosedPreMeterAreaWithPlombsAttachmentsUploadComplete: false,
      isOpenMeterCabinetPrePostAreaAttachmentsUploadComplete: false,
      isInstalledComponentsInMeterCabinetAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.iselectricMeterAttachmentsUploadComplete = true;
          this.iselectricMeterReadingAttachmentsUploadComplete = true;
          this.isgakAttachmentsUploadComplete = true;
          this.isBreakthroughsWithSealingsMeterCabinetAttachmentsUploadComplete = true;
          this.isClosedMeterCabinetInscribedComponentsAttachmentsUploadComplete = true;
          this.isClosedPreMeterAreaWithPlombsAttachmentsUploadComplete = true;
          this.isOpenMeterCabinetPrePostAreaAttachmentsUploadComplete = true;
          this.isInstalledComponentsInMeterCabinetAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
