<template>
  <div>
    <div class="accordion" role="tablist" v-if="project.measurement">
      <Roof :cards="cards" :project="project" />

      <div v-if="isPitchedRoof">
        <h1>1.1 {{ $t('innerRoof') }}</h1>
        <InnerRoof :cards="cards" :project="project" />

        <h1>1.2 {{ $t('rafter') }}</h1>
        <Rafter :cards="cards" :project="project" />

        <h1>1.3 {{ $t('outerRoof') }}</h1>
        <OuterRoof :cards="cards" :project="project" />

        <h1>1.4 {{ $t('bricks') }}</h1>
        <Bricks :cards="cards" :project="project" />

        <h1>1.5 {{ $t('roofLayout') }}</h1>
        <RoofLayout :cards="cards" :project="project" />
      </div>

      <div v-if="isFlatRoof">
        <h1>1.1 {{ $t('flatRoof') }}</h1>
        <FlatRoof :cards="cards" :project="project" />
      </div>

      <h1>
        <span v-if="isPitchedRoof">1.6</span>
        <span v-else>1.2</span>
        {{ $t('roofAccess') }}
      </h1>
      <RoofAccess :cards="cards" :project="project" />

      <h1>2.1 {{ $t('hak') }}</h1>
      <Hak :cards="cards" :project="project" />

      <h1>2.2 {{ $t('zs') }}</h1>
      <Zs :cards="cards" :project="project" />

      <h1>2.3 {{ $t('electric_meter') }}</h1>
      <ElectricMeter :cards="cards" :project="project" />

      <h1>2.4 {{ $t('locationAndCable') }}</h1>
      <LocationAndCablePath :cards="cards" :project="project" />

      <h1>2.5 {{ $t('earthingUnit') }}</h1>
      <Earthing :cards="cards" :project="project" />

      <h1>2.6 {{ $t('internetConnection') }}</h1>
      <InternetConnection :cards="cards" :project="project" />

      <h1>2.7 {{ $t('wallbox') }}</h1>
      <Wallbox :cards="cards" :project="project" />

      <h1>3. {{ $t('customer') }}</h1>
      <Customer :cards="cards" :project="project" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Roof from './MobileMeasurement/Roof.vue';
import LocationAndCablePath from './MobileMeasurement/LocationAndCablePath.vue';
import ElectricMeter from './MobileMeasurement/ElectricMeter.vue';
import Zs from './MobileMeasurement/Zs.vue';
import Hak from './MobileMeasurement/Hak.vue';
import RoofAccess from './MobileMeasurement/RoofAccess.vue';
import RoofLayout from './MobileMeasurement/RoofLayout.vue';
import Bricks from './MobileMeasurement/Bricks.vue';
import OuterRoof from './MobileMeasurement/OuterRoof.vue';
import Rafter from './MobileMeasurement/Rafter.vue';
import InnerRoof from './MobileMeasurement/InnerRoof.vue';
import Earthing from './MobileMeasurement/Earthing.vue';
import InternetConnection from './MobileMeasurement/InternetConnection.vue';
import Wallbox from './MobileMeasurement/Wallbox.vue';
import Customer from './MobileMeasurement/Customer.vue';
import FlatRoof from './MobileMeasurement/FlatRoof.vue';

export default {
  name: 'MobileProjectsMeasurement',
  components: {
    Roof,
    LocationAndCablePath,
    ElectricMeter,
    Zs,
    Hak,
    RoofAccess,
    RoofLayout,
    Bricks,
    OuterRoof,
    Rafter,
    InnerRoof,
    Earthing,
    InternetConnection,
    Wallbox,
    Customer,
    FlatRoof,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      cards: {
        /** callback function for card navigation */
        navigateTo: this.goToCard,
        /** 1. Dachtyp */
        roofType: {
          name: 'roofType',
          opened: true,
          navigateTo: this.goToCard,
          successor: 'flatRoofMeasurementsOrpitchedRoofCondition',
          predecessor: null,
          completed: this.completedState,
        },
        /** Zustand Dach */
        pitchedRoofCondition: {
          name: 'pitchedRoofCondition',
          navigateTo: this.goToCard,
          successor: 'bricksPlastered',
          predecessor: 'roofType',
          opened: false,
          completed: this.completedState,
        },
        /** Ziegel vermörtelt? */
        bricksPlastered: {
          name: 'bricksPlastered',
          navigateTo: this.goToCard,
          successor: 'cardboardPoppetState',
          predecessor: 'pitchedRoofCondition',
          opened: false,
          completed: this.completedState,
        },
        /** Pappdocken vorhanden? */
        cardboardPoppetState: {
          name: 'cardboardPoppetState',
          navigateTo: this.goToCard,
          successor: 'pedimentAttachments',
          predecessor: 'bricksPlastered',
          opened: false,
          completed: this.completedState,
        },
        /** Foto Giebel */
        pedimentAttachments: {
          name: 'pedimentAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'pitchedRoofOptionalAttachments',
          predecessor: 'cardboardPoppetState',
        },
        /** Optionale Fotos */
        pitchedRoofOptionalAttachments: {
          name: 'pitchedRoofOptionalAttachments',
          navigateTo: this.goToCard,
          opened: false,
          completed: this.completedState,
          successor: 'pitchedRoofNotes',
          predecessor: 'pedimentAttachments',
        },
        /** Optionale Notizen */
        pitchedRoofNotes: {
          name: 'pitchedRoofNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rafterVisibleState',
          predecessor: 'pitchedRoofOptionalAttachments',
        },
        /**Sparren von innen sichtbar? */
        rafterVisibleState: {
          name: 'rafterVisibleState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rafterWidthAndGap',
          predecessor: 'pitchedRoofNotes',
        },
        /** Sparrenbreite */
        rafterWidthAndGap: {
          name: 'rafterWidth',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rafterOptionalAttachments',
          predecessor: 'rafterVisibleState',
        },
        /** Sparren Optionale Fotos */
        rafterOptionalAttachments: {
          name: 'rafterOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rafterNotes',
          predecessor: 'rafterWidthAndGap',
        },
        /** Sparren Optionale Notizen */
        rafterNotes: {
          name: 'rafterNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'eavesHeight',
          predecessor: 'rafterOptionalAttachments',
        },
        /** Traufhöhe */
        eavesHeight: {
          name: 'eavesHeight',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rafterInsulation',
          predecessor: 'rafterNotes',
        },
        /** Aufsparrendämmung verbaut? */
        rafterInsulation: {
          name: 'rafterInsulation',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'lightningProtectionState',
          predecessor: 'eavesHeight',
        },
        /** Blitzschutz vorhanden? */
        lightningProtectionState: {
          name: 'lightningProtectionState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'lightningProtectionState_next',
          predecessor: 'lightningProtectionState_prev',
          // next: async () => {
          //   this.isPitchedRoof
          //     ? this.toggle(this.cards.bricksRemoveableState)
          //     : this.toggle(this.cards.roofSidesShadowsState);
          //   await this.updateProject(this.project);
          // },
          // prev: async () => {
          //   this.isPitchedRoof
          //     ? this.toggle(this.cards.rafterInsulation)
          //     : this.toggle(this.cards.flatRoofMeasurements);
          //   await this.updateProject(this.project);
          // },
        },
        /** Sind die Ziegel leicht lösbar? */
        bricksRemoveableState: {
          name: 'bricksRemoveableState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofPitchInDegrees',
          predecessor: 'lightningProtectionState',
        },
        /** Dachneigung in Grad */
        roofPitchInDegrees: {
          name: 'roofPitchInDegrees',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'outerRoofOptionalAttachments',
          predecessor: 'bricksRemoveableState',
        },
        /** Dachaußenseite Optionale Fotos */
        outerRoofOptionalAttachments: {
          name: 'outerRoofOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'outerRoofNotes',
          predecessor: 'roofPitchInDegrees',
        },
        /** Dachaußenseite Optionale Notizen */
        outerRoofNotes: {
          name: 'outerRoofNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'bricksConditionState',
          predecessor: 'outerRoofOptionalAttachments',
        },
        /** Zustand der Ziegel ? */
        bricksConditionState: {
          name: 'bricksConditionState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'bricksFrontAttachments',
          predecessor: 'outerRoofNotes',
        },
        /** Ziegel Voderseite Fotos */
        bricksFrontAttachments: {
          name: 'bricksFrontAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'bricksBackAttachments',
          predecessor: 'bricksConditionState',
        },
        /** Ziegel Rückseite Fotos */
        bricksBackAttachments: {
          name: 'bricksBackAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'brickManufacturerAndType',
          predecessor: 'bricksFrontAttachments',
        },
        /** Ziegel Hersteller / Bezeichnung */
        brickManufacturerAndType: {
          name: 'brickManufacturerAndType',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'tinBricksRequiredState',
          predecessor: 'bricksBackAttachments',
        },
        /** Blechziegel notwendig? */
        tinBricksRequiredState: {
          name: 'tinBricksRequiredState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'bricksOptionalAttachments',
          predecessor: 'brickManufacturerAndType',
        },
        /** Ziegel Optionale Fotos */
        bricksOptionalAttachments: {
          name: 'bricksOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'bricksNotes',
          predecessor: 'tinBricksRequiredState',
        },
        /** Ziegel Optionale Notizen */
        bricksNotes: {
          name: 'bricksNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesAssignedState',
          predecessor: 'bricksOptionalAttachments',
        },
        /** Wie viele Dachseiten sollen belegt werden ? */
        roofSidesAssignedState: {
          name: 'roofSidesAssignedState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesAttachments',
          predecessor: 'bricksNotes',
        },
        /** Fotos der Dachseiten */
        roofSidesAttachments: {
          name: 'roofSidesAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesMeasurements',
          predecessor: 'roofSidesAssignedState',
        },
        /** Maße der Dachflächen */
        roofSidesMeasurements: {
          name: 'roofSidesMeasurements',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesShadowsState',
          predecessor: 'roofSidesAttachments',
        },
        /** Verschattungen */
        roofSidesShadowsState: {
          name: 'roofSidesShadowsState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesSketchAttachments',
          predecessor: 'roofSidesShadowsState_prev',
        },
        /** Skizze */
        roofSidesSketchAttachments: {
          name: 'roofSidesSketchAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesSketchAttachments_next',
          predecessor: 'roofSidesShadowsState',
        },
        /** Dachbelegung Optionale Fotos */
        roofSidesOptionalAttachments: {
          name: 'roofSidesOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofSidesNotes',
          predecessor: 'tinBricksRequiredState',
        },
        /** Dachbelegung Optionale Notizen */
        roofSidesNotes: {
          name: 'roofSidesNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'scaffoldingPossibleState',
          predecessor: 'roofSidesOptionalAttachments',
        },
        /** Gerüst möglich? */
        scaffoldingPossibleState: {
          name: 'scaffoldingPossibleState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofAccessAttachments',
          predecessor: 'roofSidesNotes',
        },
        /** Foto Dachzugang */
        roofAccessAttachments: {
          name: 'roofAccessAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rainPipeMaterialType',
          predecessor: 'scaffoldingPossibleState',
        },
        /** Dachrinnen Material */
        rainPipeMaterialType: {
          name: 'rainPipeMaterialType',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'rssUsableState',
          predecessor: 'roofAccessAttachments',
        },
        /** RSS nutzbar? */
        rssUsableState: {
          name: 'rssUsableState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roadBlockRequiredState',
          predecessor: 'rainPipeMaterialType',
        },
        /** Straßensperrung notwendig? */
        roadBlockRequiredState: {
          name: 'roadBlockRequiredState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'overheadLineState',
          predecessor: 'rssUsableState',
        },
        /** Freileitungen?   */
        overheadLineState: {
          name: 'overheadLineState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofAccessOptionalAttachments',
          predecessor: 'roadBlockRequiredState',
        },
        /** Dachzugang Optionale Fotos */
        roofAccessOptionalAttachments: {
          name: 'roofAccessOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'roofAccessNotes',
          predecessor: 'overheadLineState',
        },
        /** Dachzugang Optionale Notizen */
        roofAccessNotes: {
          name: 'roofAccessNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakLocationState',
          predecessor: 'roofAccessOptionalAttachments',
        },
        /** Wo ist der HAK */
        hakLocationState: {
          name: 'hakLocationState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakAndZsSameRoomState',
          predecessor: 'roofAccessNotes',
        },
        /** HAK und ZS gleicher Raum */
        hakAndZsSameRoomState: {
          name: 'hakAndZsSameRoomState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakAttachments',
          predecessor: 'hakLocationState',
        },
        /** Foto HAK 5m Abstand */
        hakAttachments: {
          name: 'hakAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakLockedState',
          predecessor: 'hakAndZsSameRoomState',
        },
        /** HAK verschlossen? */
        hakLockedState: {
          name: 'hakLockedState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakToZsDistance',
          predecessor: 'hakAttachments',
        },
        /** Entfernung HAK zum ZS in m */
        hakToZsDistance: {
          name: 'hakToZsDistance',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'fireServiceSwitchPresentState',
          predecessor: 'hakLockedState',
        },
        /** Feuerwehrschalter vorhanden */
        fireServiceSwitchPresentState: {
          name: 'fireServiceSwitchPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakOptionalAttachments',
          predecessor: 'hakToZsDistance',
        },
        /** HAK Optionale Fotos */
        hakOptionalAttachments: {
          name: 'hakOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'hakNotes',
          predecessor: 'fireServiceSwitchPresentState',
        },
        /** HAK Optionale Notizen */
        hakNotes: {
          name: 'hakNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsLocationState',
          predecessor: 'hakOptionalAttachments',
        },
        /** Wo ist der Zählerschrank? */
        zsLocationState: {
          name: 'zsLocationState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsWithCoverAttachments',
          predecessor: 'hakNotes',
        },
        /** Foto Zählerschrank mit Abdeckungen */
        zsWithCoverAttachments: {
          name: 'zsWithCoverAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsWithoutCoverAttachments',
          predecessor: 'zsLocationState',
        },
        /** Foto Zählerschrank ohne Abdeckungen */
        zsWithoutCoverAttachments: {
          name: 'zsWithoutCoverAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'newZsRequiredState',
          predecessor: 'zsWithCoverAttachments',
        },
        /**  Wird eine neuer Zählerschrank benötigt? */
        newZsRequiredState: {
          name: 'newZsRequiredState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsSubDistributionRequiredState',
          predecessor: 'zsWithoutCoverAttachments',
        },
        /** Ist eine zusätzliche Unterverteilung notwendig? */
        zsSubDistributionRequiredState: {
          name: 'zsSubDistributionRequiredState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'netType',
          predecessor: 'newZsRequiredState',
        },
        /**  Netzform */
        netType: {
          name: 'netType',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsOptionalAttachments',
          predecessor: 'zsSubDistributionRequiredState',
        },
        /** Zählerschrank Optionale Fotos */
        zsOptionalAttachments: {
          name: 'zsOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'zsNotes',
          predecessor: 'netType',
        },
        /** Zählerschrank Optionale Notizen  */
        zsNotes: {
          name: 'zsNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'electricMeterCount',
          predecessor: 'zsOptionalAttachments',
        },
        /** Wie viele Zähler hat der Kunde? */
        electricMeterCount: {
          name: 'electricMeterCount',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'mainElectricMeterAttachments',
          predecessor: 'zsNotes',
        },
        /** Foto Hauptzähler */
        mainElectricMeterAttachments: {
          name: 'mainElectricMeterAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'electricMeterList',
          predecessor: 'electricMeterCount',
        },
        /** Zählerauflistung */
        electricMeterList: {
          name: 'electricMeterList',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'seconaryElectricMeterAttachments',
          predecessor: 'mainElectricMeterAttachments',
        },
        /** Foto der weiteren Zähler */
        seconaryElectricMeterAttachments: {
          name: 'seconaryElectricMeterAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'intermediateMeterRequesteddState',
          predecessor: 'electricMeterList',
        },
        /** Zwischenzähler gewünscht? */
        intermediateMeterRequesteddState: {
          name: 'intermediateMeterRequesteddState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'meterCombinationOrCascadeRequestedState',
          predecessor: 'seconaryElectricMeterAttachments',
        },
        /** Zählerzusammenlegung oder Kaskadenschaltung */
        meterCombinationOrCascadeRequestedState: {
          name: 'meterCombinationOrCascadeRequestedState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'electricMeterOptionalAttachments',
          predecessor: 'intermediateMeterRequesteddState',
        },
        /** Zähler Optionale Fotos */
        electricMeterOptionalAttachments: {
          name: 'electricMeterOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'electricMeterOptionalNotes',
          predecessor: 'meterCombinationOrCascadeRequestedState',
        },
        /** Zähler Optionale Notizen  */
        electricMeterOptionalNotes: {
          name: 'electricMeterOptionalNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'acInstallationLocationState',
          predecessor: 'electricMeterOptionalAttachments',
        },
        /** Wo sollen die AC Komponenten installiert werden? */
        acInstallationLocationState: {
          name: 'acInstallationLocationState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'acInstallationLocationAttachments',
          predecessor: 'electricMeterOptionalNotes',
        },
        /** Z Foto mit Zeichnung */
        acInstallationLocationAttachments: {
          name: 'acInstallationLocationAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'mateboxToZsDistance',
          predecessor: 'acInstallationLocationState',
        },
        /** Entfernung Matebox zum Zählerschrank in m */
        mateboxToZsDistance: {
          name: 'mateboxToZsDistance',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'breakthroughCountState',
          predecessor: 'acInstallationLocationAttachments',
        },
        /** Anzahl Durchbrüche */
        breakthroughCountState: {
          name: 'breakthroughCountState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'cabelPathDescription',
          predecessor: 'mateboxToZsDistance',
        },
        /** Anzahl Durchbrüche */
        cabelPathDescription: {
          name: 'cabelPathDescription',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'locationAndCablePathOptionalAttachments',
          predecessor: 'breakthroughCountState',
        },
        /** Aufstellorte & Kabelweg Optionale Fotos */
        locationAndCablePathOptionalAttachments: {
          name: 'locationAndCablePathOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'locationAndCablePathNotes',
          predecessor: 'cabelPathDescription',
        },
        /** Aufstellorte & Kabelweg Optionale Notizen */
        locationAndCablePathNotes: {
          name: 'locationAndCablePathNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'equipotentialBusBarPresentState',
          predecessor: 'locationAndCablePathOptionalAttachments',
        },
        /** Potentialausgleichschiene vorhanden? */
        equipotentialBusBarPresentState: {
          name: 'equipotentialBusBarPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'earthingPresentState',
          predecessor: 'locationAndCablePathNotes',
        },
        /** Erdung vorhanden? */
        earthingPresentState: {
          name: 'earthingPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'earthingOptionalAttachments',
          predecessor: 'equipotentialBusBarPresentState',
        },
        /** Erdungsanlage Optionale Fotos */
        earthingOptionalAttachments: {
          name: 'earthingOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'earthinghNotes',
          predecessor: 'earthingPresentState',
        },
        /** Erdungsanlage Optionale Notizen  */
        earthinghNotes: {
          name: 'earthinghNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'internetConnectionPresentState',
          predecessor: 'earthingOptionalAttachments',
        },
        /** Verbindung vorhanden */
        internetConnectionPresentState: {
          name: 'internetConnectionPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'lanConnectionPresentState',
          predecessor: 'earthinghNotes',
        },
        /** LAN Anschluss im Installationsraum? */
        lanConnectionPresentState: {
          name: 'lanConnectionPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'internetConnectionAttachments',
          predecessor: 'internetConnectionPresentState',
        },
        /** Internetanschluss Optionale Fotos */
        internetConnectionAttachments: {
          name: 'internetConnectionAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'internetConnectionNotes',
          predecessor: 'lanConnectionPresentState',
        },
        /** Internetanschluss Optionale Notizen */
        internetConnectionNotes: {
          name: 'internetConnectionNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxRequiredState',
          predecessor: 'internetConnectionAttachments',
        },
        /** Kunde möchte Wallbox */
        wallboxRequiredState: {
          name: 'wallboxRequiredState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'heavyCurrentPresentState',
          predecessor: 'internetConnectionNotes',
        },
        /** Starkstrom vorhanden? */
        heavyCurrentPresentState: {
          name: 'heavyCurrentPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxLocationAttachments',
          predecessor: 'wallboxRequiredState',
        },
        /**  Aufstellort Wallbox */
        wallboxLocationAttachments: {
          name: 'wallboxLocationAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxWirelessInstallationPossibleState',
          predecessor: 'heavyCurrentPresentState',
        },
        /** Installation über WLan möglich */
        wallboxWirelessInstallationPossibleState: {
          name: 'wallboxWirelessInstallationPossibleState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxCablePathNotes',
          predecessor: 'wallboxLocationAttachments',
        },
        /** Beschreibung Kabelweg */
        wallboxCablePathNotes: {
          name: 'wallboxCablePathNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxOptionalAttachments',
          predecessor: 'wallboxWirelessInstallationPossibleState',
        },
        /** Wallbox Optionale Fotos */
        wallboxOptionalAttachments: {
          name: 'wallboxOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'wallboxNotes',
          predecessor: 'wallboxCablePathNotes',
        },
        /** Wallbox Optionale Notizen */
        wallboxNotes: {
          name: 'wallboxNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'customerQuestions',
          predecessor: 'wallboxOptionalAttachments',
        },
        /** offene Fragen Kunden? */
        customerQuestions: {
          name: 'customerQuestions',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'customerWorking',
          predecessor: 'wallboxNotes',
        },
        /** Bauseits Arbeiten Kunden? */
        customerWorking: {
          name: 'customerWorking',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'customerSignature',
          predecessor: 'customerQuestions',
        },
        /** Unterschrift Kunde */
        customerSignature: {
          name: 'customerSignature',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: null,
          predecessor: 'customerWorking',
          next: null,
        },

        /** Flachdach */
        flatRoofMeasurements: {
          name: 'flatRoofMeasurements',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'flatRoofingMaterialType',
          predecessor: 'roofType',
        },
        /** Dacheindeckung des Flachdachs */
        flatRoofingMaterialType: {
          name: 'flatRoofingMaterialType',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'flatRoofSurfaceAttachments',
          predecessor: 'flatRoofMeasurements',
        },
        /** Foto Dachfläche */
        flatRoofSurfaceAttachments: {
          name: 'flatRoofSurfaceAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'atticPresentState',
          predecessor: 'flatRoofingMaterialType',
        },
        /** Attika vorhanden */
        atticPresentState: {
          name: 'atticPresentState',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'lightningProtectionState',
          predecessor: 'flatRoofSurfaceAttachments',
        },
        /** Flachdach Optionale Fotos */
        flatRoofOptionalAttachments: {
          name: 'flatRoofOptionalAttachments',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'flatRoofNotes',
          predecessor: 'roofSidesSketchAttachments',
        },
        /** Flachdach Optionale Notizen */
        flatRoofNotes: {
          name: 'flatRoofNotes',
          opened: false,
          completed: this.completedState,
          navigateTo: this.goToCard,
          successor: 'scaffoldingPossibleState',
          predecessor: 'flatRoofOptionalAttachments',
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getReportTemplateImages', 'getEnumValues']),
    isFlatRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'fetchEnumValues',
      'deleteProjectAttachment',
      'updateProject',
    ]),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    toggle(item) {
      item.opened = !item.opened;
    },

    /**
     * Emit a scroll event, the AccordionCard Component will resolve it
     */
    scrollTo(name) {
      this.$eventHub.$emit('scroll-to-accordion', name);
    },

    /** */
    saveSignature(data, type) {
      console.log(type, data);
      this.project.enpalReport[type] = data;
      this.saveReportSignature({
        projectId: this.project.id,
        payload: data,
        signatureType: type,
      });
    },

    /**
     * save the project and open the target card
     */
    async goToCard(target, update = true) {
      // console.log('next()', target);

      let card = target;
      if (target === 'flatRoofMeasurementsOrpitchedRoofCondition') {
        card = this.isFlatRoof ? 'flatRoofMeasurements' : 'pitchedRoofCondition';
      }
      if (target === 'lightningProtectionState_next') {
        card = this.isFlatRoof ? 'roofSidesShadowsState' : 'bricksRemoveableState';
      }
      if (target === 'lightningProtectionState_prev') {
        card = this.isFlatRoof ? 'flatRoofMeasurements' : 'rafterInsulation';
      }
      if (target === 'roofSidesShadowsState_prev') {
        card = this.isFlatRoof ? 'lightningProtectionState' : 'roofSidesMeasurements';
      }
      if (target === 'roofSidesSketchAttachments_next') {
        card = this.isFlatRoof ? 'flatRoofOptionalAttachments' : 'roofSidesOptionalAttachments';
      }

      this.toggle(this.cards[card]);

      this.$nextTick(() => {
        this.scrollTo(card);
      });

      if (update) {
        await this.updateProject(this.project);
      }
    },

    /**
     * Map a completed state to each field
     * @todo DRY
     */
    completedState(type, newValue = null) {
      // console.log('completedState():', type, newValue);

      this.project.measurement.completed = this.project.measurement.completed
        ? this.project.measurement.completed
        : [];

      const existingField = this.project.measurement.completed.find((field) => field.type === type);

      if (!existingField) {
        const newField = { type: type, value: false };

        this.project.measurement.completed.push(newField);
        return newField.value;
      } else if (newValue !== null) {
        existingField.value = newValue;
      }

      return existingField.value;
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async created() {
    // console.log('created()');
    // this.createCards();
  },

  async mounted() {
    // console.log('mounted()');
    await this.fetchEnumValues('RoofType');
    await this.fetchEnumValues('PitchedRoofBricksPlasteredState');
    await this.fetchEnumValues('PitchedRoofConditionState');
    await this.fetchEnumValues('BricksConditionState');
    await this.fetchEnumValues('OneToEightState');
    await this.fetchEnumValues('RainPipeMaterialType');
    await this.fetchEnumValues('HouseLocationState');
    await this.fetchEnumValues('NewZsRequiredState');
    await this.fetchEnumValues('NetType');
    await this.fetchEnumValues('MeterCombinationOrCascadeRequestedState');
    await this.fetchEnumValues('FlatRoofingMaterialType');
    await this.fetchEnumValues('YesNo');

    if (!this.project.measurement) {
      this.project.measurement = {
        roofType: 'PITCHED_ROOF',
        pitchedRoof: {},
        flatRoof: {},
        commonRoof: {},
        ac: {},
        customer: {},
      };
    }
  },
};
</script>

<style lang="scss">
#end {
  height: 200px;
  background: red;
}

/** signature pad */
.signature-pad {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.signature-buttons {
  text-align: center;
  position: relative;
  margin: 0 auto;
  max-width: 500px;
}
.inset-shadow {
  -moz-box-shadow: inset 0px 0px 12px -8px #000000;
  -webkit-box-shadow: inset 0px 0px 12px -8px #000000;
  box-shadow: inset 0px 0px 12px -8px #000000;
}

h1 {
  font-size: 1.1em;
  margin: 0.3em;
  font-weight: bold;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  gap: 1em;
  margin-bottom: 1em;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  // border: 1px solid red;
}

.flex-column.name {
  flex: 1 150px;
  font-weight: bold;
}
.flex-column.select {
  flex: 8 500px;
}
.flex-column.select .thumbnail img {
  max-width: 100%;
  max-height: 350px;
  object-fit: cover;
  margin-bottom: 0.5em;
}

.p-selectbutton .p-button.p-highlight {
  background: $bg;
  border-color: $bg;
}
</style>
