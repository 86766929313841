<template>
  <div>
    <Scaffolding :cards="cards" :project="project" />

    <AccordionCard :state="cards.roofAccessAttachments">
      <template v-slot:header>
        {{ $t('roofAccessAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roofAccessAttachments') }}
        </div>
        <div v-if="isFlatRoof" class="flex-column select">
          <p>
            {{ $t('roofAccessAttachments') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[10].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
        <div v-else class="flex-column select">
          <p>
            {{ $t('roofAccessAttachments') }}
          </p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[17].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofAccessAttachments"
            attachmentType="roofAccessAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isRoofAccessAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="roofAccessAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isRoofAccessAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.rainPipeMaterialType">
      <template v-slot:header>
        {{ $t('rainPipeMaterialType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('rainPipeMaterialType') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.rainPipeMaterialType"
            :options="getEnumValues('RainPipeMaterialType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.rssUsableState">
      <template v-slot:header>
        {{ $t('rssUsableState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('rssUsableState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.rssUsableState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.pitchedRoof.rssUsableState === 'NO'">
        <div class="flex-column name">{{ $t('why_not') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.rssUsableNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roadBlockRequiredState">
      <template v-slot:header>
        {{ $t('roadBlockRequiredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('roadBlockRequiredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.roadBlockRequiredState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.overheadLineState">
      <template v-slot:header>
        {{ $t('overheadLineState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('overheadLineState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.overheadLineState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofAccessOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.roofAccessOptionalAttachments"
            attachmentType="roofAccessOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isRoofAccessOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="roofAccessOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isRoofAccessOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.roofAccessNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.roofAccessNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import Scaffolding from './Scaffolding.vue';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
    Scaffolding,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isRoofAccessAttachmentsUploadComplete: false,
      isRoofAccessOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isFlatRoof() {
      return this.project.measurement.roofType === 'FLAT_ROOF';
    },
    isPitchedRoof() {
      return this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isRoofAccessAttachmentsUploadComplete = true;
          this.isRoofAccessOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
