<template>
  <b-card :title="$t('posts') + ' (' + posts.length + ')'" class="border-1 mb-0">
    <Posts
      :posts="posts"
      :deleteable="true"
      :user="getCurrentUser"
      v-on:comment:post="onCreatePost($event, project.id)"
      v-on:comment:delete="onDeletePost($event, project.id)"
    />
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Posts from '@/components/Posts';

export default {
  components: {
    Posts,
  },
  props: {
    project: { type: Object },
    projectNumber: null,
  },
  data() {
    return {
      pollingInterval: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isClient', 'getCurrentUser']),

    /**
     * Get all post from project
     */
    posts() {
      if (this.project.posts) {
        return this.project.posts;
      }
      return [];
    },
  },
  methods: {
    ...mapActions(['fetchPostsOfProjectByNumber', 'addPostToProject', 'deletePostFromProject']),

    /**
     * Create a post assigned to the project
     */
    onCreatePost(post) {
      this.addPostToProject({
        content: post,
        user: this.getCurrentUser.id,
        project: this.project.id,
      });
    },

    /**
     * Delete a post
     */
    onDeletePost(postId, projectId) {
      this.deletePostFromProject({ postId: postId, projectId: projectId });
    },

    /**
     * Fetch posts of the project every 5 seconds
     */
    startPolling() {
      this.pollingInterval = setInterval(async () => {
        await this.fetchPostsOfProjectByNumber(this.project.number);
      }, 25000);
    },

    /**
     * Stop polling when component is destroyed
     */
    stopPolling() {
      clearInterval(this.pollingInterval);
    },
  },
  mounted() {
    if (this.project.number && this.project.number !== '_new') {
      this.startPolling();
    }
  },
  beforeDestroy() {
    this.stopPolling();
  },
};
</script>

<style scoped lang="scss"></style>
