<template>
  <div class="project-solarPlant">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('baseConfiguration') }}</strong>
        </legend>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('inverter') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">
              <strong>{{ InverterNominalPowerOverall }} kW</strong>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('inverter1')"
              label-for="inverter"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('inverter1')"
            >
              <multiselect
                :placeholder="$t('inverter1')"
                v-model="project.solarPlant.inverter1"
                id="inverter1"
                :options="filteredInverters"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onInverterSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">{{ inverter1Power }} kW</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('inverter2')"
              label-for="inverter2"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('inverter2')"
                v-model="project.solarPlant.inverter2"
                id="inverter"
                :options="filteredInverters"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onInverterSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">{{ inverter2Power }} kW</div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('solarPanel') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 ">
              <strong>{{ solarPanelsOverallPower }} kWp</strong>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('solarPanel')"
              label-for="solarPanel"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('solarPanel')"
            >
              <multiselect
                :placeholder="$t('solarPanel')"
                v-model="project.solarPlant.solarPanel"
                id="solarPanel"
                :options="filteredSolarPanels"
                track-by="number"
                v-on:search-change="onSolarPanelSearch"
                :internal-search="false"
                :show-labels="true"
                :deselectLabel="$t('deselectLabel')"
                selectLabel=""
                selectedLabel=""
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">
              {{ solarPanelPower }}
              Wp
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('solarPanelCount')"
              label-for="solarPanelCount"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
              :invalid-feedback="invalidFeedbackNA"
              :state="requirementsValidState('solarPanelCount')"
            >
              <b-form-input
                v-model="project.solarPlant.solarPanelCount"
                type="text"
                :placeholder="$t('solarPanelCount')"
                :state="requirementsValidState('solarPanelCount')"
              />
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('battery') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('battery')"
              label-for="battery"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('battery')"
                v-model="project.solarPlant.battery"
                id="battery"
                :options="filteredBatteries"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onBatterySearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">{{ batteryCapacity }} kWh</div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12"> </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1" v-if="project.solarPlant.battery">
              <span>{{ $t(project.solarPlant.battery.connectionType) }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="10" md="6" sm="12">
            <strong>{{ $t('additionalComponents') }}</strong>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('energyFlowDirectionSensor_short')"
              label-for="energyFlowDirectionSensor"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('energyFlowDirectionSensor_short')"
                v-model="project.solarPlant.energyFlowDirectionSensor"
                id="energyFlowDirectionSensor"
                :options="filteredEnergyFlowFirectionSensors"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onEnergyFlowDirectionSensorSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('wallbox')"
              label-for="wallbox"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('wallbox')"
                v-model="project.solarPlant.wallbox"
                id="wallbox"
                :options="filteredWallboxes"
                :allow-empty="true"
                :show-labels="false"
                track-by="number"
                v-on:search-change="onWallboxSearch"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.manufacturer }} - {{ option.type }}
                </template>
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12">
            <div class="text-center border p-1 mt-1">
              <span v-if="project.solarPlant.wallbox">{{
                project.solarPlant.wallbox.maxNetInputPower
              }}</span>
              kW
            </div></b-col
          >
        </b-row>
        <b-row>
          <b-col lg="10" md="6" sm="12">
            <b-form-group
              horizontal
              :label="$t('emergencyPowerState')"
              label-for="emergencyPowerState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <multiselect
                :placeholder="$t('emergencyPowerState')"
                v-model="project.solarPlant.emergencyPowerState"
                :options="optionsYesNo"
                :show-labels="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }"
                  ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
                >
                <template slot="option" slot-scope="{ option }">
                  <b-badge :variant="yesNoColor(option)">
                    {{ $t(option) }}
                  </b-badge></template
                >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col lg="2" md="6" sm="12"> </b-col>
        </b-row>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <legend>
          <strong>{{ $t('extendedConfiguration') }}</strong>
        </legend>

        <b-form-group
          horizontal
          :label="$t('cascadeConnectionState')"
          label-for="cascadeConnectionState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('cascadeConnectionState')"
            v-model="project.solarPlant.cascadeConnectionState"
            :options="optionsYesNo"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="yesNoColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('networkSupplyManagementState')"
          label-for="networkSupplyManagementState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('networkSupplyManagementState')"
            v-model="project.solarPlant.networkSupplyManagementState"
            :options="getNetworkSupplyManagementStates"
            :show-labels="false"
            :allow-empty="true"
            @select="onNetworkSupplyManagementState"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('networkSupplyManagementPermanentRestrictionState')"
          label-for="networkSupplyManagementPermanentRestrictionState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('networkSupplyManagementPermanentRestrictionState')"
            v-model="project.solarPlant.networkSupplyManagementPermanentRestrictionState"
            :options="getNetworkSupplyManagementPermanentRestrictionStates"
            :show-labels="false"
            :allow-empty="true"
            :disabled="networkSupplyManagementPermanentRestrictionStateIsDisabled"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('networkSupplyManagementXOverState')"
          label-for="networkSupplyManagementXOverState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('networkSupplyManagementXOverState')"
            v-model="project.solarPlant.networkSupplyManagementXOverState"
            :options="getNetworkSupplyManagementXOverStates"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('netOutputState')"
          label-for="netOutputState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('netOutputState') + ' ' + $t('select')"
            v-model="project.solarPlant.netOutputStates"
            :options="getNetOutputStates"
            :close-on-select="false"
            :clear-on-select="false"
            :multiple="true"
            :taggable="true"
            :show-labels="true"
            :allow-empty="true"
            :selectLabel="$t('select')"
            :selectedLabel="$t('selected')"
            :deselectLabel="$t('remove_selection')"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
            <template slot="tag" slot-scope="{ option }">
              <b-badge>
                {{ $t(option) }}
              </b-badge>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('electricMeterMountingState')"
          label-for="electricMeterMountingState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('electricMeterMountingState')"
            v-model="project.solarPlant.electricMeterMountingState"
            :options="getElectricMeterMountingStates"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <!-- <b-form-group
          horizontal
          :label="$t('storageCouplingState')"
          label-for="storageCouplingState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('storageCouplingState')"
            v-model="project.solarPlant.storageCouplingState"
            :options="getStorageCouplingStates"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group> -->

        <b-form-group
          horizontal
          :label="$t('yearlyPower')"
          label-for="yearlyPower"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <InputNumber
            v-model="project.solarPlant.yearlyPower"
            mode="decimal"
            :minFractionDigits="2"
            suffix=" kWh"
            locale="de-DE"
            :placeholder="$t('yearlyPower')"
          />
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('houseFuseState')"
          label-for="houseFuseState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('houseFuseState')"
            v-model="project.solarPlant.houseFuseState"
            :options="getHouseFuseStates"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('electricMeterCombinationState')"
          label-for="electricMeterCombinationState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('electricMeterCombinationState')"
            v-model="project.solarPlant.electricMeterCombinationState"
            :options="optionsYesNo"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="yesNoColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('electricMeterDeregistrationState')"
          label-for="electricMeterDeregistrationState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('electricMeterDeregistrationState')"
            v-model="project.solarPlant.electricMeterDeregistrationState"
            :options="projectElectricMeters"
            :show-labels="false"
            :allow-empty="true"
          >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('solarPlantType')"
          label-for="solarPlantType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('solarPlantType')"
            v-model="project.solarPlant.solarPlantType"
            :options="getEnumValues('SolarPlantType')"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('islandOperationIntended')"
          label-for="islandOperationIntended"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('islandOperationIntended')"
            v-model="project.solarPlant.islandOperationIntended"
            :options="optionsYesNo"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="yesNoColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('motorStartup')"
          label-for="motorStartup"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('motorStartup')"
            v-model="project.solarPlant.motorStartup"
            :options="optionsYesNo"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="yesNoColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('networkSupplyType')"
          label-for="networkSupplyType"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('networkSupplyType')"
            v-model="project.solarPlant.networkSupplyType"
            :options="getEnumValues('NetworkSupplyType')"
            :show-labels="false"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{ $t(option) }}</template>
            <template slot="option" slot-scope="{ option }">
              {{ $t(option) }}
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('measuringConcept')"
          label-for="measuringConcept"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <b-form-input
            v-model="project.solarPlant.measuringConcept"
            type="text"
            :placeholder="$t('measuringConcept')"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import {
  formatSolarPanelsOverallPower,
  formatSolarPanelPower,
  formatBatteryCapacity,
} from '@/helpers/projects';
import {
  formatInverterApparentPowerOverall,
  formatInverterNominalPowerOverall,
  formatInverter1Power,
  formatInverter2Power,
} from '@/helpers/inverter';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor, getDocumentStateColor, getProjectStateColor } from '@/helpers/colors';
import InputNumber from 'primevue/inputnumber';

export default {
  components: {
    Multiselect,
    InputNumber,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      filteredSolarPanels: [],
      filteredInverters: [],
      filteredBatteries: [],
      filteredEnergyFlowFirectionSensors: [],
      filteredWallboxes: [],
    };
  },
  computed: {
    ...mapGetters([
      'getSolarPanels',
      'getInverters',
      'getBatteries',
      'getEnergyFlowDirectionSensors',
      'getWallboxes',
      'getNetworkSupplyManagementStates',
      'getNetworkSupplyManagementPermanentRestrictionStates',
      'getNetworkSupplyManagementXOverStates',
      'getNetOutputStates',
      'getStorageCouplingStates',
      'getElectricMeterMountingStates',
      'getHouseFuseStates',
      'getEnumValues',
    ]),
    networkSupplyManagementPermanentRestrictionStateIsDisabled() {
      return this.project.solarPlant.networkSupplyManagementState === 'PERMANENT_RESTRICTION'
        ? false
        : true;
    },
    solarPanelsOverallPower() {
      return formatSolarPanelsOverallPower(this.project);
    },
    solarPanelPower() {
      return formatSolarPanelPower(this.project);
    },
    inverterApparentPowerOverall() {
      return formatInverterApparentPowerOverall(this.project);
    },
    InverterNominalPowerOverall() {
      return formatInverterNominalPowerOverall(this.project);
    },
    inverter1Power() {
      return formatInverter1Power(this.project);
    },
    inverter2Power() {
      return formatInverter2Power(this.project);
    },
    batteryCapacity() {
      return formatBatteryCapacity(this.project);
    },
    optionsYesNo() {
      return yesNo();
    },
    /**
     * Bei der Zählerzusammenlegung/Zählerabmeldung bleibt der erste Zähler immer bestehen. Es können nur Zähler 2 und 3 ausgewählt werden.
     */
    projectElectricMeters() {
      const electricMeters = [];
      if (
        this.project.customer.electricMeterNumber2 &&
        this.project.customer.electricMeterNumber2 !== ''
      ) {
        electricMeters.push(this.project.customer.electricMeterNumber2);
      }
      if (
        this.project.customer.electricMeterNumber3 &&
        this.project.customer.electricMeterNumber3 !== ''
      ) {
        electricMeters.push(this.project.customer.electricMeterNumber3);
      }
      return electricMeters;
    },
    /**
     * @todo DRY
     */
    invalidFeedbackNA() {
      return 'Voraussetzung NA';
    },
  },
  methods: {
    ...mapActions([
      'fetchInverters',
      'fetchBatteries',
      'fetchEnergyFlowDirectionSensors',
      'fetchWallboxes',
      'fetchNetworkSupplyManagementStates',
      'fetchNetworkSupplyManagementPermanentRestrictionStates',
      'fetchNetworkSupplyManagementXOverStates',
      'fetchNetOutputStates',
      'fetchStorageCouplingStates',
      'fetchElectricMeterMountingStates',
      'fetchHouseFuseStates',
      'fetchEnumValues',
    ]),
    onNetworkSupplyManagementState(state) {
      if (state === 'FRE' || state === 'NONE') {
        this.project.solarPlant.networkSupplyManagementPermanentRestrictionState = null;
      }
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    onSolarPanelSearch(query) {
      this.filteredSolarPanels = [];
      this.getSolarPanels.map((solarPanel) => {
        if (
          solarPanel.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          solarPanel.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredSolarPanels.push(solarPanel);
        }
      });
    },
    onInverterSearch(query) {
      this.filteredInverters = [];
      this.getInverters.map((inverter) => {
        if (
          inverter.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          inverter.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredInverters.push(inverter);
        }
      });
    },
    onBatterySearch(query) {
      this.filteredBatteries = [];
      this.getBatteries.map((battery) => {
        if (
          battery.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          battery.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredBatteries.push(battery);
        }
      });
    },
    onWallboxSearch(query) {
      this.filteredWallboxes = [];
      this.getWallboxes.map((wallbox) => {
        if (
          wallbox.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          wallbox.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredWallboxes.push(wallbox);
        }
      });
    },
    onEnergyFlowDirectionSensorSearch(query) {
      this.filteredEnergyFlowFirectionSensors = [];
      this.getEnergyFlowDirectionSensors.map((energyFlowDirectionSensor) => {
        if (
          energyFlowDirectionSensor.manufacturer.toLowerCase().indexOf(query) >= 0 ||
          energyFlowDirectionSensor.type.toLowerCase().indexOf(query) >= 0
        ) {
          this.filteredEnergyFlowFirectionSensors.push(energyFlowDirectionSensor);
        }
      });
    },
    /**
     * check requirementsValidState to show mark input fields
     * @todo DRY
     */
    requirementsValidState(field) {
      if (!this.project.netRegistrationRequirements) return false;

      const requirement = this.project.netRegistrationRequirements.find(
        (requirement) => requirement.name === field
      );
      return requirement && requirement.fullfilledState === 'YES' ? true : false;
    },
    async setup() {
      await this.fetchInverters();
      await this.fetchBatteries();
      await this.fetchEnergyFlowDirectionSensors();
      await this.fetchWallboxes();
      await this.fetchNetworkSupplyManagementStates();
      await this.fetchNetworkSupplyManagementPermanentRestrictionStates();
      await this.fetchNetworkSupplyManagementXOverStates();
      await this.fetchNetOutputStates();
      await this.fetchStorageCouplingStates();
      await this.fetchElectricMeterMountingStates();
      await this.fetchHouseFuseStates();
      await this.fetchEnumValues('SolarPlantType');
      await this.fetchEnumValues('NetworkSupplyType');
      this.filteredSolarPanels = this.getSolarPanels;
      this.filteredInverters = this.getInverters;
      this.filteredBatteries = this.getBatteries;
      this.filteredWallboxes = this.getWallboxes;
      this.filteredEnergyFlowFirectionSensors = this.getEnergyFlowDirectionSensors;
    },
  },
  async mounted() {
    await this.setup();
  },
};
</script>
