<template>
  <div>
    <AccordionCard :state="cards.hakLocationState">
      <template v-slot:header>
        {{ $t('hakLocationState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('hakLocationState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.hakLocationState"
            :options="getEnumValues('HouseLocationState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.hakLockedState">
      <template v-slot:header>
        {{ $t('hakLockedState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('hakLockedState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.hakLockedState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.serviceReport.hakLockedState === 'NO'">
        <div class="flex-column name">
          {{ $t('hakAndZsSameRoomAttachments') }}
        </div>
        <div class="flex-column select">
          <p>{{ $t('upload_addtional_images') }}</p>
        </div>
      </div>
      <div class="flex-row" v-if="project.serviceReport.hakLockedState === 'NO'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serrviceHakLockedAttachments"
            attachmentType="serrviceHakLockedAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserrviceHakLockedAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serrviceHakLockedAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserrviceHakLockedAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.hakToZsDistance">
      <template v-slot:header> {{ $t('hakToZsDistance') }}</template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('hakToZsDistance') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.serviceReport.hakToZsDistance" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.hakAndZsSameRoomState">
      <template v-slot:header>
        {{ $t('hakAndZsSameRoomState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('hakAndZsSameRoomState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.hakAndZsSameRoomState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceHakAttachments">
      <template v-slot:header>
        {{ $t('hakAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('hakAttachments') }}
        </div>
        <div class="flex-column select">
          <p>{{ $t('upload_addtional_images') }}</p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[19].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceHakAttachments"
            attachmentType="serviceHakAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceHakAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceHakAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceHakAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceHakAndZsSameRoomAttachments">
      <template v-slot:header>
        {{ $t('serviceHakAndZsSameRoomAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceHakAndZsSameRoomAttachments') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceHakAndZsSameRoomAttachments"
            attachmentType="serviceHakAndZsSameRoomAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isserviceHakAndZsSameRoomAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceHakAndZsSameRoomAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isserviceHakAndZsSameRoomAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    // Textarea,
    DropzoneUpload,
    AttachmentGallery,
    InputText,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isHakAndZsSameRoomAttachmentsUploadComplete: false,
      isHakAttachmentsUploadComplete: false,
      isHakOptionalAttachmentsUploadComplete: false,
      isserrviceHakLockedAttachmentsUploadComplete: false,
      isserviceHakAttachmentsUploadComplete: false,
      isserviceHakAndZsSameRoomAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isHakAndZsSameRoomAttachmentsUploadComplete = true;
          this.isHakAttachmentsUploadComplete = true;
          this.isHakOptionalAttachmentsUploadComplete = true;
          this.isserrviceHakLockedAttachmentsUploadComplete = true;
          this.isserviceHakAndZsSameRoomAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
