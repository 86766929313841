<template>
  <div class="project-material" v-if="project">
    <b-row class="mb-4">
      <b-col lg="6" md="6" sm="12">
        <legend>
          {{ $t('generate_consumables_list') }}
        </legend>
        <b-row>
          <b-col lg="6" md="5" sm="5">
            <AttachmentList
              v-if="project.consumablesListAttachmentsBuildState === 'FINISHED'"
              :attachments="project.consumablesListAttachments"
              attachmentType="consumablesListAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </b-col>
          <b-col lg="6" md="5" sm="5">
            <router-link
              class="btn btn-success pull-right"
              :to="{
                name: 'ConsumablesListPrintPage',
                params: { projectNumber: project.number },
              }"
              target="_blank"
            >
              <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
            </router-link>
            <ButtonWait
              :startCallback="startConsumablesListBuild"
              :pollCallback="updateConsumablesListBuildState"
              class="pull-right mr-4"
            >
              <template v-slot:buttonText>
                {{ $t('generate_consumables_list') }}
              </template>
            </ButtonWait>
          </b-col>
        </b-row>

        <legend>
          {{ $t('generate_offer') }}
        </legend>
        <b-row>
          <b-col lg="6" md="5" sm="5">
            <AttachmentList
              v-if="project.projectOfferAttachmentsBuildState === 'FINISHED'"
              :attachments="project.projectOfferAttachments"
              attachmentType="projectOfferAttachments"
              v-on:attachment-list:delete="onDeleteProjectAttachment"
            ></AttachmentList>
          </b-col>
          <b-col lg="6" md="5" sm="5">
            <router-link
              class="btn btn-success pull-right"
              :to="{
                name: 'ProjectOfferPrintPage',
                params: { projectNumber: project.number },
              }"
              target="_blank"
            >
              <i class="fa fa-file mr-2"></i>{{ $t('preview') }}
            </router-link>
            <ButtonWait
              :startCallback="startProjectOfferBuild"
              :pollCallback="updateProjectOfferBuildState"
              class="pull-right mr-4"
            >
              <template v-slot:buttonText>
                {{ $t('generate_offer') }}
              </template>
            </ButtonWait>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="6" md="6" sm="12">
        <legend>
          {{ $t('item') }}
        </legend>

        <b-form-group
          horizontal
          :label="$t('consumablesOrderedState')"
          label-for="consumablesOrderedState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('consumablesOrderedState')"
            v-model="project.consumablesOrderedState"
            :options="yesNo"
            :show-labels="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="getYesNoStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('consumablesInStockState')"
          label-for="consumablesInStockState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('consumablesInStockState')"
            v-model="project.consumablesInStockState"
            :options="yesNo"
            :show-labels="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="getYesNoStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('consumablesDeliveredAt')"
          label-for="consumablesDeliveredAt"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <Calendar
            v-model="consumablesDeliveredAt"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            :placeholder="$t('date')"
            :showWeek="true"
            :yearNavigator="true"
            :monthNavigator="true"
          >
          </Calendar>
        </b-form-group>

        <b-form-group
          horizontal
          :label="$t('itemPlanningFinishedState')"
          label-for="itemPlanningFinishedState"
          label-class="text-md-right"
          :label-cols="4"
          breakpoint="md"
        >
          <multiselect
            :placeholder="$t('itemPlanningFinishedState')"
            v-model="project.itemPlanningFinishedState"
            :options="yesNo"
            :show-labels="false"
            :allow-empty="false"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><b-badge :variant="getYesNoStateColor(option)">{{ $t(option) }}</b-badge></template
            >
            <template slot="option" slot-scope="{ option }">
              <b-badge :variant="getYesNoStateColor(option)">
                {{ $t(option) }}
              </b-badge></template
            >
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6" md="6" sm="12" v-if="project.items">
        <hr />
        <ItemSelect
          v-on:item-select:add-item="onAddItemToProject"
          v-on:item-select:remove-item="onRemoveItemFromProject"
        />
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <hr />
        <ProjectItems
          :items="items"
          v-on:item-select:remove-all-items="onRemoveAllItemsFromProject"
          :project="project"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectItems from '@/views/Projects/ProjectItems';
import ItemSelect from '@/views/Projects/ItemSelect';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor } from '@/helpers/colors';
import ButtonWait from '@/components/ButtonWait.vue';
import Multiselect from 'vue-multiselect';
import Calendar from 'primevue/calendar';
import AttachmentList from '@/components/AttachmentList';

export default {
  components: { ProjectItems, ItemSelect, ButtonWait, Multiselect, Calendar, AttachmentList },
  props: {
    project: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      const itemType = 'STANDARD';
      return this.project.items
        ? this.project.items.filter((item) => item.item.type === itemType)
        : [];
    },
    consumablesDeliveredAt: {
      get() {
        return this.$calendarFormat(this.project.consumablesDeliveredAt);
      },
      set(value) {
        this.project.consumablesDeliveredAt = value;
      },
    },
    yesNo,
  },
  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'buildConsumablesList',
      'fetchConsumablesListAttachmentsBuildState',
      'fetchProjectOfferAttachmentsBuildState',
      'buildProjectOffer',
    ]),

    async onDeleteProjectAttachment(event) {
      // await this.save();
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },

    /** */
    onAddItemToProject(itemData) {
      // console.log('onAddItemToProject()', parseInt(itemData.addRemoveCount), itemData);
      const addRemoveCount = parseInt(itemData.addRemoveCount);
      const item = this.project.items.find((item) => item.item._id == itemData._id);
      if (item) {
        item.count += addRemoveCount;
      } else {
        const newItem = {
          count: addRemoveCount,
          item: itemData,
        };
        this.project.items.push(newItem);
      }
    },

    /** */
    onRemoveItemFromProject(itemData) {
      const addRemoveCount = parseInt(itemData.addRemoveCount);
      const item = this.project.items.find((item) => item.item._id == itemData._id);
      if (item) {
        item.count -= addRemoveCount;
        if (item.count <= 0) {
          const index = this.project.items.findIndex((item) => item.item._id == itemData._id);
          // console.log(index);
          this.project.items.splice(index, 1);
        }
      }
    },
    async onRemoveAllItemsFromProject() {
      this.project.items = [];
    },

    /** */
    calculateItemTotalCost() {
      if (!this.project.items) return 0;
      const totalCost = this.project.items.reduce((acc, curr) => {
        return acc + curr.count * curr.item.price;
      }, 0);

      return totalCost;
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateConsumablesListBuildState() {
      this.fetchConsumablesListAttachmentsBuildState(this.project.number);
      return this.project.consumablesListAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startConsumablesListBuild() {
      if (this.project) {
        await this.buildConsumablesList(this.project.id);
      }
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateProjectOfferBuildState() {
      this.fetchProjectOfferAttachmentsBuildState(this.project.number);
      return this.project.projectOfferAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startProjectOfferBuild() {
      if (this.project) {
        await this.buildProjectOffer(this.project.id);
      }
    },

    getYesNoStateColor,
  },

  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>
