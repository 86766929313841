<template>
  <div>
    <AccordionCard :state="cards.bricksConditionState">
      <template v-slot:header>
        {{ $t('bricksConditionState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bricksConditionState') }}
        </div>
        <div class="flex-column select">
          {{ $t('bricksConditionState_description') }}
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.bricksConditionState"
            :options="getEnumValues('BricksConditionState')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bricksFrontAttachments">
      <template v-slot:header>
        {{ $t('bricksFrontAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bricksFrontAttachments') }}
        </div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[14].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.bricksFrontAttachments"
            attachmentType="bricksFrontAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isBricksFrontAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="bricksFrontAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isBricksFrontAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bricksBackAttachments">
      <template v-slot:header>
        {{ $t('bricksBackAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('bricksBackAttachments') }}
        </div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[15].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.bricksBackAttachments"
            attachmentType="bricksBackAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isBricksBackAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="bricksBackAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isBricksBackAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickManufacturerAndType">
      <template v-slot:header>
        {{ $t('bricks') }} {{ $t('brickManufacturer') }} / {{ $t('brickType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('brickManufacturer') }} / {{ $t('brickType') }}</div>
        <div class="flex-column select">
          <multiselect
            :placeholder="$t('brickManufacturer') + '/' + $t('brickType')"
            v-model="project.measurement.pitchedRoof.brick"
            :options="getBricks"
            :show-labels="true"
            track-by="manufacturer"
            label="manufacturer"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.manufacturer }}, {{ option.type }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.manufacturer }}, {{ option.type }}
            </template>
          </multiselect>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickManufacturer') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.brickManufacturer" />
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickType') }}
        </div>
        <div class="flex-column select">
          <InputText type="text" v-model="project.measurement.pitchedRoof.brickType" />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.tinBricksRequiredState">
      <template v-slot:header>
        {{ $t('tinBricksRequiredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('tinBricksRequiredState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.pitchedRoof.tinBricksRequiredState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bricksOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.bricksOptionalAttachments"
            attachmentType="bricksOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isBricksOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="bricksOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isBricksOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.bricksNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.pitchedRoof.bricksNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Multiselect from 'vue-multiselect';

export default {
  name: 'LocationAndCablePath',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
    InputText,
    Multiselect,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isBricksFrontAttachmentsUploadComplete: false,
      isBricksBackAttachmentsUploadComplete: false,
      isBricksOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages', 'getBricks']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'fetchBricks']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    await this.fetchBricks();
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isBricksFrontAttachmentsUploadComplete = true;
          this.isBricksBackAttachmentsUploadComplete = true;
          this.isBricksOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
