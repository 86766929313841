<template>
  <div>
    <AccordionCard :state="cards.brickAndUkAssemblyType">
      <template v-slot:header>
        {{ $t('brickAndUkAssemblyType') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkAssemblyType') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.brickAndUkAssemblyType"
            :options="getEnumValues('BrickAndUkAssemblyType')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceBrickCoverWidthAttachments">
      <template v-slot:header>
        {{ $t('brickCoverWidthAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('brickCoverWidthAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[29].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceBrickCoverWidthAttachments"
            attachmentType="serviceBrickCoverWidthAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isbrickCoverWidthAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceBrickCoverWidthAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isbrickCoverWidthAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCorrectBrickHandlingAttachments">
      <template v-slot:header>
        {{ $t('correctBrickHandlingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('correctBrickHandlingAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[42].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCorrectBrickHandlingAttachments"
            attachmentType="serviceCorrectBrickHandlingAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscorrectBrickHandlingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCorrectBrickHandlingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscorrectBrickHandlingAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndUkA2State">
      <template v-slot:header>
        {{ $t('brickAndUkA2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkA2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.brickAndUkA2State"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndUkBState">
      <template v-slot:header>
        {{ $t('brickAndUkBState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('brickAndUkBState') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.brickAndUkBState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceBrickAndSubstructureAttachments">
      <template v-slot:header>
        {{ $t('brickAndSubstructureAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('brickAndSubstructureAttachments') }}</div>
        =
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[38].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceBrickAndSubstructureAttachments"
            attachmentType="serviceBrickAndSubstructureAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isbrickAndSubstructureAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceBrickAndSubstructureAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isbrickAndSubstructureAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCableAndPlugAttachedToRailStateAttachments">
      <template v-slot:header>
        {{ $t('serviceCableAndPlugAttachedToRailStateAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceCableAndPlugAttachedToRailStateAttachments') }}
        </div>
        <!-- <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[38].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div> -->
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCableAndPlugAttachedToRailStateAttachments"
            attachmentType="serviceCableAndPlugAttachedToRailStateAttachments"
            :deletable="true"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isServiceCableAndPlugAttachedToRailStateAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCableAndPlugAttachedToRailStateAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isServiceCableAndPlugAttachedToRailStateAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.railsEarthed">
      <template v-slot:header>
        {{ $t('railsEarthed') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('railsEarthed') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.railsEarthed"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCorrectCableInputAttachments">
      <template v-slot:header>
        {{ $t('correctCableInputAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('correctCableInputAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('correctCableInputAttachments_description') }}
          <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[43].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCorrectCableInputAttachments"
            attachmentType="serviceCorrectCableInputAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscorrectCableInputAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCorrectCableInputAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscorrectCableInputAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceRailOverlappingTerminationFittingAttachments">
      <template v-slot:header>
        {{ $t('serviceRailOverlappingTerminationFittingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('serviceRailOverlappingTerminationFittingAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('pictureWithInchRule') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceRailOverlappingTerminationFittingAttachments"
            attachmentType="serviceRailOverlappingTerminationFittingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isServiceRailOverlappingTerminationFittingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceRailOverlappingTerminationFittingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isServiceRailOverlappingTerminationFittingAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceProtectedUnderBrickCableRoutingAttachments">
      <template v-slot:header>
        {{ $t('protectedUnderBrickCableRoutingAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('protectedUnderBrickCableRoutingAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('protectedUnderBrickCableRoutingAttachments_description') }}
          <br />
          {{ $t('templateAttachments') }}:
          <AttachmentGallery
            :attachments="getReportTemplateImages[39].templateAttachments"
            :deletable="false"
            :sortByName="false"
            :showFilename="false"
          ></AttachmentGallery>
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceProtectedUnderBrickCableRoutingAttachments"
            attachmentType="serviceProtectedUnderBrickCableRoutingAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isprotectedUnderBrickCableRoutingAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceProtectedUnderBrickCableRoutingAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isprotectedUnderBrickCableRoutingAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceBrickAndSubstructureOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceBrickAndSubstructureOptionalAttachments"
            attachmentType="serviceBrickAndSubstructureOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isServiceBrickAndSubstructureOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceBrickAndSubstructureOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="
              isServiceBrickAndSubstructureOptionalAttachmentsUploadComplete = false
            "
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.brickAndUkOptionalNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.serviceReport.brickAndUkOptionalNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    SelectButton,
    Textarea,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isbrickCoverWidthAttachmentsUploadComplete: false,
      iscorrectBrickHandlingAttachmentsUploadComplete: false,
      isbrickAndSubstructureAttachmentsUploadComplete: false,
      isroofHookWithEarthingAttachmentsUploadComplete: false,
      iscorrectCableInputAttachmentsUploadComplete: false,
      isprotectedUnderBrickCableRoutingAttachmentsUploadComplete: false,
      isServiceCableAndPlugAttachedToRailStateAttachmentsUploadComplete: false,
      isServiceRailOverlappingTerminationFittingAttachmentsUploadComplete: false,
      isServiceBrickAndSubstructureOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isbrickCoverWidthAttachmentsUploadComplete = true;
          this.iscorrectBrickHandlingAttachmentsUploadComplete = true;
          this.isbrickAndSubstructureAttachmentsUploadComplete = true;
          this.isroofHookWithEarthingAttachmentsUploadComplete = true;
          this.iscorrectCableInputAttachmentsUploadComplete = true;
          this.isprotectedUnderBrickCableRoutingAttachmentsUploadComplete = true;
          this.isServiceCableAndPlugAttachedToRailStateAttachmentsUploadComplete = true;
          this.isServiceRailOverlappingTerminationFittingAttachmentsUploadComplete = true;
          this.isServiceBrickAndSubstructureOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
