<template>
  <div>
    <AccordionCard :state="cards.wallboxRequiredState">
      <template v-slot:header>
        {{ $t('wallboxRequiredState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('wallboxRequiredState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.wallboxRequiredState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.heavyCurrentPresentState">
      <template v-slot:header>
        {{ $t('heavyCurrentPresentState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('heavyCurrentPresentState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.heavyCurrentPresentState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.ac.heavyCurrentPresentState === 'YES'">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <p>{{ $t('upload_addtional_images') }}</p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[27].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row" v-if="project.measurement.ac.heavyCurrentPresentState === 'YES'">
        <div class="flex-column name">{{ $t('heavyCurrentPresentAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.heavyCurrentPresentAttachments"
            attachmentType="heavyCurrentPresentAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isHeavyCurrentPresentAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="heavyCurrentPresentAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isHeavyCurrentPresentAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.wallboxLocationAttachments">
      <template v-slot:header>
        {{ $t('wallboxLocationAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('wallboxLocationAttachments') }}
        </div>
        <div class="flex-column select">
          <p>{{ $t('upload_addtional_images') }}</p>
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[28].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.wallboxLocationAttachments"
            attachmentType="wallboxLocationAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isWallboxLocationAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="wallboxLocationAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isWallboxLocationAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.wallboxWirelessInstallationPossibleState">
      <template v-slot:header>
        {{ $t('wallboxWirelessInstallationPossibleState') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('earthingPresentState') }}</div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.measurement.ac.wallboxWirelessInstallationPossibleState"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>

      <div
        class="flex-row"
        v-if="project.measurement.ac.wallboxWirelessInstallationPossibleState === 'NO'"
      >
        <div class="flex-column name">{{ $t('why_not') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.wallboxWirelessInstallationPossibleNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.wallboxCablePathNotes">
      <template v-slot:header>
        {{ $t('wallboxCablePathNotes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('wallboxCablePathNotes') }}</div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.wallboxCablePathNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.wallboxOptionalAttachments">
      <template v-slot:header>
        {{ $t('optionalAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optionalAttachments') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.wallboxOptionalAttachments"
            attachmentType="wallboxOptionalAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="isWallboxOptionalAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="wallboxOptionalAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="isWallboxOptionalAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.wallboxNotes">
      <template v-slot:header>
        {{ $t('optional_notes') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('optional_notes') }}
        </div>
        <div class="flex-column select">
          {{ $t('optional_notes_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.ac.wallboxNotes"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';

export default {
  name: 'InternetConnection',
  components: {
    AccordionCard,
    SelectButton,
    Textarea,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isHeavyCurrentPresentAttachmentsUploadComplete: false,
      isWallboxLocationAttachmentsUploadComplete: false,
      isWallboxOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues', 'getReportTemplateImages']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isHeavyCurrentPresentAttachmentsUploadComplete = true;
          this.isWallboxLocationAttachmentsUploadComplete = true;
          this.isWallboxOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
