<template>
  <div>
    <AccordionCard :state="cards.customerQuestions">
      <template v-slot:header>
        {{ $t('customerQuestions') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('customerQuestions') }}
        </div>
        <div class="flex-column select">
          {{ $t('customerQuestions_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.customer.customerQuestions"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.customerWorking">
      <template v-slot:header>
        {{ $t('customerWorking') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('customerWorking') }}
        </div>
        <div class="flex-column select">
          {{ $t('customerWorking_description') }}
        </div>
      </div>
      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <Textarea
            v-model="project.measurement.customer.customerWorking"
            :autoResize="true"
            rows="5"
            cols="30"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.customerSignature">
      <template v-slot:header>
        {{ $t('customerSignature') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('customerSignature') }}</div>
        <div class="flex-column select">
          <Signature
            v-if="project"
            :onSaveCallback="saveSignature"
            type="customerSignature"
            :data="project.measurement.customer.customerSignature"
          />
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import Textarea from 'primevue/textarea';
import Signature from '@/components/Signature';

export default {
  name: 'Customer',
  components: {
    AccordionCard,
    Textarea,
    Signature,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isHeavyCurrentPresentAttachmentsUploadComplete: false,
      isWallboxLocationAttachmentsUploadComplete: false,
      isWallboxOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment', 'saveReportSignature']),

    /** */
    saveSignature(data, type) {
      console.log(type, data);
      this.project.measurement.customer[type] = data;
      this.saveReportSignature({
        projectId: this.project.id,
        payload: data,
        signatureType: type,
      });
    },

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isHeavyCurrentPresentAttachmentsUploadComplete = true;
          this.isWallboxLocationAttachmentsUploadComplete = true;
          this.isWallboxOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
