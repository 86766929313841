<template>
  <div>
    <div class="empty" v-if="attachments && attachments.length === 0">
      <small>Noch keine Dateien vorhanden!</small>
    </div>
    <div v-if="attachments">
      <FsLightbox
        :toggler="toggler"
        :sources="imageReduceToPath(attachments)"
        type="image"
        :slide="slide"
        :key="attachmentCount"
      />
    </div>
    <div class="attachment-grid-container">
      <div
        class="grid-item"
        v-for="(attachment, index) in preparedAttachments"
        :key="attachment.id"
      >
        <div class="thumbnail">
          <a
            v-if="isImage(attachment.path)"
            class="image"
            :href="sanitizePath(attachment.path)"
            target="_blank"
            @click.prevent="onOpenLightboxOnSlide(index + 1)"
          >
            <img :src="thumbnail(attachment)" loading="lazy" />
          </a>
          <a v-else class="filetype-icon" :href="sanitizePath(attachment.path)" target="_blank">
            <img :src="fileImage(attachment.filename)" loading="lazy" />
          </a>
        </div>
        <div class="file-title" v-if="showFilename">
          <small>
            <a class="text" @click.prevent="openUrl(attachment)">{{
              attachment.displayFilename
            }}</a>
          </small>
        </div>
        <div class="file-meta" v-if="deletable">
          <b-button
            @click.prevent="onDeleteAttachment(attachment.id)"
            :disabled="buttonsDisabled"
            variant="outline-danger"
          >
            <i class="fa fa-sm fa-times"></i>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FsLightbox from 'fslightbox-vue';
import { mapActions } from 'vuex';

export default {
  name: 'AttachmentGallery',
  components: {
    FsLightbox,
  },
  props: {
    attachments: Array,
    attachmentType: { type: String, default: null },
    buttonsDisabled: { default: false },
    signed: { default: true },
    deletable: { default: true },
    sortbyName: { default: false },
    showFilename: { default: true },
  },
  data() {
    return {
      imagePath: '/img/filetypes/',
      toggler: false,
      slide: 1,
      attachmentCount: 0,
    };
  },
  computed: {
    preparedAttachments() {
      if (!this.attachments) return [];

      // if (this.sortbyName) {
      //   return this.sortedAttachments;
      // }
      // console.log('unsorted Attachments');

      return this.attachments;
    },
    sortedAttachments() {
      console.log('sortedAttachments');
      const attachments = this.attachments;

      return attachments.sort((a, b) => {
        if (
          a.displayFilename &&
          b.displayFilename &&
          a.displayFilename.toUpperCase() < b.displayFilename.toUpperCase()
        ) {
          return -1;
        }
        if (
          a.displayFilename &&
          b.displayFilename &&
          a.displayFilename.toUpperCase() > b.displayFilename.toUpperCase()
        ) {
          return 1;
        }
        return 0;
      });
    },
    unSortedAttachments() {
      return this.attachments;
    },
  },
  watch: {
    /**
     * Trigger remount of Lightbox
     */
    attachments(oldVal, newVal) {
      if (newVal) {
        this.attachmentCount = newVal.length;
      }
    },
  },
  methods: {
    ...mapActions(['fetchSignedUrl']),
    async openSignedUrl(attachment, windowReference) {
      const url = await this.fetchSignedUrl({
        attachmentId: attachment.id,
        downloadType: 'inline',
      });
      // console.log(url);
      // window.open(url);
      windowReference.location = url;
    },
    openUrl(attachment) {
      let windowReference = window.open('about:blank', '_blank');
      if (this.signed) {
        this.openSignedUrl(attachment, windowReference);
      } else {
        // window.open(attachment.path);
        windowReference.location = attachment.path;
      }
    },
    isImage(filename) {
      const imageTypes = ['jpg', 'JPG', 'jpeg', 'png', 'gif', 'jfif'];
      const fileType = filename.split('.').pop();
      return imageTypes.includes(fileType);
    },
    /**
     * Reduce an array of image objects to an array the objects path property
     * @todo DRY
     */
    imageReduceToPath(images) {
      return images.reduce((result, image) => {
        // if (this.isImage(image.path)) {
        result.push(this.sanitizePath(image.path));
        // }
        return result;
      }, []);
    },
    onDeleteAttachment(attachmentId) {
      this.$emit('attachment-list:delete', {
        attachmentId: attachmentId,
        attachmentType: this.attachmentType,
      });
    },
    fileExtension(filename) {
      const filetypes = [
        'xml',
        'zip',
        'xls',
        'xlsx',
        'xlsm',
        'doc',
        'docx',
        'pdf',
        'jpg',
        'png',
        'ppt',
        'pptx',
        'csv',
      ];
      const fileType = filename.split('.').pop();
      return filetypes.includes(fileType) ? fileType : 'file';
    },
    fileImage(filename) {
      return this.imagePath + this.fileExtension(filename) + '.svg';
    },
    onOpenLightboxOnSlide(number) {
      this.slide = number;
      this.toggler = !this.toggler;
    },

    /**
     * some paths come without scheme, prefix with https://
     */
    sanitizePath(path) {
      if (path.indexOf('https://') != 0) {
        return 'https://' + path;
      }
      return path;
    },

    /**
     * return the thumbnail if available
     */
    thumbnail(attachment) {
      if (attachment.thumbnail) {
        return this.sanitizePath(attachment.thumbnail);
      }
      return this.sanitizePath(attachment.path);
    },
  },
};
</script>

<style scoped>
.attachment-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(100px, 300px)); */
  row-gap: 0.5em;
  margin-top: 0.5em;
  column-gap: 0.5em;
  vertical-align: middle;
  border: 0px solid red;
}
.grid-item {
  border: 1px solid #eee;
  width: 100%;
  padding: 0.4em 0.4em 0 0.4em;
  position: relative;
  text-align: left;
}
.thumbnail {
  position: relative;
  min-height: 80px;
  /* max-height: 250px; */
}
.thumbnail img {
  width: 100%;
  max-height: 80px;
  object-fit: contain;
}
.file-title {
  word-break: break-all;
}
.file-meta {
  position: absolute;
  top: 0;
  right: 0;
}
.file-meta button {
  padding: 0 3px;
  top: 0px;
  position: relative;
  height: 23px;
  width: 23px;
  background: #eee;
}
.empty {
  margin-top: 0.5em;
}

@media (max-width: 768px) {
  .attachment-grid-container {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
  .file-title {
    display: none;
  }
  .file-meta {
    display: none;
  }
  .thumbnail {
    height: 100%;
    width: 100%;
  }
  .thumbnail img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
