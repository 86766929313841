import { render, staticRenderFns } from "./Wallbox.vue?vue&type=template&id=50190282&scoped=true"
import script from "./Wallbox.vue?vue&type=script&lang=js"
export * from "./Wallbox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50190282",
  null
  
)

export default component.exports