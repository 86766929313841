<template>
  <div>
    <AccordionCard :state="cards.cableManagementC2State">
      <template v-slot:header>
        {{ $t('cableManagementC2State') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">
          {{ $t('cableManagementC2State') }}
        </div>
        <div class="flex-column select">
          <SelectButton
            v-model="project.serviceReport.cableManagementC2State"
            :options="getEnumValues('YesNo')"
          >
            <template #option="props">
              {{ $t(props.option) }}
            </template>
          </SelectButton>
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCableManagementAttachments">
      <template v-slot:header>
        {{ $t('cableManagementAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableManagementAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[2].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name"></div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCableManagementAttachments"
            attachmentType="serviceCableManagementAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscableManagementAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCableManagementAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscableManagementAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCard :state="cards.serviceCableAlongFacadeAttachments">
      <template v-slot:header>
        {{ $t('cableAlongFacadeAttachments') }}
      </template>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableAlongFacadeAttachments') }}</div>
        <div class="flex-column select">
          <div>
            {{ $t('templateAttachments') }}:
            <AttachmentGallery
              :attachments="getReportTemplateImages[44].templateAttachments"
              :deletable="false"
              :sortByName="false"
              :showFilename="false"
            ></AttachmentGallery>
          </div>
        </div>
      </div>

      <div class="flex-row">
        <div class="flex-column name">{{ $t('cableAlongFacadeAttachments') }}</div>
        <div class="flex-column select">
          <AttachmentGallery
            :attachments="project.serviceCableAlongFacadeAttachments"
            attachmentType="serviceCableAlongFacadeAttachments"
            v-on:attachment-list:delete="onDeleteProjectAttachment"
            :sortByName="false"
          ></AttachmentGallery>
          <DropzoneUpload
            class="mt-2"
            :complete="iscableAlongFacadeAttachmentsUploadComplete"
            :parentId="project.id"
            parentType="serviceCableAlongFacadeAttachments"
            :uploadCallback="onUploadProjectAttachment"
            v-on:dropzone-upload:reset="iscableAlongFacadeAttachmentsUploadComplete = false"
            acceptedFileTypes="image/*"
          />
        </div>
      </div>
    </AccordionCard>

    <AccordionCardOptionalInput
      :card="cards.cableManagementOptional"
      :project="project"
      notesType="cableManagementNotes"
      :attachments="project.serviceCableManagementOptionalAttachments"
      attachmentType="serviceCableManagementOptionalAttachments"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AccordionCardOptionalInput from '@/components/AccordionCardOptionalInput';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import SelectButton from 'primevue/selectbutton';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    AccordionCardOptionalInput,
    SelectButton,
  },
  props: {
    project: { type: Object },
    cards: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      iscableManagementAttachmentsUploadComplete: false,
      iscableAlongFacadeAttachmentsUploadComplete: false,
      iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete: false,
    };
  },
  computed: {
    ...mapGetters(['getEnumValues', 'getReportTemplateImages']),
    isPitchedRoof() {
      return this.project.measurement && this.project.measurement.roofType === 'PITCHED_ROOF';
    },
  },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.iscableManagementAttachmentsUploadComplete = true;
          this.iscableAlongFacadeAttachmentsUploadComplete = true;
          this.iscableBetweenMateboxAndElectricMeterAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped></style>
