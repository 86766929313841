<template>
  <AccordionCard :state="card">
    <template v-slot:header>
      {{ $t('optionalNotesAndAttachments') }}
    </template>

    <div class="flex-row">
      <div class="flex-column name">
        {{ $t('optional_notes') }}
      </div>
      <div class="flex-column select">
        {{ $t('optional_notes_description') }}
      </div>
    </div>
    <div class="flex-row">
      <div class="flex-column name"></div>
      <div class="flex-column select">
        <Textarea v-model="project.enpalReport[notesType]" :autoResize="true" rows="5" cols="30" />
      </div>
    </div>

    <div class="flex-row">
      <div class="flex-column name">{{ $t('optionalAttachments') }}</div>
      <div class="flex-column select">
        <AttachmentGallery
          :attachments="attachments"
          :attachmentType="attachmentType"
          v-on:attachment-list:delete="onDeleteProjectAttachment"
          :sortByName="false"
        ></AttachmentGallery>
        <DropzoneUpload
          class="mt-2"
          :complete="isOptionalAttachmentsUploadComplete"
          :parentId="project.id"
          :parentType="attachmentType"
          :uploadCallback="onUploadProjectAttachment"
          v-on:dropzone-upload:reset="isOptionalAttachmentsUploadComplete = false"
          acceptedFileTypes="image/*"
        />
      </div>
    </div>
  </AccordionCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccordionCard from '@/components/AccordionCard';
import AttachmentGallery from '@/components/AttachmentGallery';
import DropzoneUpload from '@/components/DropzoneUpload';
import Textarea from 'primevue/textarea';

export default {
  name: 'ExistingDamage',
  components: {
    AccordionCard,
    AttachmentGallery,
    DropzoneUpload,
    Textarea,
  },
  props: {
    project: { type: Object },
    attachments: Array,
    attachmentType: { type: String, default: null },
    notesType: { type: String },
    card: { type: Object },
  },
  data() {
    return {
      /** track Upload states for clearing dropzone preview */
      isOptionalAttachmentsUploadComplete: false,
    };
  },
  computed: { ...mapGetters(['getEnumValues']) },

  methods: {
    ...mapActions(['uploadProjectAttachment', 'deleteProjectAttachment']),

    async onUploadProjectAttachment(payload) {
      await this.uploadProjectAttachment(payload);
    },

    async onDeleteProjectAttachment(event) {
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isOptionalAttachmentsUploadComplete = true;
        }
      },
    });
  },
};
</script>
