<template>
  <div class="button-bar">
    <div class="button-grid">
      <div class="alert-container">
        <b-alert show v-if="getFlashMessage.message" :variant="getFlashMessage.type">
          <span v-html="getFlashMessage.message" />
        </b-alert>
      </div>

      <div><Spinner class="spinner" v-if="isLoading" :size="25" /></div>

      <div v-if="showPreviousButton">
        <b-button :disabled="disabled" @click="onSaveAndPrevious" variant="bg">&lt;&lt;</b-button>
      </div>

      <div v-if="showDeleteButton">
        <ConfirmDeleteButton v-bind:disabled="disabled" :confirmCallback="onDelete" />
      </div>

      <b-button :disabled="disabled" @click="onCancel" variant="default"
        >{{ $t('cancel') }}
      </b-button>

      <div>
        <b-button :disabled="disabled" @click="onSave" variant="bg">{{ $t('save') }} </b-button>
      </div>

      <div v-if="showSaveAndBackButton">
        <b-button :disabled="disabled" @click="onSaveAndBack" variant="bg"
          >{{ $t('save_and_back') }}
        </b-button>
      </div>

      <div v-if="showNextButton">
        <b-button :disabled="disabled" @click="onSaveAndNext" variant="bg">&gt;&gt;</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import ConfirmDeleteButton from '@/components/ConfirmDeleteButton';

export default {
  components: {
    Spinner,
    ConfirmDeleteButton,
  },
  props: {
    showPreviousButton: { type: Boolean, default: false },
    showNextButton: { type: Boolean, default: false },
    showSaveAndBackButton: { type: Boolean, default: true },
    showDeleteButton: { type: Boolean, default: false },

    model: null,
    modelEditRoute: { type: String, default: '' },
    modelListRoute: { type: String, default: '' },
    nextModelNumber: { type: Number, default: null },
    previousModelNumber: { type: Number, default: null },
    modelRouteParamName: { type: String, default: '' },
    updateCallback: Function,
    createCallback: Function,
    deleteCallback: Function,
    fetchCallback: Function,
    fetchPaginatedCallback: Function,
  },
  data() {
    return {
      spinnerSize: 16,
    };
  },
  computed: {
    ...mapGetters(['getFlashMessage', 'isLoading']),
    disabled() {
      return this.isLoading;
    },
  },
  methods: {
    /**
     * Save and stay on page or if new, go to newly created entry
     */
    async onSave() {
      try {
        if (this.model.number && this.model.number != '_new') {
          await this.updateCallback(this.model);
        } else {
          const newModelNumber = await this.createCallback(this.model);
          this.$router.push({
            name: this.modelEditRoute,
            params: { [this.modelRouteParamName]: newModelNumber },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Save and go back
     */
    async onSaveAndBack() {
      try {
        if (this.model.number && this.model.number != '_new') {
          await this.updateCallback(this.model);
        } else {
          await this.createCallback(this.model);
        }
        this.$router.push({
          name: this.modelListRoute,
        });
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * Save an go to next entry
     */
    async onSaveAndNext() {
      await this.updateCallback(this.model);
      this.$router.push({
        name: this.modelEditRoute,
        params: { [this.modelRouteParamName]: this.nextModelNumber },
      });
    },

    /**
     * Save an go to previous entry
     */
    async onSaveAndPrevious() {
      await this.updateCallback(this.model);
      this.$router.push({
        name: this.modelEditRoute,
        params: { [this.modelRouteParamName]: this.previousModelNumber },
      });
    },

    /**
     * Delete this entry
     */
    async onDelete() {
      if (this.model.number !== '_new') {
        this.deleteCallback(this.model.number);

        if (this.fetchCallback) {
          await this.fetchCallback();
        } else if (this.fetchPaginatedCallback) {
          await this.fetchPaginatedCallback({
            page: 0,
            pageSize: 20,
            sortField: null,
            sortOrder: -1,
            filters: {},
          });
        }

        this.$router.push({ name: this.modelListRoute });
      }
    },

    /**
     * Go back without saving
     */
    onCancel() {
      this.$router.push({
        name: this.modelListRoute,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.button-grid {
  display: flex;
  gap: 0.75rem;
  flex-flow: row wrap;
  align-items: right;
  justify-content: right;
  align-content: flex-end;
  background: #f5f5f5;
  padding: 1rem 1rem 0.75rem 1rem;
}
.button-grid > div {
  min-width: 25px;
  flex-shrink: 1;
  flex-grow: 0;
}
.button-grid > div.alert-container {
  flex-shrink: 0;
  flex-grow: 1;
}
.alert {
  margin: 0;
}

.spinner {
  position: relative;
  top: 3px;
}
</style>
