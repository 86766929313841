<template>
  <div class="zip-download">
    <button
      class="btn"
      @click="onRequestZipDownload"
      v-if="zipAttachmentBuildState !== 'IN_PROGRESS'"
    >
      <i class="fa fa-download" :class="{ light: light }"></i>
    </button>
    <Spinner class="pull-right" :size="18" v-if="zipAttachmentBuildState === 'IN_PROGRESS'" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'ZipDownload',
  components: { Spinner },
  props: {
    attachmentType: String,
    attachments: { type: Array, default: null },
    project: { type: Object },
    light: { type: Boolean, default: false },
  },
  data() {
    return {
      zipAttachmentBuildState: { type: String, default: null },
      pollZipAttachmentBuildState: null,
      timer: 0,
    };
  },
  computed: {
    zipAttachmentBuildStateType() {
      return this.attachmentType + 'ZipBuildState';
    },
  },
  methods: {
    ...mapActions(['fetchSignedUrl', 'requestZipAttachment', 'fetchZipBuildState']),

    async onRequestZipDownload() {
      const res = await this.requestZipAttachment({
        projectId: this.project.id,
        attachmentType: this.attachmentType,
      });
      this.zipAttachmentBuildState = 'IN_PROGRESS';
      /** poll the build state until finished */
      this.pollZipAttachmentBuildState = setInterval(async () => {
        await this.fetchZipBuildState(this.project.number);
        this.updateZipAttachmentBuildState();
        // console.log(
        //   'onRequestZipDownload()',
        //   this.attachmentType,
        //   this.zipAttachmentBuildState,
        //   this.project.number
        // );
        /** Stop polling when build is finished and update the attachment list */
        if (this.zipAttachmentBuildState === 'FINISHED') {
          clearInterval(this.pollZipAttachmentBuildState);
          this.downloadSignedUrl();
        }
        if (this.timer > 20) {
          this.timer = 0;
          this.zipAttachmentBuildState = 'ERROR';
          clearInterval(this.pollZipAttachmentBuildState);
        }
        this.timer++;
      }, 1000);
    },

    updateZipAttachmentBuildState() {
      this.zipAttachmentBuildState = this.project[this.zipAttachmentBuildStateType];
    },

    async downloadSignedUrl() {
      // console.log('downloadSignedUrl()', this.attachments);
      if (this.attachments.length < 1) return;

      const url = await this.fetchSignedUrl({
        attachmentId: this.attachments[0].id,
        downloadType: 'attachment',
      });
      // console.log('downloadSignedUrl()', url);

      const a = document.createElement('a');
      a.download = this.attachments[0].displayFilename;
      a.href = url;
      a.style.display = 'none';
      document.body.append(a);
      a.click();

      // Chrome requires the timeout
      setTimeout(() => {
        a.remove();
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.zip-download {
  display: inline-block;
}
.spinner {
  left: 10px;
  top: 3px;
  position: relative;
}
i.light {
  color: $gray-100;
}
</style>
